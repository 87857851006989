import type { StateCreator } from 'zustand';
import { create } from 'zustand';

interface INavBarStore {
  blurred?: boolean;

  setBlurred(blurred: boolean): void;
}

export const navBarStore: StateCreator<INavBarStore, [], [], INavBarStore> = set => ({
  blurred: false,
  setBlurred: (blurred: boolean) => set(() => ({ blurred })),
});

export const useNavBarStore = create<INavBarStore>()(navBarStore);
