import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'wallet';

const translations = {
  title: 'InKind Wallet',
  btnSeeAllSupporters: 'See all supporters',
  active: 'Active',
  tabToRedeem: 'To Redeem',
  tabCardLinked: 'Card Activated',
  tabOther: 'Other',
  'toRedeem-emptyWalletLine1': 'There are currently no gifts to redeem in your Wallet.',
  'toRedeem-emptyWalletLine2':
    'Gifts activated to your payment card will appear in <1>Card Activated</1>. All other redeemed gifts will appear in Other.',
  'cardLinked-active-emptyWalletLine1': 'There are currently no gifts activated to your debit or credit card.',
  'cardLinked-active-emptyWalletLine2': 'Visit To Redeem to select gifts to link to your payment card.',
  'cardLinked-used-emptyWalletLine1': 'There are currently no used gifts activated to your debit or credit card.',
  'cardLinked-used-emptyWalletLine2': 'Visit Active to select activated gift to use.',
  'other-active-emptyWalletLine1': 'There are currently no active Visa cards or eGifts in your Wallet.',
  'other-active-emptyWalletLine2':
    'Visit To Redeem to select gifts to redeem, or visit <1>Card Activated</1> to view gifts that have been activated to your payment card.',
  'other-used-emptyWalletLine1': 'There are currently no Used Visa cards or eGifts in your Wallet.',
  'other-used-emptyWalletLine2': 'Visit Active to select gifts to use.',
  logoutBtn: 'Logout',
  supportersTitle: 'Supporter Details',
  backToWallet: 'Return to Wallet',
};
export default {
  [blockName]: translations,
  activityLog: {
    create: 'Purchased by supporter',

    'thank-you-sent': 'Thank you sent',
    'gift.create': 'Gift Created',
    'marked-as-used': 'Marked as Used.',
    'marked-as-unused': 'Marked as Unused.',
    'email-sent': 'Sent via email',
    'egift.create': 'Redeemed as eGift',
    'merge-create': 'Merged from other gifts',
    'swap-create': 'Swapped from other gift',
    'card.linked': 'Gift activated to payment card',
    'card.activated': 'Gift activated to payment card',
    'card.unlinked': 'Gift deactivated from payment card',
    'card.deactivated': 'Gift deactivated from payment card',
    'card.credits-issued': 'Credits were issued to your payment card',
    'visa-digital.create': 'Redeemed as Digital Visa',
    'visa-physical.create': 'Sent via mail',
    'legacy.create': 'Redeemed as eGift',
    'legacy.create-visa': 'Redeemed as Digital Visa',
    'legacy.create-visa-physical': 'Processing gift for shipment',
    'zelle.transfer': 'Transfer funds via Zelle',
    'linked-to-card': 'Gift activated to payment card',
    'card.relinked': 'Gift activated to payment card',
    'unlinked-from-card': 'Gift deactivated from payment card',
    'legacy.fulfilled-visa-physical': 'Sent via mail',
    'legacy.fulfilled-visa': '-',
    'legacy.fulfilled': '-',
    'legacy.redeem': 'Redeemed as Physical Visa',
  },
  adminActivityLog: {
    create: 'Gift entity created',
    'thank-you-sent': 'Thank you sent',
    'gift.cancel': 'Giftya gift cancelled',
    'gift.cancel.fail': 'Giftya gift cancellation failed',
    'merged-gift.cancel': 'Cancel Merged Gift',
    'merged-gift.cancel-fail': 'Failed to Cancel Merged Gift',
    'gift.create': 'Giftya gift created',
    'gift.create.fail': 'Giftya gift creation failed',
    'marking-as-used': 'Marking as used',
    'marking-as-unused': 'Marking as unused',
    'marked-as-used': 'Marked as used',
    'marked-as-unused': 'Marked as unused',
    'email-sent': 'eGift details emailed to user',
    'egift.fetch-error': 'Failed to fetch eGift details from Giftya',
    refunded: 'Gift has been refunded',
    'egift.inconsistency':
      'Gift was already redeemed with Giftya as an EGift, Gift Entity was updated with new details',

    'merge-card-linkcreate': 'Gift entity updated from a temp gift to a real gift',
    'egift.create': 'Giftya eGift created',
    'egift.create.fail': 'Giftya eGift creation Failed',
    'merge-create': 'Gift Created by merging from other gifts',
    'swap-create': 'Gift Created by swapping from another gift',
    'card.linked': 'Gift activated to payment card',
    'card.activated': 'Gift activated to payment card',
    'card.unlinked': 'Gift deactivated from payment card',
    'card.deactivated': 'Gift deactivated from payment card',
    'card.credits-issued': 'Credits issued to activated payment card',
    'card.credits-issued-below-zero': 'Credits issued to activated payment card ended up with a balance less than zero',

    'legacy.create': 'Legacy order created',
    'legacy.create-visa': 'Legacy visa order created',
    'legacy.create-visa-physical': 'Legacy physical visa order created',

    'zelle.transfer': 'Funds transferred through Zelle',
    'linked-to-card': 'Gift activated to payment card',
    'card.relinked': 'Gift re-activated to payment card',
    'unlinked-from-card': 'Gift deactivated from payment card',
    'legacy.fulfilled-visa-physical': 'Legacy physical visa order fulfilled',
    'legacy.fulfilled-visa': 'Legacy visa order fulfilled',
    'legacy.fulfilled': 'Legacy order fulfilled',
    'legacy.redeem': 'Legacy order request attempt',
    'legacy.create.fail': 'Legacy order request attempt Failed',

    'update: REDEEMED_EGIFT': 'Gift entity updated because eGift created with Gitfya',
    'update: REDEEMED_EGFIFT': 'Gift entity updated because eGift created with Gitfya',
    'update: GY_GIFT_CREATED': 'Gift entity updated because a Giftya gift was created',
    'update: GY_EGIFT_CREATED': 'Gift entity updated because a Giftya egift was created',
    'update: GY_EGIFT_STATUS_UPDATED': 'Gift entity updated because the Giftya eGift status was updated',
    'update: GY_GIFT_FULFILLED_RETROACTIVE':
      'Gift entity marked as fulfilled retroactively through a second redemption request',
    'update: LEGACY_GIFT_CREATED': 'Gift entity updated because an order was created with a legacy system',
    'update: LEGACY_GIFT_FULFILLED': 'Gift entity updated because an order was created with a legacy system',
    'update: CANCELLED': 'Gift entity updated because it was cancelled',
    'update: MERGED': 'Gift entity updated because it was merged',
    'update: SWAPPED': 'Gift entity updated because it was swapped',
    'update: REFUNDED': 'Gift entity updated because it was refunded',
    'update: CARD_LINKED_MERGED': 'Gift entity updated because temp gift was used to merge other gifts',
    'update: CARD_LINKED_MERGE_REUSE': 'Gift entity updated because temp gift was reused',
    'update: CARD_LINKED': 'Gift entity updated because it was activated to a payment card',
    'update: CARD_UNLINKED': 'Gift entity updated because it was deactivated from a payment card',
    'update: CARD_UNENROLLED': 'Gift entity updated because the activated card was unenrolled from a payment card',
    'update: CARD_CREDIT_ISSUED': 'Gift entity updated because credits were issued',
    'update: BALANCE_ZERO': "Gift entity updated because it's balance reached 0",
    'update: REDEEMED': 'Gift entity updated because it was redeemed',
    'update: TEMP_GIFT_SCHEDULED_JOB_ID_CHANGED': 'Gift entity updated because the scheduled job id changed',
    'update: THANK_YOU_SENT': 'Gift entity updated because thank you email has been sent',
  },
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
