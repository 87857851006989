import type { AxiosRequestConfig } from 'axios';
import type {
  PlatformManagementApiResponse,
  StrapiPagedDataSource,
  StrapiQueryParamns,
  StrapiSingleDataSource,
} from '../../types/Response';
import BaseResource from '../base';
import type {
  GiftEntity,
  GiftMarkAsReadyPayload,
  GiftMarkAsRedeemedPayload,
  GyPGCreatePayload,
  RegisterMissingCreditsPayload,
} from '../../types/Gift';
import { giftWalletAdminEndPoints } from './endPoints';
import type { GyEGiftCreateResponse, GyGiftEntity } from '@gik/giftya-api-js/types/api.gift.types';
import type { GyEgiftEntity } from '@gik/giftya-api-js/types/api.egift.types';
import type { GyCreditTransaction } from '@gik/giftya-api-js/types/api.transaction.types';
import type { GyMerchantEntity } from '@gik/giftya-api-js/types/api.merchant.types';

const gift = giftWalletAdminEndPoints.gift;

type Entity = GiftEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<SingleDataType>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;

export class AdminGiftResource extends BaseResource {
  list(params: StrapiQueryParamns, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.list(), {
      ...options,
      params: {
        ...params,
        ...options?.params,
      },
    });
  }

  get(id: string, options?: AxiosRequestConfig) {
    return this.client.get<GiftEntity, SingleResponseType>(gift.get(id), options);
  }

  getDetailed(id: string, options?: AxiosRequestConfig) {
    return this.client.get<GiftEntity, SingleResponseType>(gift.getDetailed(id), options);
  }

  create(payload: Entity, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, SingleResponseType>(gift.create(), payload, options);
  }

  update(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
    return this.client.put<SingleDataType, SingleResponseType>(gift.update(id), payload, options);
  }

  delete(id: string, options?: AxiosRequestConfig) {
    return this.client.delete<SingleDataType, SingleResponseType>(gift.delete(id), options);
  }

  getCardTransactionHistoryByGyGiftid(gyGiftId: string, options?: AxiosRequestConfig) {
    return this.client.get<SingleDataType, PlatformManagementApiResponse<StrapiPagedDataSource<GyCreditTransaction>>>(
      gift.getCardTransactionHistoryByGyGiftid(gyGiftId),
      options
    );
  }

  registerMissingCredits(
    id: string,
    payload: Omit<RegisterMissingCreditsPayload, 'gift_id'>,
    options?: AxiosRequestConfig
  ) {
    return this.client.post<SingleDataType, SingleResponseType>(gift.registerMissingCredits(id), payload, options);
  }

  listByCustomerId(options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.list(), options);
  }

  /**
   * Strapi get by uuid
   */
  async getByUuid(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<SingleDataType, SingleResponseType>(gift.list(), {
      ...options,
      ...{
        params: {
          filters: {
            uuid: {
              $eq: id,
            },
          },
          ...options?.params,
        },
      },
    });

    // @ts-ignore
    return response?.data?.data?.[0];
  }

  /**
   * Get remote gift details
   */
  async getRemoteGift(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, StrapiSingleDataSource<GyGiftEntity>>(gift.getRemoteGift(id), options);

    return response;
  }

  async getRemoteEGift(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, StrapiSingleDataSource<GyEgiftEntity>>(
      gift.getRemoteEGift(id),
      options
    );

    return response;
  }
  async getRemoteMerchant(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, StrapiSingleDataSource<GyMerchantEntity>>(
      gift.getRemoteMerchant(id),
      options
    );

    return response;
  }

  createEGift(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<GyEGiftCreateResponse>>(
      gift.createEGift(giftUuid),
      undefined,
      options
    );
  }
  createPGGift(giftUuid: string, payload: GyPGCreatePayload, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.createPGGift(giftUuid),
      payload,
      options
    );
  }
  cancelGiftyaGift(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.cancelGiftyaGift(giftUuid),
      undefined,
      options
    );
  }
  markAsRedeemed(giftUuid: string, payload: GiftMarkAsRedeemedPayload, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.markAsRedeemed(giftUuid),
      payload,
      options
    );
  }
  markAsReady(giftUuid: string, payload: GiftMarkAsReadyPayload, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.markAsReady(giftUuid),
      payload,
      options
    );
  }
}
