/**
 * Custom error class that extends the built-in Error class.
 * @param {object} data - Additional data related to the error (default is an empty object).
 * @param {string} message - The error message.
 * @param {number} code - The error code associated with the error.
 * // Example usage

try {
  throw new NotFoundError('Resource not found', { resource: 'User' }, { sensitiveData: '1234' });
} catch (error) {
  console.log(error.message); // 'Resource not found'
  console.log(error.code);    // 404
  console.log(error.data);    // { resource: 'User' }
}

 */
export class CustomError extends Error {
  code: number;
  data: object;
  logData: object;

  constructor(message: string, data = {}, logData = {}, code: number) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
    this.data = data;
    this.logData = logData;

    // Maintains proper stack trace (only works in V8 environments like Node.js)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

// ----- 400 Errors

// Common HTTP Errors
export class BadRequestError extends CustomError {
  constructor(message = 'Bad Request', data = {}, logData = {}) {
    super(message, data, logData, 400);
  }
}

export class UnauthorizedError extends CustomError {
  constructor(message = 'Unauthorized', data = {}, logData = {}) {
    super(message, data, logData, 401);
  }
}

export class ForbiddenError extends CustomError {
  constructor(message = 'Forbidden', data = {}, logData = {}) {
    super(message, data, logData, 403);
  }
}

export class NotFoundError extends CustomError {
  constructor(message = 'Not Found', data = {}, logData = {}) {
    super(message, data, logData, 404);
  }
}

export class ConflictError extends CustomError {
  constructor(message = 'Conflict Error', data = {}, logData = {}) {
    super(message, data, logData, 409);
  }
}

// ----- 500 Errors

/**
 * The server has encountered a situation it does not know how to handle.
 */
export class InternalServerError extends CustomError {
  constructor(message = 'Internal Server Error', data = {}, logData = {}) {
    super(message, data, logData, 500);
  }
}

/**
 * The request method is not supported by the server and cannot be handled.
 * The only methods that servers are required to support (and therefore that must not return this code) are GET and HEAD.
 */
export class NotImplementedError extends CustomError {
  constructor(message = 'Not Implemented', data = {}, logData = {}) {
    super(message, data, logData, 501);
  }
}

/**
 * This error response means that the server, while working as a gateway to get a response needed to handle the request, got an invalid response.
 */
export class BadGatewayError extends CustomError {
  constructor(message = 'Bad Gateway', data = {}, logData = {}) {
    super(message, data, logData, 502);
  }
}

/**
 * The server is not ready to handle the request. Common causes are a server that is down for maintenance or that is overloaded.
 * Note that together with this response, a user-friendly page explaining the problem should be sent.
 * This response should be used for temporary conditions and the Retry-After HTTP header should, if possible,
 * contain the estimated time before the recovery of the service. The webmaster must also take care about the caching-related
 * headers that are sent along with this response, as these temporary condition responses should usually not be cached.
 */
export class ServiceUnavailableError extends CustomError {
  constructor(message = 'Service Unavailable', data = {}, logData = {}) {
    super(message, data, logData, 503);
  }
}

/**
 * This error response is given when the server is acting as a gateway and cannot get a response in time.
 */
export class GatewayTimeoutError extends CustomError {
  constructor(message = 'Gateway Timeout', data = {}, logData = {}) {
    super(message, data, logData, 504);
  }
}

/**
 * The HTTP version used in the request is not supported by the server.
 */
export class HttpVersionNotSupportedError extends CustomError {
  constructor(message = 'HTTP Version Not Supported', data = {}, logData = {}) {
    super(message, data, logData, 505);
  }
}

/**
 * The server has an internal configuration error: the chosen variant resource is configured to engage in transparent content negotiation itself,
 * and is therefore not a proper end point in the negotiation process.
 */
export class VariantAlsoNegotiatesError extends CustomError {
  constructor(message = 'Variant Also Negotiates', data = {}, logData = {}) {
    super(message, data, logData, 506);
  }
}

/**
 * The method could not be performed on the resource because the server is unable to store the representation needed to successfully complete the request.
 */
export class InsufficientStorageError extends CustomError {
  constructor(message = 'Insufficient Storage', data = {}, logData = {}) {
    super(message, data, logData, 507);
  }
}

/**
 * The server detected an infinite loop while processing the request.
 */
export class LoopDetectedError extends CustomError {
  constructor(message = 'Loop Detected', data = {}, logData = {}) {
    super(message, data, logData, 508);
  }
}

/**
 * Further extensions to the request are required for the server to fulfill it.
 */
export class NotExtendedError extends CustomError {
  constructor(message = 'Not Extended', data = {}, logData = {}) {
    super(message, data, logData, 510);
  }
}

/**
 * Indicates that the client needs to authenticate to gain network access.
 */
export class NetworkAuthenticationRequiredError extends CustomError {
  constructor(message = 'Network Authentication Required', data = {}, logData = {}) {
    super(message, data, logData, 511);
  }
}
