import { stripeTokenLocalStorage } from '@/constants';
import { fireUserNotice } from '@gik/analytics/utils/Analytics';
import { logger } from '@gik/analytics/utils/logger';
import { getUser, useUser } from '@gik/api/users/user';
import { calendarEventClaimFormContentId, checkoutFormContentId } from '@gik/calendar/utils/CalendarModals';
import { useCheckoutFormModalStore } from '@gik/checkout/store/CheckoutFormModalStore';
import type { BillingDetails, CartItem, ShippingDetails } from '@gik/checkout/types';
import type { PaymentMethod } from '@gik/checkout/utils/productUtils';
import { parsePaymentMethodName } from '@gik/checkout/utils/productUtils';
import { validateSendToSelf } from '@gik/checkout/utils/validateSendToSelf';
import { timeoutDefaultValue } from '@gik/core/constants';
import { useIdempotencyKeysStore } from '@gik/core/store/IdempotencyKeysStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { storage } from '@gik/core/utils/Storage';
import { timers } from '@gik/core/utils/TimerUtil';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import type { FormProps, FormRef, ValidationOptions } from '@gik/ui/Form';
import { Form, FormField, FormGroup, validateForm } from '@gik/ui/Form';
import { Input } from '@gik/ui/Input';
import { stripeCardElementOptions } from '@gik/ui/Stripe';
import { SvgIcon } from '@gik/ui/SvgIcon';
import PlusIcon from '@heroicons/react/solid/PlusIcon';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { Source, Stripe, StripeCardElement, StripeError } from '@stripe/stripe-js';
import classnames from 'classnames';
import type { FormApi, SubmissionErrors } from 'final-form';
import type { FormikHandlers } from 'formik/dist/types';
import React from 'react';
import { scroller } from 'react-scroll';
import { setTimeout } from 'timers';
import { translationKeys } from '../../i18n/en';
import type { ICheckoutFormModalContext } from '../CheckoutForm/CheckoutFormModalContext';
import { CheckoutFormModalContext } from '../CheckoutForm/CheckoutFormModalContext';
import { BillingFormSchema } from './BillingFormSchema';
import { CreditCardImages } from '@gik/ui/CreditCardImages';

export interface BillingFormValues extends BillingDetails {
  stripeToken?: string;
  saveAddress?: boolean;
  paymentMethod?: PaymentMethod;
}

export interface BillingFormProps extends FormProps {
  initialValues?: Partial<BillingFormValues>;
  initialOrders?: CartItem[];
  shippingDetails?: ShippingDetails;
  buttons?: (isFormValid: boolean) => React.ReactNode;
  buttonsPortal?: HTMLElement;
  productIds?: number[];

  /**
   * This is used when a stripe token is already available and an
   * obfuscated card number should be displayed
   */
  creditCardLast4?: string;

  setCardElement?(cardElement: StripeCardElement): void;

  onBeforeSubmit?: (
    values: object,
    form: FormApi<object, object>
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;

  onAfterSubmit?: (
    values: object,
    form: FormApi<object, object>,
    source: Source
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;

  /** Called when form validation succeeds but either stripe token generation or address validation fails */
  onBillingSubmitFail?();
  onShowAddress2Field?(): void;
  onClickFirstNameField?(): void;

  onFormBlur?: () => void;
  onStripeFieldChange?: () => void;
  preDebounce?: () => void;
}

export function createStripeSource(values, stripe: Stripe, cardElement) {
  return stripe.createSource(cardElement, {
    type: 'card',
    // pass billing details to stripe
    currency: 'usd',
    owner: {
      address: {
        city: values.city,
        country: values.country,
        line1: values.address1,
        line2: values.address2,
        postal_code: values.postalCode,
        state: values.state,
      },
      email: values.email,
      name: values.firstName + ' ' + values.lastName,
      phone: values.phone,
    },
    metadata: {
      frontendTransactionId: useIdempotencyKeysStore.getState().frontendTransactionId,
    },
  });
}

export const refillBillingAddressForm = () => {
  setTimeout(async () => {
    const userId = useUserStore.getState().id;

    if (!userId || !useCheckoutFormModalStore.getState().billingFormRef.current) return;

    const user = await getUser(userId);
    const billingAddress = user?.billingAddresses?.[0];

    const currentValues = useCheckoutFormModalStore.getState().billingFormRef.current.values;

    useCheckoutFormModalStore.getState().billingFormRef.current.resetForm({
      values: {
        country: 'US',
        firstName:
          currentValues.firstName?.length > 0 ? currentValues.firstName : billingAddress?.firstName || user?.firstName,
        lastName:
          currentValues.lastName?.length > 0 ? currentValues.lastName : billingAddress?.lastName || user?.lastName,
        state: currentValues.state?.length > 0 ? currentValues.state : billingAddress?.state || user?.state,
        email: currentValues.email?.length > 0 ? currentValues.email : billingAddress?.email || user?.emailAddress,
        city: currentValues.city?.length > 0 ? currentValues.city : billingAddress?.city,
        companyName: currentValues.companyName?.length > 0 ? currentValues.companyName : billingAddress?.companyName,
        postalCode: currentValues.postalCode?.length > 0 ? currentValues.postalCode : billingAddress?.postalCode,
        address1: currentValues.address1?.length > 0 ? currentValues.address1 : billingAddress?.address1,
        address2: currentValues.address2?.length > 0 ? currentValues.address2 : billingAddress?.address2,
        stripeToken: stripeTokenLocalStorage ? storage.getWithExpiry('stripe-token') : undefined,
        saveAddress: true,
        paymentMethod: 'stripe',
      },
    });
  }, 500);
};

function BillingFormComp({
  initialValues,
  initialOrders,
  shippingDetails,
  creditCardLast4,
  submitButton,
  className,
  buttons,
  buttonsPortal,
  productIds,
  setCardElement,
  onSubmit,
  onBeforeSubmit,
  onAfterSubmit,
  onBillingSubmitFail,
  onChange,
  onShowAddress2Field,
  onClickFirstNameField,
  onFormBlur,
  onStripeFieldChange,
  preDebounce,
  ...otherProps
}: BillingFormProps): React.ReactElement {
  const [stripeError, setStripeError] = React.useState<StripeError>(undefined);
  const [stripeEditable, setStripeEditable] = React.useState<boolean>(false);
  const [showSaveAddress, setShowSaveAddress] = React.useState<boolean>(false);

  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const { isSubmitted, isSubmitting, modalContentRef } =
    React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext) || {};

  const stripe = useStripe();
  const elements = useElements();

  const useStripeInput = true;

  const formRef = React.useRef<FormRef>();

  const handleSubmit = React.useCallback(
    async (values: BillingFormValues, form: FormApi<object, object>): Promise<void> => {
      setStripeError(undefined);
      onBeforeSubmit?.(values, form);

      let stripeSource: Source;

      if (useStripeInput && values.paymentMethod !== 'apple-pay') {
        const cardElement = elements.getElement(CardElement);

        setCardElement(cardElement);

        const startTime = new Date();
        try {
          logger.info(`start create Stripe token`);

          const { error, source } = await createStripeSource(values, stripe, cardElement);

          stripeSource = source;

          if (error) {
            const endTime = new Date();
            const timeEllapsed = endTime.getTime() - startTime.getTime();

            logger.warn(`Stripe token card user error (took ${timeEllapsed} ms):`, error);
            setStripeError(error);
            fireUserNotice(error.message, 'errorStripe');
            onBillingSubmitFail?.();
            return;
          } else {
            // add the stripeToken to the values object
            values.stripeToken = stripeSource.id;

            const endTime = new Date();
            const timeEllapsed = endTime.getTime() - startTime.getTime();

            logger.info(`successfully got Stripe token after ${timeEllapsed} ms`);

            timers.billingFormStripeToken = new Date();
          }
        } catch (err) {
          const endTime = new Date();
          const timeEllapsed = endTime.getTime() - startTime.getTime();
          fireUserNotice(err.message, 'errorStripe');
          logger.error(`failed to create Stripe token (took ${timeEllapsed} ms):`, err);
        }
      } else {
        // add the stripeToken from initialValues
        values.stripeToken = initialValues?.stripeToken;
      }

      // set saveAddress to false if the field wasn't even shown
      if (!showSaveAddress) {
        values.saveAddress = false;
      }

      // workaround for: https://wolfellc.atlassian.net/browse/GIK-8759
      storage.setWithExpiry('billingForm', JSON.stringify(values), 15 * 60);

      onSubmit?.(values, form);
      onAfterSubmit?.(values, form, stripeSource);
    },
    [
      onBeforeSubmit,
      useStripeInput,
      showSaveAddress,
      onSubmit,
      onAfterSubmit,
      onBillingSubmitFail,
      elements,
      setCardElement,
      stripe,
      initialValues,
    ]
  );

  const handleChange = React.useCallback(
    (values: object) => {
      onChange?.(values);
    },
    [onChange]
  );

  const handleChangeStripe = React.useCallback(() => {
    setStripeEditable(true);
  }, []);

  const schema = React.useMemo(() => {
    const schema = BillingFormSchema(modalContentRef, !!userId, undefined, undefined, true);
    useCheckoutFormModalStore.getState().billingFormSchema.current = schema;
    return schema;
  }, [modalContentRef, userId]);

  async function validate(values: BillingFormValues, opts: ValidationOptions<BillingFormValues>) {
    const validation = await validateForm(schema, values, opts);

    if (validateSendToSelf(initialOrders, values.email, shippingDetails)) {
      // @ts-ignore
      validation.email = {
        message: i18n.t(translationKeys.tangoCardCannotPurchaseForSelf),
        // TODO: 'singleRequired' is not really the correct type for this validation error
        type: 'singleRequired',
      };
    }

    return validation;
  }

  const disabled = isSubmitted || isSubmitting;

  const bem = useBemCN('billing-form');

  const [showAddress2Field, setShowAddress2Field] = React.useState(false);
  const handleShowAddress2Field = React.useCallback(() => {
    setShowAddress2Field(true);
    setTimeout(() => onShowAddress2Field?.(), timeoutDefaultValue);
  }, [onShowAddress2Field]);

  const handleClickFistNameField = React.useCallback(() => {
    onClickFirstNameField?.();
  }, [onClickFirstNameField]);

  const originalHandleBlur = React.useRef<FormikHandlers['handleBlur']>();

  return (
    <Form
      {...otherProps}
      ref={ref => {
        formRef.current = ref;
        useCheckoutFormModalStore.getState().billingFormRef.current = ref;
      }}
      onSubmit={handleSubmit}
      debounce={1000}
      preDebounce={preDebounce}
      schema={schema}
      onChange={handleChange}
      initialValues={initialValues}
      vertical
      validateOnBlur={false}
      {...bem(null, null, className)}
      validate={validate}
      disabled={disabled}
      // restoreAfterUpdate
      render={renderProps => {
        if (!originalHandleBlur.current) {
          originalHandleBlur.current = renderProps.handleBlur;
        }
        renderProps.handleBlur = (...args) => {
          onFormBlur?.();
          // @ts-ignore
          originalHandleBlur.current?.(...args);
        };
        const { isSubmitting, dirty } = renderProps;
        const showSaveAddress = user && (!user?.billingAddresses?.length || dirty);
        setShowSaveAddress(showSaveAddress);

        return (
          <>
            <FormGroup
              id={'stripe'}
              {...bem('stripe-wrapper')}
              vertical
              label={
                <>
                  <div>
                    <strong>{parsePaymentMethodName('stripe')}</strong>
                  </div>
                  <div>
                    <CreditCardImages />
                  </div>
                </>
              }
            >
              {/*
                NOTE: this input should always be rendered and mounted to be able to recreate a new stripe source multiple times (in case initial payment failed)
                Since we are not attaching payment sources to customer objects yet we need to recreate a single use source for every attempt.
              */}
              <Input
                disabled={disabled}
                className={classnames({ 'gik-input--hidden': !useStripeInput })}
                onChange={() => {
                  onStripeFieldChange?.();
                }}
                customInput={customProps => (
                  <CardElement
                    options={{ ...stripeCardElementOptions, disabled }}
                    {...customProps}
                    onBlur={e => {
                      customProps.onBlur?.(e);
                      onFormBlur?.();
                    }}
                    onChange={e => {
                      customProps.onChange?.(e);
                      useCheckoutFormModalStore.getState().isCardFieldComplete.current = e.complete;
                    }}
                    onFocus={e => {
                      customProps.onFocus?.(e);

                      scroller.scrollTo('BillingFormEnd', {
                        duration: 300,
                        smooth: true,
                        container:
                          document.getElementById(checkoutFormContentId) ??
                          document.getElementById(calendarEventClaimFormContentId),
                      });
                    }}
                    onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      // prevent the default action of the numeric input which is to submit the form
                      event.preventDefault();

                      if (event.key === 'Enter') {
                        const nextEl = document.querySelector('[name="anonymous-0"]') as HTMLInputElement;
                        if (nextEl) nextEl.focus();
                      }
                    }}
                  />
                )}
              />

              {!useStripeInput && (
                <div>
                  xxxx-xxxx-xxxx-{creditCardLast4} (
                  <Button
                    variant="primary-link"
                    onClick={handleChangeStripe}
                    disabled={disabled}
                    preventClickWhenDisabled
                  >
                    Change
                  </Button>
                  )
                </div>
              )}
            </FormGroup>

            <section {...bem('first-name-last-name-section')}>
              <FormField
                name="firstName"
                className="tw-flex-1"
                disabled={disabled}
                onClick={handleClickFistNameField}
              />
              <FormField name="lastName" className="tw-flex-1" disabled={disabled} />
            </section>
            {/*<FormField name="companyName" disabled={disabled} />*/}
            {/*<FormField name="country" disabled={disabled} />*/}
            <FormField name="address1" disabled={disabled} />
            <div>
              {showAddress2Field ? (
                <FormField name="address2" disabled={disabled} />
              ) : (
                <div {...bem('address-2-field-wrapper')}>
                  <Button circle variant={'default'} size={'xs'} onClick={handleShowAddress2Field}>
                    <SvgIcon Icon={PlusIcon} />
                  </Button>{' '}
                  <p {...bem('address-2-field-copy')}>Add address line</p>
                </div>
              )}
            </div>
            <FormField name="city" className="tw-flex-1" disabled={disabled} />
            <section {...bem('state-zip-section')}>
              <FormField name="state" disabled={disabled} />
              <FormField name="postalCode" disabled={disabled} />
            </section>
            <FormField
              {...bem('email-field', [{ hidden: !!userId && !!initialValues.email }], 'tw-flex-1')}
              name="email"
              disabled={disabled}
            />
            {showSaveAddress && <FormField name="saveAddress" disabled={disabled} />}

            {stripeError && <div className="gik-form__error">{stripeError.message}</div>}
            {submitButton && (
              <div className="tw-text-center tw-pt-4">
                <Button variant="primary" type="submit" loading={isSubmitting} wide>
                  CONTINUE
                </Button>
              </div>
            )}
            {renderPortal?.(buttons && buttons?.(renderProps.isValid), () => buttonsPortal)}
            <div id="BillingFormEnd" />
          </>
        );
      }}
    />
  );
}

export const BillingForm = withComponentErrorBoundary(BillingFormComp);
