import type { AxiosRequestConfig } from 'axios';
import type {
  PlatformManagementApiResponse,
  StrapiPagedDataSource,
  StrapiSearchParams,
  StrapiSingleDataSource,
} from '../types/Response';
import BaseResource from './base';
import { giftWalletEndPoints } from './endPoints';
import type {
  GiftEntity,
  GiftSendThankYouMessagePayload,
  EGiftSendEmailPayload,
  PGGiftSendEmailPayload,
  GiftActivityLog,
  GiftHistory,
} from '../types/Gift';
import type { GyCreditTransaction } from '@gik/giftya-api-js/types/api.transaction.types';
import type { GyGiftEntity } from '@gik/giftya-api-js/types/api.gift.types';
import type { GyEgiftEntity } from '@gik/giftya-api-js/types/api.egift.types';
import type { GyLinkNewCardsResponse } from '@gik/giftya-api-js/types/api.card.types';

const gift = giftWalletEndPoints.gift;

type Entity = GiftEntity;

export class GiftResource extends BaseResource {
  /**
   * Strapi list
   */
  list(params: StrapiSearchParams, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.list(),
      {
        ...options,
        params: {
          ...params,
          ...options?.params,
        },
      }
    );
  }

  /**
   * Strapi get
   */
  // get(id: string, options?: AxiosRequestConfig) {
  //   return this.client.get<GiftEntity, PlatformManagementApiResponse<GiftEntity>>(gift.get(id), options);
  // }

  /**
   * Strapi get detailed
   */
  getDetailed(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<GiftEntity>>(
      gift.getByUuidDetailed(id),
      options
    );
  }

  /**
   * Strapi create
   */
  // create(payload: Entity, options?: AxiosRequestConfig) {
  //   return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<GiftEntity>>(gift.create(), payload, options);
  // }

  /**
   * Strapi update
   */
  // update(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
  //   return this.client.put<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<GiftEntity>>(gift.update(id), payload, options);
  // }

  /**
   * Strapi delete
   */
  // delete(id: string, options?: AxiosRequestConfig) {
  //   return this.client.delete<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<GiftEntity>>(gift.delete(id), options);
  // }

  /**
   * Strapi get by uuid
   */
  getByUuid(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<GiftEntity>>(
      gift.getByUuid(id),
      options
    );
  }

  getActivityLog(giftId: string, options?: AxiosRequestConfig) {
    return this.client.get<
      StrapiSingleDataSource<Entity>,
      PlatformManagementApiResponse<StrapiPagedDataSource<GiftActivityLog>>
    >(gift.getActivityLog(giftId), options);
  }

  getGiftHistory(giftId: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiSingleDataSource<Entity>, StrapiPagedDataSource<GiftHistory>>(
      gift.getGiftHistory(giftId),
      options
    );
  }

  getCardTransactionHistoryByGyGiftid(gyGiftId: string, options?: AxiosRequestConfig) {
    return this.client.get<
      StrapiSingleDataSource<Entity>,
      PlatformManagementApiResponse<StrapiPagedDataSource<GyCreditTransaction>>
    >(gift.getCardTransactionHistoryByGyGiftid(gyGiftId), options);
  }

  getCardTransactionHistoryByGiftUuid(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.get<
      StrapiSingleDataSource<Entity>,
      PlatformManagementApiResponse<StrapiPagedDataSource<GyCreditTransaction>>
    >(gift.getCardTransactionHistoryByGiftUuid(giftUuid), options);
  }

  listByCustomerIdRedeemableGrouped(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdRedeemableGrouped(id),
      options
    );
  }

  listByCustomerIdRedeemable(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdRedeemable(id),
      options
    );
  }

  listByCustomerIdMore(id: string, baseGiftSourceProductId: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdMore(id, baseGiftSourceProductId),
      options
    );
  }

  listByCustomerIdUnused(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdUnused(id),
      options
    );
  }

  listByCustomerIdActive(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdActive(id),
      options
    );
  }

  listByCustomerIdUsed(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdUsed(id),
      options
    );
  }

  listByCustomerIdActiveLinked(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdActiveLinked(id),
      options
    );
  }

  listByCustomerIdUsedLinked(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdUsedLinked(id),
      options
    );
  }

  listByCustomerIdLinkedToCard(id: string, cardId: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerIdLinkedToCard(id, cardId),
      options
    );
  }

  usePaymentCardsByCustomerId(customerId: string, cardId: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.usePaymentCardsByCustomerId(customerId, cardId),
      options
    );
  }

  listByCustomerId(id: string, options?: AxiosRequestConfig) {
    return this.client.get<StrapiPagedDataSource<Entity>, PlatformManagementApiResponse<StrapiPagedDataSource<Entity>>>(
      gift.listByCustomerId(id),
      options
    );
  }

  /**
   * Get remote gift details
   */
  async getRemoteGift(uuid: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, PlatformManagementApiResponse<StrapiSingleDataSource<GyGiftEntity>>>(
      gift.getRemoteGift(uuid),
      options
    );

    return response;
  }

  async getRemoteEGift(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, PlatformManagementApiResponse<StrapiSingleDataSource<GyEgiftEntity>>>(
      gift.getRemoteEGift(id),
      options
    );

    return response;
  }

  /**
   * Strapi create
   */
  cancel(id: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<Entity>, boolean>(gift.cancel(id), undefined, options);
  }

  sendEmail(id: string, payload?: Partial<EGiftSendEmailPayload>, options?: AxiosRequestConfig) {
    return this.client.post<null, PlatformManagementApiResponse<boolean>>(gift.sendEmail(id), payload, options);
  }

  sendLegacyEmail(id: string, payload?: Partial<PGGiftSendEmailPayload>, options?: AxiosRequestConfig) {
    return this.client.post<null, PlatformManagementApiResponse<boolean>>(gift.emailLegacyGift(id), payload, options);
  }

  sendDigitalVisaEmail(id: string, payload?: Partial<PGGiftSendEmailPayload>, options?: AxiosRequestConfig) {
    return this.client.post<null, PlatformManagementApiResponse<boolean>>(gift.emailDigitalVisa(id), payload, options);
  }

  sendThankYouMessage(id: string, payload?: Partial<GiftSendThankYouMessagePayload>, options?: AxiosRequestConfig) {
    return this.client.post<null, PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>>(
      gift.sendThankYouMessage(id),
      payload,
      options
    );
  }

  markAsUsedByUuid(id: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<boolean>>(
      gift.markAsUsedByUuid(id),
      undefined,
      options
    );
  }

  markAsUnusedByUuid(id: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<boolean>>(
      gift.markAsUnusedByUuid(id),
      undefined,
      options
    );
  }

  // unlinkFromCard(id: string, options?: AxiosRequestConfig) {
  //   return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<boolean>>(
  //     gift.unlinkFromCard(id),
  //     undefined,
  //     options
  //   );
  // }

  getCardLinkingSession(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<GyLinkNewCardsResponse>>(
      gift.getCardLinkingSession(giftUuid),
      undefined,
      options
    );
  }

  invalidateCardLinkingSession(sessionid: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<boolean>>(
      gift.invalidateCardLinkingSession(sessionid),
      undefined,
      options
    );
  }

  reactivateCard(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<boolean>>(
      gift.reactivateCard(giftUuid),
      undefined,
      options
    );
  }

  fulfilled(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<boolean>>(
      gift.fulfilled(giftUuid),
      undefined,
      options
    );
  }
  stalled(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<Entity>, PlatformManagementApiResponse<boolean>>(
      gift.stalled(giftUuid),
      undefined,
      options
    );
  }
}
