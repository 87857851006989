import { v4 as uuidv4 } from 'uuid';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface AppLocalKeyStore {
  browserId: string;
  updateBrowserId: () => void;
}

export const appLocalStore: StateCreator<AppLocalKeyStore, [], [], AppLocalKeyStore> = set => ({
  browserId: null,
  updateBrowserId: () => set(() => ({ browserId: uuidv4() })),
});

export const useAppLocalStore = create<AppLocalKeyStore>()(
  persist(appLocalStore, {
    name: 'app-local-store',
    getStorage: () => localStorage,
  })
);

if (useAppLocalStore.getState().browserId === null) {
  useAppLocalStore.getState().updateBrowserId();
}
