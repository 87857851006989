import type { StateCreator } from 'zustand';
import { create } from 'zustand';

type IntercomStoreState = {
  readonly isBooted: boolean;
  setBooted(): void;
};

export const intercomStore: StateCreator<IntercomStoreState, [], [], IntercomStoreState> = set => ({
  isBooted: false,
  setBooted: () =>
    set(() => {
      return {
        isBooted: true,
      };
    }),
});

export const useIntercomStore = create<IntercomStoreState>()(intercomStore);
