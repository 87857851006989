import type { ShippingDetails } from '@gik/checkout/types';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { ButtonElement } from '@gik/ui/Button/ButtonProps';
import type { FormProps } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import type { FormApi, SubmissionErrors } from 'final-form';
import React from 'react';
import { shippingFormId } from '../CheckoutForm/CheckoutForm';
import { ShippingFormSchema } from './ShippingFormSchema';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon';
import PlusIcon from '@heroicons/react/solid/PlusIcon';
import { setTimeout } from 'timers';
import { timeoutDefaultValue } from '@gik/core/constants';
import { Layout } from '@gik/ui/gik/Layout/Layout';

export interface ShippingFormProps extends FormProps {
  onBeforeSubmit?: (
    values: object,
    form: FormApi<object, object>
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;
  inkindRouteId?: string;
  hasEmail?: boolean;
  buttons?: (isFormValid: boolean) => React.ReactNode;
  buttonsPortal?: HTMLElement;
  recipient?: boolean;
  onShowAddress2Field?(): void;
}

function ShippingFormComp({
  initialValues,
  hasEmail,
  onSubmit,
  onBeforeSubmit,
  inkindRouteId,
  buttons,
  buttonsPortal,
  recipient,
  onShowAddress2Field,
  ...otherProps
}: ShippingFormProps): React.ReactElement {
  const bem = useBemCN('shipping-form');

  const [showAddress2Field, setShowAddress2Field] = React.useState(false);
  const handleShowAddress2Field = React.useCallback(() => {
    setShowAddress2Field(true);
    setTimeout(() => onShowAddress2Field?.(), timeoutDefaultValue);
  }, [onShowAddress2Field]);

  const handleSubmit = React.useCallback(
    (values: ShippingDetails, form: FormApi<object, object>): void => {
      if (onBeforeSubmit) onBeforeSubmit(values, form);
      if (onSubmit) onSubmit(values, form);
    },
    [onBeforeSubmit, onSubmit]
  );

  return (
    <Form
      id={shippingFormId}
      onSubmit={handleSubmit}
      schema={ShippingFormSchema({
        ref: undefined,
        hasEmail,
        lastNameRequired: true,
        emailReadOnly: !!inkindRouteId,
        nameReadOnly: !!inkindRouteId,
        recipient,
      })}
      initialValues={initialValues}
      vertical
      validateOnBlur={false}
      // restoreAfterUpdate
      {...otherProps}
      render={renderProps => {
        return (
          <>
            <section {...bem('first-name-last-name-section')}>
              <FormField name="firstName" className="tw-flex-1" />
              <FormField name="lastName" className="tw-flex-1" />
            </section>
            {hasEmail && <FormField name="email" />}
            {/*<FormField name="companyName" />*/}
            <FormField name="country" />
            <FormField name="address1" />
            <div>
              {showAddress2Field ? (
                <FormField name="address2" />
              ) : (
                <div {...bem('address-2-field-wrapper')}>
                  <Button circle variant={'default'} size={'xs'} onClick={handleShowAddress2Field}>
                    <SvgIcon Icon={PlusIcon} />
                  </Button>{' '}
                  <p {...bem('address-2-field-copy')}>Add address line</p>
                </div>
              )}
            </div>
            <section {...bem('city-state-zip-section')}>
              <FormField name="city" className="tw-flex-1" />
              <div {...bem('state-zip-section')}>
                <FormField name="state" />
                <FormField
                  name="postalCode"
                  enterKeyHint="send"
                  onKeyPress={(event: React.KeyboardEvent<ButtonElement>) => {
                    if (event.key === 'Enter') {
                      // Trigger the button element with a click
                      const btnEl = document.getElementById(shippingFormId + '-btn') as ButtonElement;
                      if (btnEl) btnEl.click();
                    }
                  }}
                />
              </div>
            </section>

            {renderPortal?.(buttons && buttons?.(renderProps.isValid), () => buttonsPortal)}
          </>
        );
      }}
    />
  );
}

export const ShippingForm = withComponentErrorBoundary(ShippingFormComp);
