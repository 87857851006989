import { v4 as uuidv4 } from 'uuid';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IdempotencyKeysStore {
  createFlowIdempotencyKey: string;
  updateCreateFlowIdempotencyKey: () => void;

  frontendTransactionId: string;
  updateFrontendTransactionId: () => void;

  expressCheckoutUniqueKey: string;
  updateExpressCheckoutUniqueKey: () => void;
}

function createIdempotencyKey(): string {
  return uuidv4();
}

const idempotencyKeyStore: StateCreator<IdempotencyKeysStore, [], [], IdempotencyKeysStore> = set => ({
  createFlowIdempotencyKey: null,
  updateCreateFlowIdempotencyKey: () => set(() => ({ createFlowIdempotencyKey: createIdempotencyKey() })),

  frontendTransactionId: null,
  updateFrontendTransactionId: () => set(() => ({ frontendTransactionId: createIdempotencyKey() })),

  expressCheckoutUniqueKey: null,
  updateExpressCheckoutUniqueKey: () => set(() => ({ expressCheckoutUniqueKey: createIdempotencyKey() })),
});

export const useIdempotencyKeysStore = create<IdempotencyKeysStore>()(
  persist(idempotencyKeyStore, {
    name: 'idempotency-keys',
    getStorage: () => localStorage,
  })
);

if (useIdempotencyKeysStore.getState().createFlowIdempotencyKey === null) {
  useIdempotencyKeysStore.getState().updateCreateFlowIdempotencyKey();
}
