import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { auth } from '@gik/auth';
import { SignInFlowCalloutBlock } from '@gik/auth/components/SignInFlow/SignInFlowCallout';
import { SignInFlowContentCopyVariant } from '@gik/auth/components/SignInFlow/SignInFlowStartContent';
import type { ICalendarEventFormValues } from '@gik/calendar';
import { CalendarClaimEvent } from '@gik/calendar';
import type { ClaimConflictResolve, OrderStatus } from '@gik/checkout/api';
import type { CheckoutFormStepId } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import { tryToNavigateToPaymentStep } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import { CheckoutFormModalContext } from '@gik/checkout/components/CheckoutForm/CheckoutFormModalContext';
import { useCheckoutFormModalStore } from '@gik/checkout/store/CheckoutFormModalStore';
import { useCheckoutStore } from '@gik/checkout/store/CheckoutStore';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { User } from '@gik/core/models/gik/User';
import { openPrivacyPolicySheet } from '@gik/core/pages/Legal/PrivacyPolicy';
import { openTermsOfServiceSheet } from '@gik/core/pages/Legal/TermsOfService';
import { useUserStore } from '@gik/core/store/UserStore';
import sleep from '@gik/core/utils/sleep';
import { isEmpty } from '@gik/core/utils/validator';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { useDialogStore } from '@gik/ui/Dialogs';
import { ModalButtonFooter } from '@gik/ui/Modal/ModalButtonFooter';
import { UI } from '@gik/ui/UIManager';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import { scroller } from 'react-scroll';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getAnnouncementUIContextFromId } from '../components/Announcements';
import { CalendarAnnouncementViewWrapper } from '../components/Announcements/CalendarAnnouncementViewWrapper';
import { CalendarEditAnnouncementForm } from '../components/Announcements/CalendarEditAnnouncementForm';
import CalendarClaimView from '../components/ClaimEvent/CalendarClaimView';
import { CalendarEventCreateFlow } from '../components/EventCreateForm/CalendarEventCreateFlow';
import { CalendarEditEventForm } from '../components/EventForm/CalendarEditEventForm';
import type { ICalendarAnnouncement, ICalendarEntry, ICalendarEvent } from '../models/Calendar';
import { useCalendarStore } from '../store/CalendarStore';

type IClaimRequest = {
  event: ICalendarEvent;
  entry: ICalendarEntry;
  routeId: string;
};

interface ICalendarModalsStore {
  readonly claimRequest: IClaimRequest;
  readonly setClaimRequest: (claimRequest: IClaimRequest) => void;
}

export const calendarModalsStore: StateCreator<ICalendarModalsStore, [], [], ICalendarModalsStore> = set => ({
  claimRequest: null,
  setClaimRequest: claimRequest => set({ claimRequest }),
});

export const useCalendarModalsStore = create<ICalendarModalsStore>()(
  persist(calendarModalsStore, {
    name: 'calendar-modals',
    getStorage: () => sessionStorage,
  })
);

function markClaim(event: ICalendarEvent, entry: ICalendarEntry, routeId: string) {
  useCalendarModalsStore.getState().setClaimRequest({ event, entry, routeId });
}

export function getClaim(): IClaimRequest {
  return useCalendarModalsStore.getState().claimRequest;
}

function unmarkClaim() {
  useCalendarModalsStore.getState().setClaimRequest(null);
}

export function add(date?: Moment) {
  const footerId = 'CalendarEventAddFormFooter';

  // reset whether the user has changed gift cards
  useCalendarStore.getState().setHasChangedGiftCards(false);
  const inkindRouteId = useInkindStore.getState().inkindRouteId;

  return UI.dialog<ICalendarEventFormValues>(
    ({ close }) => {
      return (
        <CalendarEventCreateFlow
          date={date}
          buttonsPortal={() => document.getElementById(footerId)}
          onSuccess={async (newEventId: string, addMore: boolean) => {
            close(true);
            await useCalendarStore.getState().reloadFunction();
            useCalendarStore.getState().setFormSaving(false);

            setTimeout(() => {
              useCalendarStore.getState().scrollToEntryById(newEventId);
            }, 300);

            if (addMore) {
              setTimeout(() => {
                add(date);
              }, 1000);
            }
          }}
        />
      );
    },
    {
      title: 'Add Request',
      closable: true,
      onClose: response => {
        if (!response) Analytics.fireEvent(AnalyticsEvents.EventCreateCancelled, { inkindRouteId });
        return true;
      },
      autowidth: false,
      modalProps: {
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
        allowIntercom: true,
      },
      className: 'gik-calendar-modal gik-calendar-add-event',
      footer: <ModalButtonFooter centeredButtons={false} id={footerId} />,
    }
  );
}

export async function edit(event: ICalendarEvent, entry: ICalendarEntry) {
  const footerId = 'CalendarEventEditFormFooter';

  // TODO: use footerRef
  const response: ICalendarEventFormValues = await UI.dialog<ICalendarEventFormValues>(
    ({ close }) => {
      return (
        <CalendarEditEventForm
          event={event}
          entry={entry}
          // TODO: pass footerRef instead
          buttonsPortal={() => document.getElementById(footerId)}
          onSuccess={() => {
            close(true);
            useCalendarStore.getState().reloadFunction();
          }}
          onDelete={() => {
            close(false);
            useCalendarStore.getState().reloadFunction();
          }}
        />
      );
    },
    {
      title: 'Edit Request',
      closable: true,
      autowidth: false,
      modalProps: {
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
        allowIntercom: true,
      },
      className: 'gik-calendar-modal gik-calendar-edit-event',
      // TODO: we should not need to pass footer here because we should use ModalButtonFooter as default in Dialogs
      footer: <ModalButtonFooter id={footerId} />,
    }
  );

  return response;
}

export async function editAnnouncement(event: ICalendarEvent, entry: ICalendarAnnouncement) {
  const footerId = 'CalendarEventEditFormFooter';

  const response = await UI.dialog(
    ({ close }) => {
      return (
        <CalendarEditAnnouncementForm
          entry={entry}
          event={event}
          buttonsPortal={() => document.getElementById(footerId)}
          onSuccess={() => {
            close(true);
            useCalendarStore.getState().reloadFunction();
          }}
          onDelete={() => {
            close(false);
            useCalendarStore.getState().reloadFunction();
          }}
        />
      );
    },
    {
      title: 'Edit Announcement',
      closable: true,
      autowidth: false,
      modalProps: {
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
      },
      className: 'gik-calendar-modal gik-calendar-edit-announcement',
      footer: <ModalButtonFooter id={footerId} />,
    }
  );

  return response;
}

export async function viewAnnouncement(event: ICalendarEvent, entry: ICalendarAnnouncement) {
  const footerId = 'CalendarEventEditFormFooter';

  const typeContext = getAnnouncementUIContextFromId(entry.announcementTypeId);

  const response = await UI.dialog(
    ({ close }) => {
      return (
        <CalendarAnnouncementViewWrapper
          startsAt={event.startsAt}
          endsAt={event.endsAt}
          entryId={event.entryId}
          allDay={entry.allDay}
          typeId={entry.announcementTypeId}
          description={entry.description}
          title={entry.title}
          typeContext={typeContext}
          buttonsPortal={() => document.getElementById(footerId)}
          onDone={close}
          onEdit={() => {
            close();
            setTimeout(() => {
              editAnnouncement(event, entry);
            }, 300);
          }}
          onDeleted={() => {
            close();
            useCalendarStore.getState().reloadFunction();
          }}
        />
      );
    },
    {
      title: 'Announcement',
      closable: true,
      autowidth: false,
      className: 'gik-calendar-modal gik-calendar-view-announcement',
      footer: () => <ModalButtonFooter id={footerId} />,
    }
  );

  return response;
}

export async function isBillingFormFilledOut() {
  const schema = useCheckoutFormModalStore.getState().billingFormSchema.current;
  const values = await new Promise(resolve => {
    const cancel = useCheckoutFormModalStore.getState().billingFormRef.current?.subscribe('values', values => {
      sleep().then(() => {
        cancel?.();
        resolve(values);
      });
    });
  });

  const isCardFieldFilledOut = useCheckoutFormModalStore.getState().isCardFieldComplete.current;

  return (
    isCardFieldFilledOut &&
    schema
      ?.filter(entry => !!entry.required)
      .map(entry => entry.name)
      .every(key => !isEmpty(values[key]))
  );
}

export function scrollToPayment() {
  const userId = useUserStore.getState().id;
  const target = userId ? 'payment-container' : 'subscribe-to-newsletter';
  scroller.scrollTo(target, {
    duration: 300,
    offset: -17,
    smooth: true,
    container:
      document.getElementById(checkoutFormContentId) ?? document.getElementById(calendarEventClaimFormContentId),
  });
}

export async function confirmCloseCheckoutModal(
  handleOpenTerms: () => Promise<void>,
  handleOpenPrivacyPolicy: () => Promise<void>
) {
  await isBillingFormFilledOut();
  const isFormFilledOut = await isBillingFormFilledOut();

  return UI.confirm(
    <>
      <p className={'gik-checkout-form-confirm-cancel-purchase__copy-top'}>
        Your purchase is almost complete! Are you absolutely sure you want to cancel now?
      </p>
      {isFormFilledOut && (
        <p className={'gik-checkout-form-confirm-cancel-purchase__copy-bottom'}>
          By clicking “complete purchase” you agree to the website{' '}
          <Button variant={'default-extra-dark-link-solid'} onClick={handleOpenTerms}>
            terms and conditions
          </Button>{' '}
          and{' '}
          <Button variant={'default-extra-dark-link-solid'} onClick={handleOpenPrivacyPolicy}>
            privacy policy
          </Button>
          .
        </p>
      )}
    </>,
    {
      title: 'Cancel Purchase?',
      closable: true,
      cancelButtonProps: {
        variant: 'danger',
      },
      cancelText: 'Cancel Purchase',
      okText: isFormFilledOut ? 'Complete Purchase' : 'back to checkout',
      className: 'gik-checkout-form-confirm-cancel-purchase',
    }
  );
}

export const calendarEventClaimFormContentId = 'CalendarEventClaimFormContent';
export const checkoutFormContentId = 'CheckoutFormContent';

export async function claim(event: ICalendarEvent, entry: ICalendarEntry, routeId: string) {
  const calendarStore = useCalendarStore.getState();
  // if the user is not logged in yet store this entry id and start date so we can
  // show the login dialog and return the user to this claim after signup
  if (!useUserStore.getState().id) {
    markClaim(event, entry, routeId);

    return void auth.signin({
      callout: SignInFlowCalloutBlock.CALENDAR_CLAIM,
      copyVariant: SignInFlowContentCopyVariant.INKIND_PAGE,
      routeId,
      noRedirect: true,
    });
  }

  const headerId = 'CalendarEventClaimFormHeader';
  const footerId = 'CalendarEventClaimFormFooter';

  const submitSucceeded = React.createRef<boolean>() as React.MutableRefObject<boolean>;
  const closeButtonOverride = React.createRef<() => Promise<boolean>>() as React.MutableRefObject<
    () => Promise<boolean>
  >;
  const currentStep = React.createRef<CheckoutFormStepId>() as React.MutableRefObject<CheckoutFormStepId>;
  const completedStep = React.createRef<CheckoutFormStepId>() as React.MutableRefObject<CheckoutFormStepId>;

  // get selected eventType
  // const eventType = useCalendarStore
  //   .getState()
  //   .calendarEventTypes.find(eventType => eventType.id === event.typeId);

  function reloadMonth() {
    useCalendarStore.getState().reloadFunction(useCalendarStore.getState().selectedMonth);
  }

  function confirmCloseModal() {
    return confirmCloseCheckoutModal(handleOpenTerms, openPrivacyPolicy);
  }

  async function handleOpenTerms() {
    // close top most dialog (the cancel purchase modal)
    UI.closeDialog();

    // open the terms and conditions modal sheet
    openTermsOfServiceSheet({
      onClosed: async () => {
        const response = await confirmCloseModal();

        if (response === false) {
          return UI.closeAllDialogs();
        }

        if (response === true) {
          await tryToNavigateToPaymentStep(currentStep);
          await onCompletePurchase?.();

          return false;
        }
      },
    });
  }

  async function openPrivacyPolicy() {
    // close top most dialog (the cancel purchase modal)
    UI.closeDialog();

    // open the terms and conditions modal sheet
    openPrivacyPolicySheet({
      onClosed: async () => {
        const response = await confirmCloseModal();

        if (response === false) {
          return UI.closeAllDialogs();
        }

        if (response === true) {
          await tryToNavigateToPaymentStep(currentStep);
          await onCompletePurchase?.();

          return false;
        }
      },
    });
  }

  const onCompletePurchase = async () => {
    if (await isBillingFormFilledOut()) {
      useCheckoutFormModalStore.getState().billingFormRef.current?.submitForm();
    } else {
      scrollToPayment();
    }
  };

  const inkindRouteId = useInkindStore.getState().inkindRouteId;
  const response: ICalendarEventFormValues = await UI.dialog<ICalendarEventFormValues>(
    ({ close, setTitle, setClosable, setOnBack, setFooterClass, scrollToTop }) => {
      const [selectedEvent, setSelectedEvent] = React.useState(event);
      const [selectedEntry, setSelectedEntry] = React.useState(entry);

      const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
      const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
      const [anonymous, setAnonymous] = React.useState<boolean>(false);
      const [privateClaim, setPrivateClaim] = React.useState<boolean>(false);
      const [selectedShippingOptionName, setSelectedShippingOptionName] = React.useState<string>();

      const [mainCart, setMainCart] = React.useState<CartItem[]>();
      const [agree, setAgree] = React.useState<boolean>();
      const [suggestedResolves, setSuggestedResolves] = React.useState<ClaimConflictResolve[]>();
      const [messageToRecipient, setMessageToRecipient] = React.useState<string>();
      const [orderStatus, setOrderStatus] = React.useState<OrderStatus>();
      const modalContentRef = React.useRef<HTMLDivElement>(null);

      return (
        <CheckoutFormModalContext.Provider
          value={{
            modalContentRef,
            isSubmitting,
            setIsSubmitting,
            isSubmitted,
            setIsSubmitted,
            mainCart,
            setMainCart,
            anonymous,
            setAnonymous,
            suggestedResolves,
            setSuggestedResolves,
            privateClaim,
            setPrivateClaim,
            messageToRecipient,
            setMessageToRecipient,
            selectedShippingOptionName,
            setSelectedShippingOptionName,
            orderStatus,
            setOrderStatus,
          }}
        >
          <CalendarClaimEvent
            modalContentId={calendarEventClaimFormContentId}
            event={selectedEvent}
            entry={selectedEntry}
            setSelectedEvent={setSelectedEvent}
            setSelectedEntry={setSelectedEntry}
            stepsNavPortal={() => document.getElementById(headerId)}
            buttonsPortal={() => document.getElementById(footerId)}
            updateModalTitle={setTitle}
            setModalClosable={setClosable}
            setOnDialogBack={setOnBack}
            setDialogFooterClass={setFooterClass}
            onStepChange={step => {
              currentStep.current = step;
            }}
            onStepProgression={step => {
              completedStep.current = step;
            }}
            closeButtonOverrideRef={closeButtonOverride}
            onSuccess={() => {
              submitSucceeded.current = true;
              reloadMonth();
            }}
            onFailed={() => {
              reloadMonth();
            }}
            onConflict={() => {
              reloadMonth();
            }}
            onEditSuccess={() => {
              close(true);
              reloadMonth();
            }}
            onUnclaim={() => {
              close(true);
              reloadMonth();
            }}
            onClose={() => {
              close(true);
            }}
          />
        </CheckoutFormModalContext.Provider>
      );
    },
    {
      title: '', // set in CalendarClaimEvent
      closable: true,
      modalProps: {
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
      },
      onReady: () =>
        Analytics.fireEvent(AnalyticsEvents.CalendarEventClaimModalOpened, {
          inkindRouteId,
          requestType: entry.typeSlug,
        }),
      autowidth: false,
      padding: true,
      className: 'gik-claim-modal gik-calendar-claim-event-modal',
      headerId: headerId,
      contentId: calendarEventClaimFormContentId,
      footer: <ModalButtonFooter id={footerId} centeredButtons={false} />,
      onClose: async () => {
        const cart = useCheckoutStore.getState().cart;

        if (closeButtonOverride.current) {
          await closeButtonOverride.current();
          return false;
        }

        if (completedStep.current === 'payment' && submitSucceeded.current != true && cart.length > 0) {
          const response = await confirmCloseModal();

          if (response === undefined) {
            return false;
          }

          if (response === true) {
            await tryToNavigateToPaymentStep(currentStep);
            onCompletePurchase?.();

            return false;
          }
        }

        Analytics.fireEvent(AnalyticsEvents.CalendarEventClaimModalClosed, { inkindRouteId });

        // reset Checkoutform store
        // wait for modal to close then reset the form to avoid flickering
        setTimeout(() => {
          useCheckoutStore.getState().setCart([]);
          useCalendarStore.getState().setClaimFailErrorCode(null);
          useCalendarStore.getState().setPaymentConfirmationValues(undefined);
        }, 800);

        return true;
      },
    }
  );

  // reset calendar store values to reset the modal
  useCalendarStore.getState().resetStore();

  return response;
}

export function viewClaim(entry: ICalendarEntry, event: ICalendarEvent) {
  const footerId = 'CalendarEventClaimViewFooter';

  UI.dialog(
    ({ close }) => (
      <CalendarClaimView
        buttonsPortal={() => document.getElementById(footerId)}
        entry={entry}
        event={event}
        onClose={() => {
          close(true);
        }}
      />
    ),
    {
      // TODO: translation do not seem to work here
      title: 'Request Details',
      closable: true,
      className: 'gik-claim-modal gik-calendar-view-claim-event-modal',
      autowidth: false,
      padding: true,
      footer: <ModalButtonFooter id={footerId} centeredButtons={true} />,
    }
  );
}

export function continueClaimAfterLogin(user: User, inkindPage: InkindPageAPIModel) {
  const { event, entry, routeId } = getClaim();
  const userId = useUserStore.getState().id;

  if (useDialogStore.getState().dialogs.length > 0) return;
  if (!event || !entry || !routeId || !userId || inkindPage.routeId != routeId) return;

  unmarkClaim();

  const isNewAccount = moment.utc().diff(user.createdDate, 'minutes') < 5;
  const message = isNewAccount
    ? 'Thank you for creating a Give InKind account. You are now following the page ' +
      inkindPage.title +
      ' and will receive updates submitted by the organizer. Please continue claiming your item...'
    : 'Thank you for logging in. Please continue claiming your item...';

  UI.notifySuccess(message, { autoCloseTime: isNewAccount ? 10000 : 5000 });

  claim(event, entry, routeId);

  document.getElementsByClassName('gik-inkind-calendar')[0].scrollIntoView();
}
