import React from 'react';

export function useIntersectObserverPolyfill() {
  React.useEffect(() => {
    if (
      !('IntersectionObserver' in window) &&
      !('IntersectionObserverEntry' in window) &&
      !('intersectionRatio' in window?.['IntersectionObserverEntry']?.['prototype'])
    ) {
      import('intersection-observer');
    }
  }, []);
}
