import type { IUserSignupFormValues } from '@gik/auth/components/Signup/SignupForm';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';

export const subscribeToNewsletterInitialValue = true;

// TODO: rename this to SignupStore
export interface IAuthStore {
  signupFormValues: Partial<IUserSignupFormValues>;
  setSignupFormValues(values: Partial<IUserSignupFormValues>): void;
}

export const authStore: StateCreator<IAuthStore, [], [], IAuthStore> = set => ({
  signupFormValues: {
    subscribeToNewsletter: subscribeToNewsletterInitialValue,
  },
  setSignupFormValues: signupFormValues =>
    set(() => {
      return {
        signupFormValues,
      };
    }),
});

export const useAuthStore = create<IAuthStore>()(authStore);
