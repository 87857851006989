import { logger } from '@gik/analytics/utils/logger';
import type { CustomError } from '@gik/api/CustomError';
import { OAuthErrorModalContent } from '@gik/auth/components/Login/OAuthErrorModalContent';
import type { WindowWithFacebookJSSDK } from '@gik/auth/types/WindowWithFacebookJSSDK';
import routes from '@gik/core/routes';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useIsInApp } from '@gik/core/utils/browser';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { FacebookWhite } from '@gik/ui/SvgIcon/BrandIcons/Facebook';

declare const window: WindowWithFacebookJSSDK;

export function useFacebookJSSDK() {
  const appId = useEnvStore(state => state.FACEBOOK_APP_ID);
  const isIAB = useIsInApp();

  React.useEffect(() => {
    if (appId)
      window.fbAsyncInit = function () {
        FB.init({
          appId,
          xfbml: true,
          version: 'v19.0',
        });
      };
  }, [appId, isIAB]);
}

export function getBaseFacebookDialogParams(isIAB: boolean): fb.LoginOptions {
  return {
    scope: 'public_profile,email',
    redirect_uri: isIAB ? location.origin + routes.inAppLogin : undefined,
    state: isIAB
      ? JSON.stringify({
          redirectUri: location.pathname,
        })
      : undefined,
  };
}

export function useBaseFacebookDialogParams() {
  const isIAB = useIsInApp();
  return React.useMemo(() => getBaseFacebookDialogParams(isIAB), [isIAB]);
}

export function facebookLoginFlow(
  dialogParams: fb.LoginOptions,
  onSuccess: (response: fb.StatusResponse) => void,
  onError: (response: fb.StatusResponse) => void,
  isIAB: boolean
) {
  if (isIAB) return redirectToDialog(dialogParams);
  FB.login(function (response) {
    if (response.authResponse) {
      onSuccess(response);
    } else {
      onError(response);
    }
  }, dialogParams);
}

export function facebookRerequestFlow(
  warningTitle: string,
  warningCopy: string | React.JSX.Element,
  dialogParams: fb.LoginOptions,
  onSuccess: (response: fb.StatusResponse) => void,
  onError: (response: fb.StatusResponse) => void,
  isIAB: boolean
) {
  UI.confirm(warningCopy, {
    title: warningTitle,
    okButtonProps: {
      className: 'facebook-login',
    },
    okText: (
      <>
        Continue via <SvgIcon className={'tw-mx-1'} Icon={FacebookWhite} /> Facebook
      </>
    ),
    cancelText: 'Use another method',
    className: 'gik-facebook-rerequest-modal',
  }).then(
    response =>
      response &&
      facebookLoginFlow(
        {
          ...dialogParams,
          auth_type: 'rerequest',
        },
        onSuccess,
        onError,
        isIAB
      )
  );
}

export function facebookRerequest(
  isIAB: boolean,
  onSuccess: (accessToken: string, rerequestFlowCallback?: (err?: CustomError) => void) => void,
  redirectUri?: string
) {
  const dialogParams = getBaseFacebookDialogParams(isIAB);
  if (redirectUri) {
    // @ts-ignore
    dialogParams.state = JSON.stringify({
      redirectUri,
    });
  }

  facebookRerequestFlow(
    'Unsuccessful Sign Up/Sign In',
    <>
      We need your email address to sign up or sign in. <br />
      <br />
      Please allow access to your email address via Facebook. Or, use another sign-up or sign-in method.
    </>,
    dialogParams,
    response =>
      onSuccess(response.authResponse.accessToken, err =>
        UI.alert(<OAuthErrorModalContent oauthErrorMessage={err.message} />, {
          closable: true,
          title: 'Unsuccessful Sign Up/Sign In',
          centeredTitle: false,
          autowidth: true,
        })
      ),
    response => logger.error('Facebook login error', { response }),
    isIAB
  );
}

const isObject = (obj: unknown): obj is Record<string, string | number | boolean> => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

const objectToParams = (obj: unknown): string => {
  if (!isObject(obj) || Object.keys(obj).length === 0) {
    return '';
  }

  return (
    '?' +
    Object.keys(obj)
      .map(key => `${key}=${encodeURIComponent(obj[key])}`)
      .join('&')
  );
};

function redirectToDialog(dialogParams: fb.LoginOptions) {
  window.location.href = `https://www.facebook.com/dialog/oauth${objectToParams({
    ...dialogParams,
    client_id: useEnvStore.getState().FACEBOOK_APP_ID,
  })}`;
}
