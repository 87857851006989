import { logger } from '@gik/analytics/utils/logger';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { confirmCloseCheckoutModal } from '@gik/calendar/utils/CalendarModals';
import type { CheckoutFormStepId } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import { tryToNavigateToPaymentStep } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import { openPrivacyPolicySheet } from '@gik/core/pages/Legal/PrivacyPolicy';
import { openTermsOfServiceSheet } from '@gik/core/pages/Legal/TermsOfService';
import type { InkindPageViewHistoryItem } from '@gik/inkind-page/components/InkindPageViewRecorder/InkindPageViewRecorder';
import { ModalButtonFooter } from '@gik/ui/Modal';
import { withStripe } from '@gik/ui/Stripe';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type { CheckoutFormWrapperProps } from '../components/CheckoutForm/CheckoutFormWrapper';
import { CheckoutFormWrapper } from '../components/CheckoutForm/CheckoutFormWrapper';
import { useCheckoutStore } from '../store/CheckoutStore';
import { getInkinds } from '@gik/api/inkinds/inkinds';

const CheckoutFormWrapperComp = withStripe(CheckoutFormWrapper);

export interface openCheckoutFormModalProps {
  onCloseSuccessfulPurchase?(): void;
  onCompletePurchase?(): Promise<void>;
  onClose?(): boolean;
  cart?: CartItem[];
  inkindRouteId: string;
  cachedProducts?: Product[]; // used to avoid requesting products again on the wishlist
  inkindPageViewHistory?: InkindPageViewHistoryItem[];
  formProps: CheckoutFormWrapperProps;
  recipientName?: string;
  customMessage?: string;
  contentId?: string;
  expiryMonth?: string;
  cardImage?: string;
  faceplate?: PerfectGiftFaceplate;
  hasPhysicalCard?: boolean;
  hasCardCarrier?: boolean;
  buyForSomeoneElse?: boolean;
  isOpenLoop?: boolean;
}

export async function openCheckoutFormModal({
  cart,
  inkindRouteId,
  recipientName,
  customMessage,
  expiryMonth,
  cardImage,
  faceplate,
  hasCardCarrier,
  hasPhysicalCard,
  isOpenLoop,
  contentId,
  buyForSomeoneElse,
  onClose,
  onCloseSuccessfulPurchase,
  onCompletePurchase,
  formProps,
}: openCheckoutFormModalProps) {
  const submitSucceeded = React.createRef<boolean>() as React.MutableRefObject<boolean>;
  const closeButtonOverride = React.createRef<() => Promise<boolean>>() as React.MutableRefObject<
    () => Promise<boolean>
  >;

  const currentStep = React.createRef<CheckoutFormStepId>() as React.MutableRefObject<CheckoutFormStepId>;
  const completedStep = React.createRef<CheckoutFormStepId>() as React.MutableRefObject<CheckoutFormStepId>;

  function confirmCloseModal() {
    return confirmCloseCheckoutModal(handleOpenTerms, handleOpenPrivacyPolicy);
  }

  async function handleOpenTerms() {
    // close top most dialog (the cancel purchase modal)
    UI.closeDialog();

    // open the terms and conditions modal sheet
    openTermsOfServiceSheet({
      onClosed: async () => {
        const response = await confirmCloseModal();

        if (response === false) {
          return UI.closeAllDialogs();
        }

        if (response === true) {
          await tryToNavigateToPaymentStep(currentStep);
          await onCompletePurchase?.();

          return false;
        }
      },
    });
  }

  async function handleOpenPrivacyPolicy() {
    // close top most dialog (the cancel purchase modal)
    UI.closeDialog();

    // open the terms and conditions modal sheet
    openPrivacyPolicySheet({
      onClosed: async () => {
        const response = await confirmCloseModal();

        if (response === false) {
          return UI.closeAllDialogs();
        }

        if (response === true) {
          await tryToNavigateToPaymentStep(currentStep);
          await onCompletePurchase?.();

          return false;
        }
      },
    });
  }

  // the donation product is donation to GIK, not to a page.
  // Giftboxes can be purchased for anyone, not just pages.
  if (
    !inkindRouteId &&
    !isOpenLoop &&
    !cart?.some(entry => entry.checkoutType === 'donation' || entry.checkoutType === 'giftbox')
  ) {
    logger.error('inkindRouteId property is missing');
  }
  if (!cart) logger.error('cart property is missing');

  let title = cart?.some(
    product =>
      product.checkoutType === CheckoutType.TangoCard ||
      product.checkoutType === CheckoutType.Perfectgift ||
      product.checkoutType === CheckoutType.GiftyaPlatform
  )
    ? 'Sending Support to ' + (await getInkinds([inkindRouteId]))?.[0]?.title
    : cart?.some(product => product.checkoutType === 'giftbox')
      ? 'Send Gift Box'
      : cart?.some(product => product.checkoutType === 'gik-premium')
        ? 'Upgrade Your Page'
        : cart?.some(product => product.checkoutType === 'donation')
          ? cart?.[0]?.name
          : `Send ${cart?.[0]?.name ?? 'Product'}`; // virtual product

  if (buyForSomeoneElse) {
    title = 'Sending support';
  }

  return UI.dialog(
    ({ close, contentRef, footerRef, headerRef, setTitle, setClosable, setOnBack, setFooterClass, scrollToTop }) => {
      return (
        <CheckoutFormWrapperComp
          inkindRouteId={inkindRouteId}
          stepsNavPortal={headerRef.current}
          buttonsPortal={footerRef.current}
          recipientName={recipientName}
          customMessage={customMessage}
          expiryMonth={expiryMonth}
          cardImage={cardImage}
          faceplate={faceplate}
          hasPhysicalCard={hasPhysicalCard}
          hasCardCarrier={hasCardCarrier}
          buyForSomeoneElse={buyForSomeoneElse}
          onCloseSuccessfulPurchase={() => {
            close();
            onCloseSuccessfulPurchase();
          }}
          closeButtonOverrideRef={closeButtonOverride}
          onSuccess={() => {
            submitSucceeded.current = true;

            // refresh the calendar
            useCalendarStore?.getState?.()?.reloadFunction?.(useCalendarStore.getState().selectedMonth);
          }}
          scrollToTop={scrollToTop}
          setTitle={setTitle}
          setClosable={setClosable}
          setOnDialogBack={setOnBack}
          setDialogFooterClass={setFooterClass}
          cart={cart}
          isOpenLoop={isOpenLoop}
          onStepChange={step => {
            currentStep.current = step;
          }}
          onStepProgression={step => {
            completedStep.current = step;
          }}
          {...formProps}
        />
      );
    },
    {
      title,
      closable: true,
      autowidth: false,
      contentId,
      onClose: async () => {
        const cart = useCheckoutStore.getState().cart;

        if (closeButtonOverride.current) {
          await closeButtonOverride.current();
          return false;
        }

        if (completedStep.current === 'payment' && submitSucceeded.current != true && cart.length > 0) {
          const response = await confirmCloseModal();

          if (response === undefined) {
            return false;
          }

          if (response === true) {
            await tryToNavigateToPaymentStep(currentStep);
            onCompletePurchase?.();
            return false;
          }
        }

        // reset Checkoutform store
        // wait for modal to close then reset the form to avoid flickering
        setTimeout(() => {
          useCheckoutStore.getState().setCart([]);
          useCalendarStore.getState().setClaimFailErrorCode(null);
          useCalendarStore.getState().setPaymentConfirmationValues(undefined);
        }, 800);

        return onClose();
      },
      modalProps: {
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
      },
      className: 'gik-modal-checkout-new',
      footer: ({ footerClass }) => {
        return <ModalButtonFooter centeredButtons={false} className={footerClass} />;
      },
    }
  );
}
