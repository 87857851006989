import { v4 as uuidv4 } from 'uuid';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface SessionKeyStore {
  browserSessionId: string;
  updateBrowserSessionId: () => void;
}

function createSessionKey(): string {
  return uuidv4();
}

export const appSessionStore: StateCreator<SessionKeyStore, [], [], SessionKeyStore> = set => ({
  browserSessionId: null,
  updateBrowserSessionId: () => set(() => ({ browserSessionId: createSessionKey() })),
});

export const useAppSessionStore = create<SessionKeyStore>()(
  persist(appSessionStore, {
    name: 'app-session-store',
    getStorage: () => sessionStorage,
  })
);

if (useAppSessionStore.getState().browserSessionId === null) {
  useAppSessionStore.getState().updateBrowserSessionId();
}
