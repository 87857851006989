import type { FormSchemaEntry } from '@gik/ui/Form';

export type StreetAddress1FieldProps = {
  required?: boolean;
};

export const StreetAddress1Field = (props?: StreetAddress1FieldProps): FormSchemaEntry => ({
  label: 'Street address',
  name: 'address1',
  type: 'text',
  required: props?.required !== undefined ? props?.required : true,
  minLength: 2,
  maxLength: 255,
  props: { placeholder: 'U.S. Addresses only', autoComplete: 'address-line1' },
});

export const StreetAddress2Field = (props?: StreetAddress1FieldProps): FormSchemaEntry => ({
  name: 'address2',
  type: 'text',
  required: props?.required,
  minLength: 2,
  maxLength: 255,
  props: { placeholder: 'Apartment, suite, unit, etc (optional)', autoComplete: 'address-line2' },
});
