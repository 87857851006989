import type { UIComponent } from '@gik/core/types/UI';
import React from 'react';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { ReadyToPayChangeResponse } from '../GooglePayButton/GooglePayButton';
import { useEnvStore } from '@gik/core/store/EnvStore';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { logger } from '@gik/analytics/utils/logger';
import { ExpressCheckoutElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import type { PaymentIntent, StripeError, StripeExpressCheckoutElementReadyEvent, Order } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createPaymentIntentFromApplePay } from '../../api';
import type { StripeElementsOptionsMode } from '@stripe/stripe-js/types/stripe-js/elements-group';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useIdempotencyKeysStore } from '@gik/core/store/IdempotencyKeysStore';

const stripePromise = loadStripe(useEnvStore.getState().STRIPE_API_KEY);

export type IApplePayButtonProps = {
  cart?: CartItem[];
  tip?: number;
  shipping?: number;
  total: number;
  onAuthorized: (paymentIntent: PaymentIntent, billingDetails: Order.Billing) => Promise<void>;
  onReadyToPayChange: (result: ReadyToPayChangeResponse) => void;
  disabled?: boolean;
} & UIComponent;

export const ApplePayButton = withComponentErrorBoundary(ApplePayStripeButtonComp);

function ApplePayStripeButtonComp({ cart, tip, shipping, total, onAuthorized }: IApplePayButtonProps) {
  const options: StripeElementsOptionsMode = {
    mode: 'payment',
    amount: total * 100,
    currency: 'usd',
    capture_method: 'manual',
  };

  const onConfirm = async (paymentIntent: PaymentIntent, billingDetails: Order.Billing) => {
    await onAuthorized?.(paymentIntent, billingDetails);
  };

  const onReady = async (e: StripeExpressCheckoutElementReadyEvent) => {};
  const onLoadError = error => {};
  const onCancel = e => {};

  if (!cart?.length || !total || total <= 0) return null;

  //  TODO: CHANGE STRIPE PROMISE KEY TO ENV VAR
  return (
    <Elements stripe={stripePromise} options={options}>
      <ApplePayExpressCheckoutElement
        onLoadError={onLoadError}
        onCancel={onCancel}
        onReady={onReady}
        onConfirm={onConfirm}
        cart={cart}
        tip={tip}
        shipping={shipping}
        total={total}
      />
    </Elements>
  );
}

type IApplePayExpressCheckoutElementProps = {
  onLoadError?: (event: { elementType: 'expressCheckout'; error: StripeError }) => void;
  onCancel: (event: { elementType: 'expressCheckout' }) => void;
  onReady: (e: StripeExpressCheckoutElementReadyEvent) => void;
  onConfirm: (paymentIntent: PaymentIntent, billingDetails: Order.Billing) => void;
  cart?: CartItem[];
  tip?: number;
  shipping?: number;
  total: number;
};

function ApplePayExpressCheckoutElement({
  cart,
  tip,
  shipping,
  total,
  onLoadError,
  onCancel,
  onReady,
  onConfirm,
}: IApplePayExpressCheckoutElementProps) {
  const stripe = useStripe();
  const elements = useElements();

  const { lineItems } = useCartToLineItems(cart, tip, shipping);

  const handleConfirm = async () => {
    try {
      useIdempotencyKeysStore.getState().updateExpressCheckoutUniqueKey();
      const uniqueKey = useIdempotencyKeysStore.getState().expressCheckoutUniqueKey;
      const paymentIntent = await createPaymentIntentFromApplePay(total * 100, uniqueKey);

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: paymentIntent.client_secret,
        confirmParams: {
          return_url: useEnvStore.getState().DOTNET_API_URL + '/orders/apple-pay-return-url',
        },
        redirect: 'if_required',
      });

      if (error) {
        logger.error('Apple Pay Error creating token', { error });
        throw error;
      }

      logger.log('Apple Pay payment confirmed');

      const billing = await dotnetApi
        .get('orders/apple-pay-billing-info', {
          searchParams: {
            id: paymentIntent.id,
            uniqueKey,
          },
        })
        .json<Order.Billing>();

      onConfirm(paymentIntent, billing);
    } catch (e) {
      logger.error('Apple Pay error:', {
        e,
      });

      throw e;
    }
  };

  return (
    <ExpressCheckoutElement
      options={{
        buttonTheme: {
          applePay: 'black',
        },
        wallets: {
          applePay: 'always',
          googlePay: 'never',
          // @ts-ignore
          amazonPay: 'never',
          paypal: 'never',
          link: 'never',
        },
        paymentMethodOrder: ['applePay'],
        lineItems,
        billingAddressRequired: true,
        emailRequired: true,
        phoneNumberRequired: true,
      }}
      onLoadError={onLoadError}
      onCancel={onCancel}
      onReady={onReady}
      onConfirm={handleConfirm}
    />
  );
}

function useCartToLineItems(cart: CartItem[], tip: number, shipping: number) {
  return React.useMemo(
    () => ({
      lineItems: cart
        ?.map(item => ({
          name: item.name,
          amount: item.price * 100,
        }))
        .concat(
          tip
            ? [
                {
                  name: 'Give InKind Tip',
                  amount: tip * 100,
                },
              ]
            : []
        )
        .concat(
          shipping
            ? [
                {
                  name: 'Shipping',
                  amount: shipping * 100,
                },
              ]
            : []
        ),
    }),
    [cart, shipping, tip]
  );
}
