import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface IDevStore {
  isOpen?: boolean;
  setIsOpen?(isOpen: boolean): void;

  activeTab?: number;
  setActiveTab?(activeTab: number): void;

  forceSkeletons?: boolean;
  setForceSkeletons?(forceSkeletons: boolean): void;

  forceErrorBoundary?: boolean;
  setForceErrorBoundary?(forceErrorBoundary: boolean): void;

  forceViewportFallbacks?: boolean;
  setForceViewportFallbacks?(forceViewportFallback: boolean): void;

  productSlug?: string;
  setProductSlug?(slug: string): void;

  groupSlug?: string;
  setGroupSlug?(slug: string): void;

  prefixNewPages?: boolean;
  setPrefixNewPages?(isOpen: boolean): void;

  streamDebug?: boolean;
  setStreamDebug?(isOpen: boolean): void;
}

const initialState = {
  isOpen: false,
  forceSkeletons: false,
  forceErrorBoundary: false,
  prefixNewPages: true,
  forceViewportFallbacks: false,
  streamDebug: false,
};

export const devStore: StateCreator<IDevStore, [], [], IDevStore> = set => ({
  ...initialState,
  setForceSkeletons: (forceSkeletons: boolean) =>
    set(() => {
      return {
        forceSkeletons,
      };
    }),
  setForceErrorBoundary: (forceErrorBoundary: boolean) =>
    set(() => {
      return {
        forceErrorBoundary,
      };
    }),
  setForceViewportFallbacks: (forceViewportFallbacks: boolean) =>
    set(() => {
      return {
        forceViewportFallbacks,
      };
    }),
  setProductSlug: (productSlug: string) =>
    set(() => {
      return {
        productSlug,
      };
    }),
  setGroupSlug: (groupSlug: string) =>
    set(() => {
      return {
        groupSlug,
      };
    }),
  setIsOpen: (isOpen: boolean) =>
    set(() => {
      return {
        isOpen,
      };
    }),
  setActiveTab: (id: number) =>
    set(() => {
      return {
        activeTab: id,
      };
    }),
  setPrefixNewPages: (prefixNewPages: boolean) =>
    set(() => {
      return {
        prefixNewPages,
      };
    }),
  setStreamDebug: (streamDebug: boolean) =>
    set(() => {
      return {
        streamDebug,
      };
    }),
});

export const useDevStore = create<IDevStore>()(
  persist(devStore, {
    name: 'devtools',
    getStorage: () => localStorage,
  })
);
