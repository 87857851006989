import type { UseMutationOptions } from '@tanstack/react-query';
import { usePlatformManagementClient } from '../../../contexts';
import type { PlatformManagementApiResponse, StrapiSingleDataSource } from '@gik/platform-management-api-js';
import type { WalletDeactivatePayload, WalletEntity } from '@gik/platform-management-api-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { buildOptions } from '../../../utils/buildOptions';

type CreatePayload = WalletEntity;
type UpdatePayload = Partial<WalletEntity>;

const uniqueName = 'wallet';
export const useAdminWalletCreate = (
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>,
    Error,
    CreatePayload
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_create`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>, Error, CreatePayload>(
    (payload: CreatePayload) => client.admin.wallet.create(payload),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminWalletUpdate = (
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>,
    Error,
    UpdatePayload
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_update`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>, Error, UpdatePayload>(
    (payload: UpdatePayload) => client.admin.wallet.update(payload.id.toString(), payload),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminWalletActivate = (options?: UseMutationOptions<boolean, Error, string>) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_deavtivate`;

  return useMutation<boolean, Error, string>(
    (customerId: string) => client.admin.wallet.activateByCustomerId(customerId),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminWalletDeactivate = (options?: UseMutationOptions<boolean, Error, WalletDeactivatePayload>) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_deavtivate`;

  return useMutation<boolean, Error, WalletDeactivatePayload>(
    (payload: WalletDeactivatePayload) =>
      client.admin.wallet.deactivateByCustomerId(payload.customerId, payload.markAsUsed),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminWalletUpdateCounters = (options?: UseMutationOptions<boolean, Error, WalletDeactivatePayload>) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_deavtivate`;

  return useMutation<boolean, Error, WalletDeactivatePayload>(
    (payload: WalletDeactivatePayload) => client.admin.wallet.updateCounters(payload.customerId),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminWalletUpdateName = (options?: UseMutationOptions<boolean, Error, WalletDeactivatePayload>) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_deavtivate`;

  return useMutation<boolean, Error, WalletDeactivatePayload>(
    (payload: WalletDeactivatePayload) => client.admin.wallet.updateName(payload.customerId),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminWalletUpdateByInkindRouteId = (
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>,
    Error,
    UpdatePayload
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_update`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>, Error, UpdatePayload>(
    (payload: UpdatePayload) => client.admin.wallet.update(payload.id.toString(), payload),
    buildOptions(queryClient, [key], options)
  );
};

export const useCancelMergedGiftForCardLinking = (
  customerId: string,
  options?: UseMutationOptions<boolean, Error, string>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_cancel-merged-gift-for-card-linking`;

  return useMutation<boolean, Error, string>(
    (giftUuid: string) => client.admin.wallet.cancelMergedGiftForCardLinking(customerId, giftUuid),
    buildOptions(queryClient, [key], options)
  );
};

export const useCancelMergedGiftForCardLinking2 = (
  options?: UseMutationOptions<boolean, Error, { customerId: string; giftUuid: string }>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_cancel-merged-gift-for-card-linking`;

  return useMutation<boolean, Error, { customerId: string; giftUuid: string }>(
    ({ customerId, giftUuid }) => client.admin.wallet.cancelMergedGiftForCardLinking(customerId, giftUuid),
    buildOptions(queryClient, [key], options)
  );
};

export const useWalletSecurityLock = (options?: UseMutationOptions<boolean, Error, { customerId: string }>) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_security-lock`;

  return useMutation<boolean, Error, { customerId: string }>(
    ({ customerId }) => client.admin.wallet.securityLock(customerId),
    buildOptions(queryClient, [key], options)
  );
};

export const useWalletSecurityUnlock = (options?: UseMutationOptions<boolean, Error, { customerId: string }>) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_security-unlock`;

  return useMutation<boolean, Error, { customerId: string }>(
    ({ customerId }) => client.admin.wallet.securityUnlock(customerId),
    buildOptions(queryClient, [key], options)
  );
};
