const prefix = 'gift-wallet/';

const giftNote = {
  list: () => `${prefix}gift-notes`,
  get: (id: string) => `${prefix}gift-notes/${id}`,
};

const gift = {
  list: () => `${prefix}gifts`,
  // create: () => `${prefix}gifts`,
  // update: (id: number) => `${prefix}gifts/${id}`,
  // delete: (id: number) => `${prefix}gifts/${id}`,

  listByCustomerId: (id: string) => `${prefix}gifts/customer/${id}`,
  listByCustomerIdRedeemableGrouped: (id: string) => `${prefix}gifts/customer/${id}/redeemable-grouped`,
  listByCustomerIdRedeemable: (id: string) => `${prefix}gifts/customer/${id}/redeemable`,
  listByCustomerIdMore: (id: string, baseGiftSourceProductId: string) =>
    `${prefix}gifts/customer/${id}/more?baseSourceProductId=${baseGiftSourceProductId}`,
  listByCustomerIdActive: (id: string) => `${prefix}gifts/customer/${id}/active`,
  listByCustomerIdUsed: (id: string) => `${prefix}gifts/customer/${id}/used`,
  listByCustomerIdActiveLinked: (id: string) => `${prefix}gifts/customer/${id}/active-card-linked`,
  listByCustomerIdUsedLinked: (id: string) => `${prefix}gifts/customer/${id}/used-card-linked`,
  listByCustomerIdLinkedToCard: (id: string, cardId: string) =>
    `${prefix}gifts/customer/${id}/linked-to-card/${cardId}`,
  usePaymentCardsByCustomerId: (customerId: string, cardId: string) =>
    `${prefix}/payment-cards/${customerId}/card/${cardId}`,
  listByCustomerIdUnused: (id: string) => `${prefix}gifts/customer/${id}/unused`,
  getActivityLog: (uuid: string) => `${prefix}gifts/${uuid}/activity-log`,
  getGiftHistory: (uuid: string) => `${prefix}gifts/${uuid}/history`,
  getGiftLinkedCardTransactionHistory: (uuid: string) => `${prefix}gifts/${uuid}/card-transaction-history`,
  getCardTransactionHistoryByGyGiftid: (gyGiftId: string) => `${prefix}card-transaction-history/${gyGiftId}`,
  getCardTransactionHistoryByGiftUuid: (giftUuid: string) => `${prefix}card-transaction-history-uuid/${giftUuid}`,
  getByUuid: (uuid: string) => `${prefix}gifts/${uuid}`,
  getByUuidDetailed: (uuid: string) => `${prefix}gifts/${uuid}/detailed`,

  getRemoteGift: (uuid: string) => `${prefix}gifts/${uuid}/remote`,
  getRemoteEGift: (uuid: string) => `${prefix}gifts/${uuid}/egift`,
  sendEmail: (uuid: string) => `${prefix}gifts/${uuid}/email-egift`,
  emailLegacyGift: (uuid: string) => `${prefix}gifts/${uuid}/email-legacy-gift`,
  emailDigitalVisa: (uuid: string) => `${prefix}gifts/${uuid}/email-digital-visa`,
  sendThankYouMessage: (uuid: string) => `${prefix}gifts/${uuid}/send-thank-you`,
  cancel: (id: string) => `${prefix}gifts/${id}/cancel`,
  markAsUsedByUuid: (uuid: string) => `${prefix}gifts/${uuid}/mark-used`,
  markAsUnusedByUuid: (uuid: string) => `${prefix}gifts/${uuid}/mark-unused`,
  getCardLinkingSession: (uuid: string) => `${prefix}gifts/${uuid}/card-linking-session`,
  invalidateCardLinkingSession: (uuid: string) => `${prefix}gifts/${uuid}/invalidate-card-linking-session`,
  reactivateCard: (uuid: string) => `${prefix}gifts/${uuid}/card-reactivate`,
  fulfilled: (uuid: string) => `${prefix}gifts/${uuid}/fulfilled`,
  stalled: (uuid: string) => `${prefix}gifts/${uuid}/stalled`,
};

const wallet = {
  getBy: () => `${prefix}wallets`,
  redeem: (id: string) => `${prefix}wallets/customer/${id}/redeem`,
  getByCustomerId: (id: string) => `${prefix}wallets/customer/${id}`,
  getSupporters: (customerId: string, giftTypeId?: string) =>
    giftTypeId
      ? `${prefix}wallets/customer/${customerId}/supporters?giftTypeId=${giftTypeId}`
      : `${prefix}wallets/customer/${customerId}/supporters`,
  updateByCustomerId: (id: string) => `${prefix}wallets/customer/${id}`,
  activateByCustomerId: (id: string) => `${prefix}wallets/customer/${id}/unarchive`,
  deactivateByCustomerId: (id: string, markAsUsed: boolean) =>
    `${prefix}wallets/customer/${id}/archive?markAsUsed=${markAsUsed}`,
  createOrderGifts: (orderId: string) => `${prefix}create-order-gifts?orderId=${orderId}`,
  placeOrder: () => `${prefix}place-order`,
  getRemainingRedemptionBalance: (id: string) => `${prefix}wallets/customer/${id}/remaining-redemption-balance`,
  mergeGiftsForCardLinking: (id: string) => `${prefix}wallets/customer/${id}/merge-gifts-for-card-linking`,
  markGiftsAsBeingActivated: (id: string) => `${prefix}wallets/customer/${id}/post-process-card-link`,
  startCardActivateAttempt: (id: string) => `${prefix}wallets/customer/${id}/start-card-activate-attempt`,
  getPendingCardActivationStatus: (id: string) => `${prefix}wallets/customer/${id}/get-pending-card-activation-status`,
};

export const giftWalletEndPoints = {
  gift,
  wallet,
  giftNote,
};
