import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import type { INotification } from './Notifications';

export interface INotificationsContext {
  notifications?: INotification[];
  nextNotificationId?: number;
  add?(item: INotification): void;
  remove?(notificationId: string): void;
  removeAll?(): void;
}

export const walletSessionStore: StateCreator<INotificationsContext, [], [], INotificationsContext> = set => ({
  notifications: [],
  nextNotificationId: 0,
  add: (item: INotification) =>
    set(state => {
      item.notificationId = item.key || state.nextNotificationId.toString();
      return {
        notifications: state.notifications.concat([item]),
        nextNotificationId: state.nextNotificationId + 1,
      };
    }),
  remove: (notificationId: string) =>
    set(state => {
      return {
        notifications: state.notifications.filter(entry => entry.notificationId !== notificationId),
      };
    }),
  removeAll: () =>
    set(() => {
      return {
        notifications: [],
      };
    }),
});

export const useNotificationsContext = create<INotificationsContext>()(walletSessionStore);
