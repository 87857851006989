import type { AxiosError } from 'axios';
import axios, { HttpStatusCode } from 'axios';
import { useWalletSessionStore } from '../../../store/WalletSessionstore';
import { useUserStore } from '../../../store/UserStore';
import { useWalletRedemptionStore } from '../../../store/WalletRedemptionStore';
import type { IWalletTokenExtendResponse } from '@gik/platform-management-api-js';
import { UnauthorizedError } from '@gik/strapi';

let isTokenRefreshing = false;
let refreshTokenPromise: Promise<void> | null = null;

export async function walletTokenResponseInterceptor(error: AxiosError) {
  const setAccessToken = useWalletSessionStore.getState().setAccessToken;

  const refreshToken = useUserStore?.getState().refreshToken;
  const walletAccessToken = useWalletSessionStore?.getState().accessToken;
  const customerId = useWalletRedemptionStore?.getState().customerId;

  if ((error.request.status === 401 || error.request.status === 403) && walletAccessToken) {
    if (!isTokenRefreshing) {
      isTokenRefreshing = true;
      const dotnetClient = axios.create({
        baseURL: process.env.DOTNET_API_URL,
        httpsAgent: true,
        withCredentials: false,
      });

      refreshTokenPromise = dotnetClient
        .post<IWalletTokenExtendResponse>('/authentication/wallet-session-extend', {
          refreshToken,
          walletAccessToken,
          customerId,
        })
        .then(response => {
          if (response.status === HttpStatusCode.Ok) {
            setAccessToken(response.data.accessToken);

            setTimeout(() => {
              const event = new Event('wallet-access-token-refreshed');
              document.body.dispatchEvent(event);
            }, 50);
          } else {
            throw new UnauthorizedError('Failed to verify token');
          }
        })
        .catch(() => {
          setAccessToken(undefined);

          setTimeout(() => {
            const event = new Event('wallet-access-token-refreshed');
            document.body.dispatchEvent(event);
          }, 50);
        })
        .finally(() => {
          isTokenRefreshing = false;
          refreshTokenPromise = null;
        });
    }

    await refreshTokenPromise;
  }

  return Promise.reject(error);
}
