import { admin_dateDisplayFormatFull } from '@gik/l10n';
import moment from 'moment-timezone';
import numeral from 'numeral';
import React from 'react';
import DateTimeUtils, { dateFormat, dateTimeFormat, timeFormat } from './DateTimeUtils';

// TODO: the currency sign should be dynamic based on user settings.
const currencySign = '$';
// TODO: the number format should be dynamic based on user settings.
const numberFormat = '0,0';
// // TODO: the decimal number format should be dynamic based on user settings.
const numberFormatDecimal = '0,0.00';

const defaultTimeZone = 'America/Los_Angeles';

export interface FormatCurrencyReactOptions {
  /**
   * Render the signal in a <sup> tag
   */
  sup?: boolean;

  decimals?: boolean;
}

export interface FormatCurrencyOptions {
  decimals?: boolean;
}

/**
 * Format a number or string to be displayed as a currency.
 */
export function formatCurrencyReact(
  value: string | number,
  options: FormatCurrencyReactOptions = { decimals: true }
): React.ReactNode {
  let currency = <span>{currencySign}</span>;
  if (options.sup) currency = <sup>{currencySign}</sup>;
  return (
    <>
      {currency}
      {options.decimals ? formatNumberDecimal(value) : formatNumber(value)}
    </>
  );
}

/**
 * Format a number or string to be displayed as a currency.
 */
export function formatCurrency(value: string | number): string {
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  const hasDecimals = numericValue % 1 !== 0;

  const amount = hasDecimals ? formatNumberDecimal(numericValue) : formatNumber(numericValue);

  return currencySign + amount;
}

/**
 * Format a number or string to be displayed as a number.
 */
export function formatNumber(value: string | number): string {
  return numeral(value).format(numberFormat);
}

/**
 * Format a number or string to be displayed as a number.
 */
export function formatNumberDecimal(value: string | number): string {
  return numeral(value).format(numberFormatDecimal);
}

/**
 * Format a date string to display as a date.
 */
export function formatDate(value: string | number | Date): string {
  return moment(value, DateTimeUtils.DateFormat).format(dateFormat);
}
/**
 * Format a date string to display as a date with time.
 */
export function formatDateTime(value: string | number | Date): string {
  return moment(value, DateTimeUtils.DateFormat).format(dateTimeFormat);
}

export function adminFormatDateTimeTZ(value: string | number | Date, timeZone = defaultTimeZone): string {
  return moment.utc(value).tz(timeZone).format(admin_dateDisplayFormatFull);
}
/**
 * Format a date string to display as a time.
 */
export function formatTime(value: string | number | Date): string {
  return moment(value, DateTimeUtils.DateFormat).format(timeFormat);
}

export function formatRelativeTime(value: string | number | Date): string {
  return moment(value).fromNow();
}
