import type { FormSchemaEntry } from '@gik/ui/Form';

export type EmailAddressFieldProps = {
  readOnly: boolean;
  labelPrefix?: string;
};

export const EmailAddressField = (props?: EmailAddressFieldProps): FormSchemaEntry => ({
  label: (props?.labelPrefix ?? '') + 'Email address',
  name: 'email',
  type: props?.readOnly ? 'plaintext' : 'email',
  required: true,
  minLength: 2,
  maxLength: 255,
  props: {
    centerVertical: props?.readOnly,
  },
});
