import { Analytics } from '@gik/analytics';
import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { gikClassPrefix, timeoutDefaultValue } from '@gik/core/constants';
import { useAppStore } from '@gik/core/store/AppStore';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import type { ValidationError, ValidationErrors, ValidationOptions } from '@gik/ui/Form/validation';
import { validateForm } from '@gik/ui/Form/validation';
import { UI } from '@gik/ui/UIManager';
import classnames from 'classnames';
import type { FormikProps, FormikTouched } from 'formik';
import { Formik } from 'formik';
import type { FormikConfig, FormikErrors, FormikValues } from 'formik/dist/types';
import React from 'react';
import type { FieldInputProps } from 'react-final-form';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist as zustandPersist } from 'zustand/middleware';
import type { UISize } from '../types';
import type { FormVariant } from '../typesValues';
import { FormContext } from './FormContext';
import { translationKeys } from './i18n/en';
import type { FormSchemaEntry } from './index';
import { FormField } from './index';

// Redeclare forwardRef
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type FormError = {
  message: string;
  type: 'required' | 'singleRequired' | 'custom';
};

export type FormErrors<ValuesType extends FormikValues = object> = {
  [K in keyof ValuesType]?: FormError;
};

type CustomFormConfig<ValuesType extends FormikValues = object> = Omit<
  FormikConfig<ValuesType>,
  'onSubmit' | 'validate' | 'initialValues'
> &
  Partial<Pick<FormikConfig<ValuesType>, 'initialValues'>>;

export type FormApi = {
  getState(): {
    dirty: boolean;
    change: (name: string) => void;
    resetFieldState: (name: string) => void;
    reset: (name: string) => void;
  };
};

export type FormRef<ValuesType extends FormikValues = object> = FormikProps<ValuesType> & {
  id: string;
  formEl: HTMLFormElement;
  subscribe: <T extends object = object>(key: string, callback: (value: T) => void) => () => void;
};

export interface FormProps<ValuesType extends FormikValues = object> extends CustomFormConfig<ValuesType> {
  id?: string;
  className?: string;

  /**
   * A form schema that specifies what form fields to render
   */
  schema?: FormSchemaEntry[];

  /**
   * A render function can be provided that will render the form fields.
   * If this function is used then the form will not be auto generated from the schema.
   */
  render?: (props?: FormikProps<ValuesType>) => React.ReactNode;

  // TODO: add a cancelButton boolean (same as resetButton and submitButton)

  /**
   * If true a generic reset button will be rendered at the bottom of the form
   */
  resetButton?: boolean;
  resetButtonText?: string;
  /**
   * If true a generic submit button will be rendered at the bottom of the form
   */
  submitButton?: boolean;
  submitButtonText?: string;

  /**
   * This property is passed to the FormGroup component to display a different layout
   */
  vertical?: boolean;

  /**
   * This property is passed to the form component to disable them
   */
  disabled?: boolean;

  /**
   * If true the form values will be persisted in localstorage on every change
   * so the form can be restored after a refresh.
   * NOTE: when set to true the `id` property is required
   */
  persist?: boolean;

  /**
   * A custom renderField function can be provided for edge cases
   */
  renderField?(
    entry: FormSchemaEntry,
    props: FieldInputProps<unknown, HTMLElement>
    // fieldState: FieldState<unknown>
  ): React.ReactElement;

  /**
   * Callback function that will get triggered when the form is successfully submitted
   */
  // TODO: fix typing
  onSubmit?: (values: object, form: unknown) => void | Promise<void>;

  /**
   * Callback function that will get triggered when a from value changes
   */
  onChange?: (values: object) => void | Promise<void>;
  onSubmitAttempt?: (values: object) => void | Promise<void>;
  onSubmitField?: (entry: FormSchemaEntry, value: string) => Promise<void | Response>;
  onSubmitFieldFail?: (entry: FormSchemaEntry, response: Response) => void;

  validate?: (
    values: ValuesType,
    options: ValidationOptions<ValuesType>
  ) => ValidationErrors<ValuesType> | Promise<ValidationErrors<ValuesType>>;

  /**
   * Called when the form is submitted with one or more errors
   */
  onValidationFail?();

  /**
   * Variant to pass on to all control components
   */
  variant?: FormVariant;

  /**
   * Variant to pass on to all  control components
   */
  size?: UISize;

  /**
   * Wether or not to open a notification when a validation error occurs while trying to submit
   */
  validationNotifications?: boolean;

  /**
   * Wether or not to show inline form errors below each field
   */
  inlineErrors?: boolean;

  /**
   * Tracks error messages
   */
  trackingId?: AnalyticsEvents;

  /**
   * Disable scroll to first invalid field behavior.
   */
  disableScrollToFirstError?: boolean;

  /**
   * Restore form values after the app reloads the page as a result of an app update
   */
  restoreAfterUpdate?: boolean;

  // TODO: fix typing
  onReady?: (form: FormikProps<ValuesType>) => void;

  autoComplete?: boolean;

  /**
   * if set, will cause the submit button to react to click events once every X ms delay
   */
  debounce?: number;
  preDebounce?: () => void;
}

export type FormStore = {
  values?: unknown;
  setValues?(values: unknown): void;
};

export interface IFormSubmitAttemptOptions {
  notificationError?: string;
  formRenderProps?: FormikProps<object>;
}

// block name for this component
const blockName = `${gikClassPrefix}-form`;

/**
 * GIK Form
 *
 * This is a wrapper around react-final-form.
 * It hooks up a schema with the validation method and it also
 * provides a context that each field has access to.
 *
 * It can also auto generate the form fields based on the schema.
 */
function FormComp<ValuesType extends FormikValues>(
  {
    schema,
    initialValues = {} as ValuesType,
    onSubmit,
    onSubmitAttempt,
    onSubmitField,
    onSubmitFieldFail,
    onValidationFail,
    onChange,
    onReady,
    render,
    validate,
    vertical,
    disabled,
    resetButton,
    resetButtonText = 'Reset',
    submitButton,
    submitButtonText = 'Submit',
    variant,
    persist,
    size,
    id,
    restoreAfterUpdate,
    validationNotifications = true,
    inlineErrors = true,
    className,
    trackingId,
    disableScrollToFirstError = false,
    autoComplete,
    debounce,
    preDebounce,
    ...otherProps
  }: FormProps<ValuesType>,
  ref: React.MutableRefObject<FormRef<ValuesType>>
): React.ReactElement {
  const [_initialValues, _setInitialValues] = React.useState<ValuesType>(undefined);
  const [values, _setValues] = React.useState(undefined);
  const [ready, setReady] = React.useState(false);
  const [savingField, setSavingField] = React.useState<boolean>(false);
  const [submitCount, setSubmitCount] = React.useState<number>(0);

  let formRef: HTMLFormElement;
  const formRenderProps = React.useRef<FormikProps<ValuesType>>();
  const storeRef = React.useRef<UseStore<FormStore>>();

  const valuesRef = React.useRef(values);
  const setValues = React.useCallback(
    data => {
      valuesRef.current = data;
      // the if statement prevent a state update if the component was unmounted
      if (formRef) {
        _setValues(data);
      }
    },
    [formRef]
  );

  const { subscribe, observer } = useFormEvents<ValuesType>();

  React.useImperativeHandle(ref, () => ({
    id,
    formEl: formRef,
    subscribe,
    ...(formRenderProps.current ?? ({} as FormikProps<ValuesType>)),
  }));

  const handleWindowUnload = () => {
    const appIsUpdating = useAppStore.getState().isUpdating;
    if (restoreAfterUpdate && appIsUpdating) {
      persistDirtyForm();
    }
  };

  const persistDirtyForm = () => {
    // skip if this form wasn't marked as dirty
    if (!formRenderProps.current.dirty) return;

    // store the values in localstorage if this form should be restored after the app is reloaded
    const formStorage = createStorage();
    const state = formStorage.getState();

    state?.setValues(valuesRef.current);
  };

  const createStorage = () => {
    const formStore: StateCreator<FormStore, [], [], FormStore> = set => ({
      values: undefined,
      setValues: (values: unknown) =>
        set(() => {
          return {
            values,
          };
        }),
    });

    return create<FormStore>()(
      zustandPersist(formStore, {
        name: id,
        getStorage: () => localStorage,
      })
    );
  };

  React.useEffect(() => {
    const newInitialValues: ValuesType = initialValues || ({} as ValuesType);

    // NOTE: Removed support for default value when changing to Formik
    // if (schema) {
    //   // assign initial values from the 'default' variable in the schema property
    //   schema.forEach(entry => {
    //     if (newInitialValues[entry.name] === undefined) {
    //       newInitialValues[entry.name as keyof ValuesType] = entry.default;
    //     }
    //   });
    // }

    // create a zustand store to persist form values
    if (persist || restoreAfterUpdate) {
      if (!id) {
        throw new Error(`Form "id" prop is required when "persist" is true`);
      }

      storeRef.current = createStorage();

      // override initialValues from zustand store if any values were persisted
      const formState = storeRef.current?.getState();
      if (formState?.values) {
        Object.keys(formState.values).map((key: string) => {
          const value = formState.values[key];
          if (newInitialValues[key] == undefined && value !== undefined) {
            newInitialValues[key as keyof ValuesType] = value;
          }
        });

        // delete persisted values if this is a one time restore
        if (!persist) {
          formState.setValues({});
          storeRef.current = undefined;
        }
      }
    }

    _setInitialValues(newInitialValues);

    window.addEventListener('unload', handleWindowUnload);

    // handle form unload
    return () => {
      // clear dirty form state
      useAppStore.getState().clearDirtyForm(id);

      window.removeEventListener('unload', handleWindowUnload);
    };
    // FIXME: depends on initialValues but adding initialValues causes an infinite render loop
    // eslint-disable-next-line
  }, []);

  const debounceRef = React.useRef<NodeJS.Timeout>(null);

  const handleOnSubmit = React.useCallback(
    (values: object): void | Promise<void> => {
      // TODO: need to handle this differently with Formik becuase the isSubmitting is already true when this function is called
      // prevent submitting form while it is already being submitted
      // if (formRenderProps.current.isSubmitting) {
      //   return;
      // }

      if (debounce > 0) {
        preDebounce?.();
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
          if (onSubmit) {
            useAppStore.getState().clearDirtyForm(id);
            return onSubmit(values, null);
          }
        }, debounce);
      } else {
        if (onSubmit) {
          useAppStore.getState().clearDirtyForm(id);
          return onSubmit(values, null);
        }
      }
    },
    [debounce, id, onSubmit, preDebounce]
  );

  const handleReset = React.useCallback(
    (form: FormikProps<ValuesType>): void => {
      // hard reset the form to clear validation errors
      // if (schema) {
      //   schema.forEach(entry => {
      //     form.change(entry.name, undefined);
      //     form.resetFieldState(entry.name);
      //   });
      // }

      // reset react final form to initial values
      form.resetForm();
      useAppStore.getState().clearDirtyForm(id);
    },
    [id]
  );

  const syncDirtyState = React.useCallback(() => {
    if (!id) return;
    // keep track of this form when it's dirty at the app level
    if (formRenderProps.current.dirty) {
      useAppStore.getState().setDirtyForm(id);
    } else {
      useAppStore.getState().clearDirtyForm(id);
    }
  }, [id]);

  const handleChange = React.useCallback(
    (values: ValuesType) => {
      let state: FormStore;
      if (storeRef) {
        state = storeRef.current?.getState();
        state?.setValues(values);
      }

      if (restoreAfterUpdate) setTimeout(syncDirtyState, timeoutDefaultValue);

      onChange?.(values);

      setValues(values);
    },
    [onChange, setValues, syncDirtyState, restoreAfterUpdate]
  );

  const handleSubmitField = React.useCallback(
    async entry => {
      setSavingField(entry);
      const response = await onSubmitField?.(entry, valuesRef.current[entry.name]);
      if (response && response.ok) UI.notifySuccess(`${getNameFromEntry(entry)} has been saved`);
      setSavingField(undefined);
      return response;
    },
    [onSubmitField]
  );

  const handleSubmitFieldFail = React.useCallback(
    (entry, res) => {
      UI.notifyError(`Failed to save ${getNameFromEntry(entry)}`);
      onSubmitFieldFail?.(entry, res);
    },
    [onSubmitFieldFail]
  );

  const getValidateFormResults = React.useCallback(
    async values => {
      const opts = {
        formEl: formRef,
        formRenderProps: formRenderProps.current,
      };

      return validate ? await validate(values, opts) : await validateForm(schema, values, opts);
    },
    [formRef, schema, validate]
  );

  React.useEffect(() => {
    if (!ready && formRenderProps.current) {
      setReady(true);
      onReady?.(formRenderProps.current);
    }
  }, [formRenderProps, onReady, ready]);

  const showErrorNotification = React.useCallback(
    async (errors, options?: IFormSubmitAttemptOptions) => {
      // trigger form validation to see if there are any errors at this point
      // Note: Use valuesRef as handleSubmitAttempt can be called from outside of this component (different context)

      const errorKeys = Object.keys(errors);
      if (errorKeys.length) {
        let errorNames = '';
        let singleError: ValidationError;
        const matchingErrors: ValidationError[] = [];
        errorKeys.forEach((key, index) => {
          let separatorSymbol = '';
          if (index == errorKeys.length - 1 && matchingErrors.length > 0) {
            separatorSymbol = ' and ';
          } else if (index > 0) {
            separatorSymbol = ', ';
          }

          const validationError = errors[key] as ValidationError;
          const schemaEntry = schema?.find(entry => entry.name === key);

          // fire analytics events
          if (trackingId) {
            try {
              Analytics.fireEvent(
                trackingId,
                {
                  ValidationError: `${schemaEntry?.name || key}:${
                    schemaEntry?.type === 'password' ? '*** sensitive info removed ***' : values?.[key]
                  }:${errors?.[key].message}`,
                },
                keys => keys
              );
            } catch (e) {
              logger.error(e, { trackingId, schemaEntry, values, errors, key });
            }
          }

          if (schemaEntry) {
            errorNames += `${separatorSymbol}${getNameFromEntry(schemaEntry)}`;

            // only add fields that failed to pass the 'required' validation
            if (validationError.type === 'required') {
              // add field name from the schema
              matchingErrors.push(validationError);
            }
            if (validationError.type === 'singleRequired') {
              // add field name from the schema
              singleError = validationError;
            }
          }

          // focus on the first entry
          const firstKey = errorKeys[0];
          const firstErrorEl = document.getElementsByClassName(`gik-form-group--${firstKey}`)[0] as HTMLDivElement;
          if (firstErrorEl) {
            const inputEl = firstErrorEl.getElementsByClassName('gik-input__input')[0] as HTMLInputElement;
            setTimeout(() => inputEl?.focus(), timeoutDefaultValue);
          }
        });

        onValidationFail?.();

        // just fire a custom notification if one was supplied
        if (validationNotifications) {
          if (options?.notificationError) {
            UI.notifyError(options.notificationError);
          } else {
            if (singleError) {
              UI.notifyError(i18n.t(singleError.message).toString());
            } else {
              if (matchingErrors.length > 10 || matchingErrors.length === 0) {
                UI.notifyError(
                  i18n
                    .t(
                      matchingErrors.some(error => error.type === 'required' || error.type === 'singleRequired')
                        ? translationKeys.genericFormErrorFieldRequired
                        : translationKeys.genericFormErrorFieldError
                    )
                    .toString()
                );
              } else {
                if (matchingErrors.length === 1) {
                  UI.notifyError(`The ${errorNames} field is required.`);
                } else {
                  UI.notifyError(`The ${errorNames} fields are required.`);
                }
              }
            }
          }
        }
      }
    },
    [onValidationFail, schema, trackingId, validationNotifications, values]
  );

  const handleValidateForm = React.useCallback(
    async (values: ValuesType) => {
      handleChange(values);

      const results = await getValidateFormResults(values);

      const formikResults: FormikErrors<ValuesType> = {};
      Object.keys(results).forEach((key: keyof ValuesType) => {
        // @ts-ignore
        formikResults[key] = results[key];
      });

      // if we are submitting, mark all fields with errors as touched so they all show errors on the UI
      if (formRenderProps.current?.submitCount > submitCount) {
        const touched: FormikTouched<ValuesType> = {};
        Object.keys(formikResults).forEach((key: keyof ValuesType) => {
          // @ts-ignore
          touched[key] = true;
        });
        await formRenderProps.current?.setTouched(touched);
        setSubmitCount(formRenderProps.current?.submitCount);

        // show an error notification if this was
        await showErrorNotification(results);
      }

      return formikResults;
    },
    [getValidateFormResults, handleChange, showErrorNotification, submitCount]
  );

  function getNameFromEntry(entry: FormSchemaEntry) {
    return entry.errorName || entry.label || entry.placeholder || entry.name;
  }

  // wait until initialValues is ready
  if (!_initialValues) {
    return null;
  }

  const blockClasses = classnames([
    blockName,
    `${blockName}--generated`,
    { [`${blockName}--vertical`]: vertical },
    { [`variant-${variant}`]: variant },
    className || '',
  ]);

  return (
    <Formik<ValuesType>
      {...otherProps}
      initialValues={initialValues}
      validate={handleValidateForm}
      validateOnBlur={false}
      validateOnMount={true}
      onSubmit={handleOnSubmit}
    >
      {props => {
        observer(props);
        formRenderProps.current = props;

        const context = {
          form: props,
          // useFormStore,
          schema,
          vertical,
          // formProps: props,
          // initialValues,
        };

        if (props.submitCount > submitCount) {
          setSubmitCount(props.submitCount);
        }

        return (
          <FormContext.Provider value={context}>
            <FormContext.Consumer>
              {context => {
                return (
                  <form
                    ref={(_ref: HTMLFormElement) => {
                      formRef = _ref;
                      return ref;
                    }}
                    onSubmit={props.handleSubmit}
                    id={id}
                    className={blockClasses}
                    noValidate
                    autoComplete={autoComplete ? 'on' : undefined}
                  >
                    {!render &&
                      schema?.map((entry: FormSchemaEntry, index: number) => (
                        <FormField
                          context={context}
                          {...entry}
                          key={index}
                          isSaving={savingField}
                          onSubmit={() => handleSubmitField(entry)}
                          onSubmitFail={res => handleSubmitFieldFail(entry, res)}
                        />
                      ))}

                    {render?.(props)}

                    <FormActions
                      resetButton={resetButton}
                      submitButton={submitButton}
                      handleReset={() => handleReset(props)}
                      disabled={disabled}
                      submitting={props.isSubmitting}
                      pristine={!props.dirty}
                      resetButtonText={resetButtonText}
                      submitButtonText={submitButtonText}
                    />
                  </form>
                );
              }}
            </FormContext.Consumer>
          </FormContext.Provider>
        );
      }}
    </Formik>
  );
}

const FormWithRef = React.forwardRef(FormComp);
export const Form = FormWithRef;

type IFormActionsProps = {
  resetButton: boolean;
  submitButton: boolean;
  handleReset: () => void;
  disabled: boolean;
  submitting?: boolean;
  pristine: boolean;
  resetButtonText: string;
  submitButtonText: string;
};
export function FormActions({
  resetButton,
  submitButton,
  handleReset,
  disabled,
  submitting,
  pristine,
  resetButtonText,
  submitButtonText,
}: IFormActionsProps) {
  if (!(resetButton || submitButton)) return null;

  return (
    <div className="gik-form__actions">
      {resetButton && (
        <Button type="reset" onClick={() => handleReset()} disabled={disabled || submitting || pristine}>
          {resetButtonText}
        </Button>
      )}
      {submitButton && (
        <Button type="submit" loading={submitting} disabled={disabled || submitting}>
          {submitButtonText}
        </Button>
      )}
    </div>
  );
}

type Subscription = Record<string, ((value: object) => void)[]>;

function transverseObject(key: string, obj: object) {
  try {
    return key.split('.').reduce((o, i) => o[i], obj);
  } catch (e) {
    return undefined;
  }
}

function useFormEvents<ValuesType extends FormikValues>() {
  const lastValues = React.useRef<FormikProps<ValuesType>>(null);
  const subscribers = React.useRef<Subscription>(null);

  const subscribe = React.useCallback(function subscribe<T extends object = object>(
    key: string,
    callback: (value: T) => void
  ): () => void {
    if (!key || !callback) throw new Error('subscribe() requires a key and a callback');

    if (!subscribers.current) {
      subscribers.current = {} as Subscription;
    }

    if (!subscribers.current[key]) {
      subscribers.current[key] = [];
    }

    if (subscribers.current[key].indexOf(callback) > -1) {
      return () => void 0;
    }

    subscribers.current[key].push(callback);
    callback(transverseObject(key, lastValues.current));

    return function unsubscribe() {
      const index = subscribers.current[key].indexOf(callback);
      if (index > -1) {
        subscribers.current[key].splice(index, 1);

        if (subscribers.current[key].length === 0) {
          delete subscribers.current[key];
        }
      }
    };
  }, []);

  const observer = React.useCallback(function observer(form: FormikProps<ValuesType>) {
    if (form && lastValues.current && subscribers.current) {
      for (const key in subscribers.current) {
        const lastValue = transverseObject(key, lastValues.current);
        const currentValue = transverseObject(key, form);

        if (lastValue !== currentValue) {
          subscribers.current[key]?.forEach(callback => callback(currentValue));
        }
      }
    }

    lastValues.current = form;
  }, []);

  return {
    subscribe,
    observer,
  };
}
