import type { MatchConditions, SubjectRawRule } from '@casl/ability';
import jwt_decode from 'jwt-decode';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type {
  AdminPermissionAction,
  AdminPermissionCriteriaType,
  AdminPermissionSubject,
  UserPermissionAction,
  UserPermissionCriteriaType,
  UserPermissionSubject,
} from './permissions';

export type AdminRawRule = SubjectRawRule<
  AdminPermissionAction,
  AdminPermissionSubject,
  MatchConditions<AdminPermissionCriteriaType>
>;
export type UserRawRule = SubjectRawRule<
  UserPermissionAction,
  UserPermissionSubject,
  MatchConditions<UserPermissionCriteriaType>
>;

export interface IUserStore {
  readonly id?: string;
  readonly accessToken?: string;
  readonly refreshToken?: string;
  readonly refreshTokenHash?: string;
  readonly permissions?: UserRawRule[];
  readonly adminPermissions?: AdminRawRule[];
  readonly lastTokenRefresh: number;
  readonly hasUserPreviouslyLoggedIn: boolean;
  readonly isImpersonating: boolean;
  readonly shouldLogout: boolean;
  readonly shouldCloseModalsAfterNavigation: boolean;
  setShouldCloseModalsAfterNavigation(shouldCloseModalsAfterNavigation: boolean): void;
  readonly shouldUnblurAfterNavigation: boolean;
  setShouldUnblurAfterNavigation(shouldUnblurAfterNavigation: boolean): void;
  // readonly ability: PureAbility;
  // readonly userAbility: PureAbility;
  login(refreshToken: string, refreshTokenHash: string): void;
  setAccessToken(accessToken: string): void;
  setIsImpersonating(isImpersonating: boolean): void;
  setShouldLogout(shouldLogout: boolean): void;
  logout(): void;
  // setAbility(ability: PureAbility): void;
  // setUserAbility(ability: PureAbility): void;
}

function decodeRefreshToken(refreshToken: string): Partial<IUserStore> {
  const decodedRefreshToken = refreshToken && jwt_decode<{ initialAccessToken: string }>(refreshToken);
  const decodedInitialAccessToken =
    decodedRefreshToken &&
    jwt_decode<{
      userId: string;
      permissions: UserRawRule[];
      adminPermissions: AdminRawRule[];
    }>(decodedRefreshToken.initialAccessToken);

  return {
    refreshToken,
    id: decodedInitialAccessToken?.userId,
    accessToken: decodedRefreshToken?.initialAccessToken,
    permissions: decodedInitialAccessToken?.permissions,
    adminPermissions: decodedInitialAccessToken?.adminPermissions,
  };
}

export const userSessionStore: StateCreator<IUserStore, [], [], IUserStore> = set => ({
  lastTokenRefresh: 0,
  hasUserPreviouslyLoggedIn: false,
  isImpersonating: false,
  shouldLogout: false,
  shouldCloseModalsAfterNavigation: true,
  // ability: undefined,
  // userAbility: undefined,
  shouldUnblurAfterNavigation: true,
  login: (refreshToken: string, refreshTokenHash: string) => {
    set(state => {
      return {
        ...state,
        ...decodeRefreshToken(refreshToken),
        lastTokenRefresh: Date.now(),
        hasUserPreviouslyLoggedIn: true,
        refreshTokenHash,
      };
    });
  },
  setAccessToken: (accessToken: string) =>
    set(() => {
      return {
        accessToken,
        lastTokenRefresh: Date.now(),
      };
    }),
  logout: () => {
    set(state => {
      return {
        ...state,
        isImpersonating: false,
        refreshTokenHash: undefined,
        ...decodeRefreshToken(undefined),
      };
    });
  },
  setIsImpersonating: (isImpersonating: boolean) => {
    set(() => {
      return {
        isImpersonating,
      };
    });
  },
  setShouldLogout: (shouldLogout: boolean) => {
    set(() => {
      return {
        shouldLogout,
      };
    });
  },
  setShouldCloseModalsAfterNavigation: (shouldCloseModalsAfterNavigation: boolean) => {
    set(() => {
      return {
        shouldCloseModalsAfterNavigation,
      };
    });
  },
  setShouldUnblurAfterNavigation: (shouldUnblurAfterNavigation: boolean) => {
    set(() => {
      return {
        shouldUnblurAfterNavigation,
      };
    });
  },
  // setAbility: (ability: PureAbility) => {
  //   set(() => {
  //     return {
  //       ability,
  //     };
  //   });
  // },
  // setUserAbility: (userAbility: PureAbility) => {
  //   set(() => {
  //     return {
  //       userAbility,
  //     };
  //   });
  // },
});

export const useUserStore = create<IUserStore>()(
  persist(userSessionStore, {
    name: 'user-v2',
    // blacklist: ['ability', 'userAbility'],
    getStorage: () => localStorage,
  })
);
