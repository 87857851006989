import type { ShippingDetails } from '@gik/checkout/types';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { FormProps } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import type { FormApi, SubmissionErrors } from 'final-form';
import React from 'react';
import { ShippingFormSchema } from './DigitalShippingFormSchema';
import { useBemCN } from '@gik/core/utils/bemBlock';

export interface DigitalShippingFormProps extends FormProps {
  onBeforeSubmit?: (
    values: object,
    form: FormApi<object, object>
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;
  inkindRouteId?: string;
  buttons?: (isFormValid: boolean) => React.ReactNode;
  buttonsPortal?: HTMLElement;
  recipient?: boolean;
}

function DigitalShippingFormComp({
  initialValues,
  onSubmit,
  onBeforeSubmit,
  inkindRouteId,
  buttons,
  buttonsPortal,
  recipient,
  ...otherProps
}: DigitalShippingFormProps): React.ReactElement {
  const bem = useBemCN('shipping-form');

  const handleSubmit = React.useCallback(
    (values: ShippingDetails, form: FormApi<object, object>): void => {
      if (onBeforeSubmit) onBeforeSubmit(values, form);
      if (onSubmit) onSubmit(values, form);
    },
    [onBeforeSubmit, onSubmit]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      schema={ShippingFormSchema({
        ref: undefined,
        lastNameRequired: true,
        emailReadOnly: !!inkindRouteId,
        nameReadOnly: !!inkindRouteId,
        recipient,
      })}
      initialValues={initialValues}
      vertical
      validateOnBlur={false}
      // restoreAfterUpdate
      {...otherProps}
      render={renderProps => {
        return (
          <>
            <section {...bem('first-name-last-name-section')}>
              <FormField name="firstName" className="tw-flex-1" />
              <FormField name="lastName" className="tw-flex-1" />
            </section>
            <FormField name="email" />

            {renderPortal?.(buttons && buttons?.(renderProps.isValid), () => buttonsPortal)}
          </>
        );
      }}
    />
  );
}

export const DigitalShippingForm = withComponentErrorBoundary(DigitalShippingFormComp);
