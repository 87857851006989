import type { StatusCodeHandlerProps } from '@/components/StatusCodeHandler';
import { withDevTools } from '@/components/withDevTools';
import { useNavigationLogger } from '@/utils/navigateTo';
import { useAnalytics } from '@gik/analytics/hooks/useAnalytics';
import { useImpactRadius } from '@gik/analytics/utils/ImpactRadius';
import { logger } from '@gik/analytics/utils/logger';
import { BaseHeadTags } from '@gik/core/components/BaseHeadTags/BaseHeadTags';
import type { ErrorPageProps } from '@gik/core/pages/Error/ErrorPage';
import i18n from '@gik/i18n';
import { UIWrapper } from '@gik/ui/UIManager/UIWrapper';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import 'rc-slider/assets/index.css';
import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { I18nextProvider } from 'react-i18next';
import 'regenerator-runtime/runtime';
import 'spinkit/spinkit.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import '../styles/0tailwind.scss';
import '../styles/index.scss';
import { PlatformManagementClientProvider } from '@gik/platform-management-api-react';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { walletTokenResponseInterceptor } from '@gik/core/api/axios/interceptors/walletTokenResponseInterceptor';
import { useAppSessionStore } from '@gik/core/store/AppSessionStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { useAppLocalStore } from '@gik/core/store/AppLocalStore';
import { ReactQueryClientProvider } from '@/ReactQueryClientProvider';
import { VercelSpeedInsights } from '@/components/VercelSpeedInsights';
import { useIntersectObserverPolyfill } from '@gik/core/hooks/useIntersectObserverPolyfill';

if (process.env.NODE_ENV === 'production') {
  // polyfills
  require('intersection-observer');
}

// Development React warnings in the console are really loud and it's hard to
// see the actual logs you care about.
//
// This patches `console.error` to detect and reformat the logs.
//
// This should not show up in production builds.
if (process.env.NODE_ENV === 'development') {
  const consoleError = console.error;

  // Add a hidden filter in the output so that we can easily filter out the log
  // messages with the negative "-ReactDOMWarning" filter.
  const hiddenFilter = 'ReactDOMWarning';
  const hiddenStyles = 'color: transparent; font-size: 0px; user-select: none';

  console.error = (...args: Parameters<typeof consoleError>) => {
    // Fallback to normal console error unless this error came from react-dom.
    const trace = new Error().stack;
    if (!trace || !trace.includes('react-dom.development.js')) {
      return consoleError(...args);
    }

    // All react-dom warnings start with "Warning:"
    const firstArg = args[0];
    if (typeof firstArg !== 'string' || !firstArg.startsWith('Warning:')) {
      return consoleError(...args);
    }

    // If we get here, we're reasonably sure that it's a ReactDOM warning.
    const template = args.shift()?.replace(/%s$/, '');
    const stacktrace = args.pop();

    // eslint-disable-next-line no-console
    console.groupCollapsed(
      `%c⚠️ ${template}%c${hiddenFilter}`,
      'color: orange; font-weight: normal',
      ...args,
      hiddenStyles
    );
    // eslint-disable-next-line no-console
    console.log(
      `Tip: Add %c-${hiddenFilter}%c to the log Filter box to silence these ReactDOM error groups%c%{hiddenFilter}`,
      'font-weight: bold',
      'font-weight: normal',
      hiddenStyles
    );
    consoleError(`%s%c${hiddenFilter}`, stacktrace, hiddenStyles);
    // eslint-disable-next-line no-console
    console.groupEnd();
  };
}

const ErrorPage = dynamic<ErrorPageProps>(() => import('@gik/core/pages/Error/ErrorPage').then(mod => mod.ErrorPage));

const StatusCodeHandler = dynamic(() => import('@/components/StatusCodeHandler').then(mod => mod.StatusCodeHandler), {
  ssr: true,
});

const NProgressWrapper = dynamic(() => import('@/components/NProgressWrapper').then(mod => mod.NProgressWrapper), {
  ssr: false,
});

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement, layoutProps) => React.ReactNode;
};

type AppPropsWithLayout = AppProps<React.PropsWithChildren<StatusCodeHandlerProps>> & {
  Component: NextPageWithLayout;
};

const MyApp: React.FC<AppPropsWithLayout> = ({ Component, pageProps, router }: AppPropsWithLayout) => {
  const comp = useComponentLayout(Component, pageProps, router);

  useImpactRadius();
  useIntersectObserverPolyfill();
  useNavigationLogger();
  useAnalytics();

  return (
    <AppErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <ReactQueryClientProvider>
          <PlatformManagementClientProvider
            baseUrl={useEnvStore.getState().PLATFORM_API_BASE_URL}
            apiKey={useEnvStore.getState().PLATFORM_API_KEY}
            options={{
              headers: {
                ['x-app-id']: `inkind-nextjs`,
              },
            }}
            clientOptions={{
              dotnetBaseUrl: process.env.DOTNET_API_URL,
              responseInterceptors: [walletTokenResponseInterceptor],
              appRequestHeadersInterceptor: {
                useAppSessionStore,
                useUserStore,
                useAppLocalStore,
              },
            }}
          >
            <BaseHeadTags />
            <NProgressWrapper />
            <VercelSpeedInsights />
            <UIWrapper />
            {comp}
          </PlatformManagementClientProvider>
        </ReactQueryClientProvider>
      </I18nextProvider>
    </AppErrorBoundary>
  );
};

export default MyApp;

class AppErrorBoundary extends React.Component<React.PropsWithChildren<{}>, { hasError: boolean; error: unknown }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logger.error('App error boundary: ', { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}

function useComponentLayout(
  Component: AppPropsWithLayout['Component'],
  pageProps: AppPropsWithLayout['pageProps'],
  router: AppPropsWithLayout['router']
) {
  const url = `${process.env.BASE_URL}${router.asPath}`;

  // Use the layout defined at the page level, if available
  const getLayout = React.useMemo(() => Component.getLayout ?? (page => page), [Component.getLayout]);

  return React.useMemo(
    () =>
      pageProps.statusCode && pageProps.statusCode >= 400 ? (
        <StatusCodeHandler {...pageProps} />
      ) : (
        getLayout(withDevTools(<Component {...pageProps} />), { key: url })
      ),
    [Component, getLayout, pageProps, url]
  );
}
