import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Button } from '../Button';
import { Accordion, AccordionItem } from '../Accordion';
import { FormGroup } from '../Form';
import type { SvgIconProps } from '../SvgIcon';
import { SvgIcon } from '../SvgIcon';
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/solid/ChevronUpIcon';

export const ExpandableButton = withComponentErrorBoundary(ExpandableButtonComp);

export type IExpandableButtonProps = UIComponent & {
  title: string;
  Icon: Pick<SvgIconProps, 'Icon'>['Icon'];
  onExpand?: () => void;
};

function ExpandableButtonComp({ className, title, Icon, onExpand, children, ...otherProps }: IExpandableButtonProps) {
  const bem = useBemCN('expandable-button');
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleExpand = React.useCallback(() => {
    setExpanded(v => !v);
    onExpand?.();
  }, [onExpand]);

  return (
    <div {...otherProps} {...bem(null, null, className)}>
      <div {...bem('button-wrapper')}>
        <Button
          fullWidth
          prepend={<SvgIcon Icon={Icon} />}
          append={<SvgIcon Icon={expanded ? ChevronUpIcon : ChevronDownIcon} />}
          onClick={handleExpand}
        >
          {title}
        </Button>
      </div>
      <Accordion allToggled={expanded}>
        <AccordionItem>
          <FormGroup highlight>{children}</FormGroup>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
