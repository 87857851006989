import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import type { CartItem } from '../types';

export type CheckoutStoreProps = {
  cart: CartItem[];
  setCart(cart: CartItem[]): void;
  setOrderTotal(orderTotal: number): void;
  setOrderTip(orderTip: number): void;
  orderTotal?: number;
  orderTip?: number;
};

const initialState = {
  cart: [],
};

export const checkoutStore: StateCreator<CheckoutStoreProps, [], [], CheckoutStoreProps> = set => ({
  ...initialState,
  setCart: (cart: CartItem[]) =>
    set(() => {
      return {
        cart,
      };
    }),
  setOrderTotal: (orderTotal: number) => set(() => ({ orderTotal })),
  setOrderTip: (orderTip: number) => set(() => ({ orderTip })),
});

export const useCheckoutStore = create<CheckoutStoreProps>()(checkoutStore);
