import { useUserOwnedOrOrganizingPagesInfinite } from '@gik/api/inkinds/userPages';
import { useWishlistProducts } from '@gik/api/inkinds/wishlistProducts';
import type { APIWithPagination, OptimisticUpdate } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import type { KeyedMutatorWithHeaders } from '@gik/core/api/swr/middlewares/swrWithHeadersMiddleware';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import { can } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import sleep from '@gik/core/utils/sleep';
import { fuzzysearch } from '@gik/core/utils/string';
import { Button } from '@gik/ui/Button';
import type { IInkindListItemProps } from '@gik/ui/gik/InkindListItem';
import { InkindListItem } from '@gik/ui/gik/InkindListItem';
import type { InfiniteLoaderRefProps } from '@gik/ui/InfiniteLoader/InfiniteLoaderSWR';
import { InfiniteLoaderSWR } from '@gik/ui/InfiniteLoader/InfiniteLoaderSWR';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { PopoverListItem } from '@gik/ui/PopoverListItem';
import { SearchInput } from '@gik/ui/SearchInput';
import { UI } from '@gik/ui/UIManager';
import MinusIcon from '@heroicons/react/outline/MinusIcon';
import PlusIcon from '@heroicons/react/outline/PlusIcon';
import React from 'react';

interface AddToWishlistPopoverContentProps {
  productId: number;
  inkindRouteId?: string; // optional, when viewing an inkind page or wishlist
  closePopover?(): void;
}

export function AddToWishlistPopoverContent({
  productId,
  inkindRouteId,
  closePopover,
}: AddToWishlistPopoverContentProps): React.ReactElement {
  const userStore = useUserStore();
  const userId = userStore.id;
  const doAutoAction = React.useRef<boolean>();
  doAutoAction.current = true;
  const [searchStr, setSearchStr] = React.useState<string>();

  const { mutate: mutatePages } = useUserOwnedOrOrganizingPagesInfinite(userStore.id, { perPage: 4 });

  const loaderRef = React.useRef<InfiniteLoaderRefProps<InkindPageAPIModel, IInkindListItemProps>>();

  const bem = useBemCN('add-to-wishlist-popover-content');

  function useFetch(params?: APIWithPagination) {
    return useUserOwnedOrOrganizingPagesInfinite(userId, params);
  }

  const permissions = useUserStore(state => state.permissions);

  function transform(data: InkindPageAPIModel[]): IInkindListItemProps[] {
    let result: IInkindListItemProps[] = data?.map(item => ({
      name: item.title,
      thumbnail: item.thumb_small,
      isOrganizer: can('manage', 'inkinds', {
        inkindRouteId: item.routeId,
        groupId: item.groupId,
      }),
      routeId: item.routeId,
    }));

    // filter out pages from browsing history that do not match the search query
    if (result && searchStr) {
      result = result?.filter(item => fuzzysearch(searchStr, item.name));
    }

    return result;
  }

  // eslint-disable-next-line
  const { data: currentPageWishListProducts, mutate: refreshCurrentPageWishListProducts } =
    useWishlistProducts(inkindRouteId);

  const updateWishlistItem = React.useCallback(
    async (
      inkindPages: InkindPageAPIModel[],
      inkindListItem: IInkindListItemProps,
      mutate?: KeyedMutatorWithHeaders<InkindPageAPIModel[]>,
      optimisticUpdate?: OptimisticUpdate<InkindPageAPIModel>
    ) => {
      const matchingPage = inkindPages.find(page => page.routeId === inkindListItem.routeId);
      const isAdded = matchingPage.wishlistProductIds?.some(id => id === productId);
      const addToWishlist = !isAdded;

      const executeUpdate = async () => {
        const wishlistProductUrl = `inkinds/${matchingPage.routeId}/wishlistProducts/${productId}`;
        if (addToWishlist) {
          try {
            await dotnetApi.put(wishlistProductUrl);
            UI.notifySuccess('Item successfully added to wishlist');
          } catch (err) {
            UI.notifyError('Failed to add item to wishlist');
          }
        } else {
          try {
            await dotnetApi.delete(wishlistProductUrl);
            UI.notifySuccess('Item successfully removed from wishlist');
          } catch (err) {
            UI.notifyError('Failed to remove item from wishlist');
          }
        }
      };

      const isViewingInkindPage = inkindRouteId && matchingPage.routeId === inkindRouteId;

      if (currentPageWishListProducts && isViewingInkindPage) {
        // optimistic update for currently viewed inkind/wishlist page
        // - only removals implemented since products cannot currently
        // be added to the wishlist grid from the inkind/wishlist page
        if (!addToWishlist) {
          await refreshCurrentPageWishListProducts(
            // access the items ref, since wishlist toolbar does not have access to latest items
            currentPageWishListProducts.filter(item => item.id !== productId),
            null,
            false
          );
        }
      }

      const updatedPages = inkindPages.slice();
      updatedPages[updatedPages.indexOf(matchingPage)] = {
        ...matchingPage,
        wishlistProductIds: [
          ...(matchingPage?.wishlistProductIds ?? []).filter(id => id !== productId),
          ...(isAdded ? [] : [productId]),
        ],
      };

      if (optimisticUpdate) {
        optimisticUpdate(updatedPages, null, executeUpdate);
      } else {
        await executeUpdate();
        await loaderRef.current?.mutate();
        await mutatePages();
      }
      // await executeUpdate();

      if (currentPageWishListProducts && isViewingInkindPage) {
        await sleep(1000);
        await refreshCurrentPageWishListProducts();
      }
    },
    [inkindRouteId, currentPageWishListProducts, productId, refreshCurrentPageWishListProducts, mutatePages]
  );

  const isProductInWishlist = (page: InkindPageAPIModel) => page?.wishlistProductIds?.some(id => id === productId);

  function renderWishlistItem(
    inkindPages: InkindPageAPIModel[],
    inkindListItem: IInkindListItemProps,
    mutate: KeyedMutatorWithHeaders<InkindPageAPIModel[]>,
    optimisticUpdate: OptimisticUpdate<InkindPageAPIModel>
  ): React.ReactElement {
    const matchingPage = inkindPages.find(page => page.routeId === inkindListItem.routeId);
    const isAdded = isProductInWishlist(matchingPage);
    return (
      <PopoverListItem
        key={inkindListItem.routeId}
        prepend={<InkindListItem {...inkindListItem} />}
        icon={isAdded ? MinusIcon : PlusIcon}
        iconName={isAdded ? 'remove' : 'add'}
        danger={isAdded}
        iconSize="lg"
        hover
        onClick={() => updateWishlistItem(inkindPages, inkindListItem, mutate, optimisticUpdate)}
      />
    );
  }

  const handleFetchDone = React.useCallback(
    (data, transformed) => {
      const hasSinglePage = data?.length === 1;

      if (doAutoAction.current && hasSinglePage) {
        doAutoAction.current = false;

        updateWishlistItem(data, transformed[0]);
        loaderRef.current?.mutate();
        closePopover();
      }
    },
    [closePopover, updateWishlistItem]
  );

  return (
    <div {...bem()}>
      <InfiniteLoaderSWR<InkindPageAPIModel, IInkindListItemProps>
        ref={loaderRef}
        perPage={4}
        search={searchStr}
        fetch={useFetch}
        onFetchDone={handleFetchDone}
        transform={transform}
        header={({ isLoading, data, hasMore }) => (
          <>
            {(isLoading || data?.length !== 0) && <h6 {...bem('title')}>ADD TO WHICH WISHLIST?</h6>}
            {data?.length >= 5 && hasMore && (
              <SearchInput
                variant="default-solid"
                placeholder="Search pages"
                debounceValue={300}
                value={searchStr}
                {...bem('search')}
                onValueChange={setSearchStr}
              />
            )}
          </>
        )}
        footer={({ data, error, hasMore, isLoading, loadMore, totalItems }) => {
          const hasMoreResults = hasMore && data?.length > 0;

          if (!hasMoreResults && !error) return null;

          return (
            <div {...bem('footer')}>
              {hasMoreResults && (
                <>
                  <Button variant="primary-link" disabled={isLoading} onClick={loadMore}>
                    + {totalItems - data?.length} More Results
                  </Button>
                </>
              )}

              {error && <div className="gik-form-error">{error}</div>}
            </div>
          );
        }}
      >
        {({ data, transformed, isLoading, mutate, optimisticUpdate }) => {
          if (isLoading && data?.length === 0) return <LoadingSpinner center />;
          if (data?.length === 0 && searchStr) return <div> no results for &quot;{searchStr}&quot;</div>;

          if (data?.length === 0 && !searchStr) {
            return (
              <>
                <h6 {...bem('title')}>NO WISHLISTS TO ADD TO?</h6>
                <div {...bem('empty')}>
                  <p>
                    Start an InKind Page to
                    <br />
                    create a Wishlist
                  </p>
                  <Button type={'a'} href="/create" variant="secondary" pill>
                    Start a page
                  </Button>
                </div>
              </>
            );
          }

          return (
            <div>
              {transformed?.map(item => {
                return renderWishlistItem(data, item, mutate, optimisticUpdate);
                // <PopoverListItem
                //   hover
                //   key={item.routeId}
                //   prepend={<InkindListItem {...item} />}
                //   icon={ChevronRightIcon}
                //   onClick={() => onInkindPageChosen(item.routeId)}
                // />
              })}
              {isLoading && <LoadingSpinner center />}
            </div>
          );
        }}
      </InfiniteLoaderSWR>
    </div>
  );
}
