import type { FormSchemaEntry } from '@gik/ui/Form';

export type CityFieldProps = {
  required?: boolean;
};

export const CityField = (props?: CityFieldProps): FormSchemaEntry => ({
  label: 'City',
  name: 'city',
  type: 'text',
  required: props?.required !== undefined ? props?.required : true,
  minLength: 2,
  maxLength: 255,
  props: { autoComplete: 'address-level2' },
});
