import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface IInkindStore {
  inkindRouteId?: string;
  inkindGroupId?: string;
  isSamplePage?: boolean;

  hasPurchased?: boolean;
  isPremiumModalOpen?: boolean;

  setInkindRouteId(id: string): void;
  setInkindGroupId(id: string): void;
  setHasPurchased(hasPurchased: boolean): void;
  setIsSamplePage(isSamplePage: boolean): void;
  setPremiumModalOpen(isPremiumModalOpen: boolean): void;
}

export const inkindStore: StateCreator<IInkindStore, [], [], IInkindStore> = set => ({
  inkindRouteId: null,
  inkindGroupId: null,
  isSamplePage: false,
  hasPurchased: false,

  setInkindRouteId: (id: string) =>
    set(() => {
      return {
        inkindRouteId: id,
      };
    }),
  setInkindGroupId: (id: string) =>
    set(() => {
      return {
        inkindGroupId: id,
      };
    }),
  setHasPurchased: (hasPurchased: boolean) =>
    set(() => {
      return {
        hasPurchased,
      };
    }),
  setIsSamplePage: (isSamplePage: boolean) =>
    set(() => {
      return {
        isSamplePage,
      };
    }),
  setPremiumModalOpen: (isPremiumModalOpen: boolean) =>
    set(() => {
      return {
        isPremiumModalOpen,
      };
    }),
});

export const useInkindStore = create<IInkindStore>()(
  persist(inkindStore, {
    name: 'inkind',
    getStorage: () => localStorage,
  })
);
