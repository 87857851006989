import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useInkind } from '@gik/api/inkinds/inkind';
import { patchInkind } from '@gik/api/inkinds/inkinds';
import { optimisticUpdate } from '@gik/core/api';
import type { InkindPageAPIModel, PatchInkindPageRequest } from '@gik/core/models/gik/InkindPage';
import bemBlock from '@gik/core/utils/bemBlock';
import i18n from '@gik/i18n';
// imports for useCollectRecipientInfo
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { useInkindPageRecipientInfoComplete } from '@gik/inkind-page/utils/isInkindPageRecipientInfoComplete';
import { useDialogStore } from '@gik/ui/Dialogs';
import { PageSection } from '@gik/ui/gik/PageSection';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { ModalButtonFooter } from '@gik/ui/Modal';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import type { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { translationKeys } from './i18n/en';
import type { IRecipientAddressPromptFormValues } from './RecipientAddressPromptForm';
import { RecipientAddressPromptForm } from './RecipientAddressPromptForm';

export interface IRecipientAddressPromptProps {
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  initialValues?: Partial<IRecipientAddressPromptFormValues>;
  onSuccess?: (values: IRecipientAddressPromptFormValuesWithSource) => void;
  onSkip?: (values: IRecipientAddressPromptFormValuesWithSource) => void;
}

export interface IRecipientAddressPromptFormValuesWithSource extends IRecipientAddressPromptFormValues {
  isDontAskAgain?: boolean;
  whichButtonIsPressed?: 'skip' | 'next';
}

export const recipientAddressPromptBlockName = 'recipient-address-prompt';

export function RecipientAddressPrompt({
  buttonsPortal,
  onSuccess,
  onSkip,
  initialValues,
}: IRecipientAddressPromptProps): React.ReactElement {
  const bem = bemBlock(recipientAddressPromptBlockName);
  const { t } = useTranslation();

  const [isDontAskAgain, setIsDontAskAgain] = React.useState<boolean>(false);

  function handleSubmit(values: IRecipientAddressPromptFormValues) {
    if (onSuccess) onSuccess({ ...values, whichButtonIsPressed: 'next' });
  }

  const handleWhyAddAddress = async () => {
    const infoContent = (
      <>
        <p className={bem('dialog-text')}>{t(translationKeys.whyAddAddressDescription1).toString()}</p>
        <p className={bem('dialog-text')}>
          <HTMLParser rawHtml={t(translationKeys.whyAddAddressDescription2)} />
        </p>
      </>
    );
    await UI.info(infoContent, {
      title: t(translationKeys.whyAddAddressTitle),
      okText: t(translationKeys.whyAddAddressButton),
      className: bem('why-add-dialog'),
    });
  };

  const handleSkip = async () => {
    const confirmContent = <p className={bem('dialog-text')}>{t(translationKeys.skipDialogDescription).toString()}</p>;

    const response = await UI.confirm(confirmContent, {
      title: t(translationKeys.skipDialogTitle),
      okText: t(translationKeys.skipDialogOkButton),
      cancelText: t(translationKeys.skipDialogCancelButton),
      okButtonProps: { variant: 'danger', size: 'base' },
      cancelButtonProps: { variant: 'default', size: 'base' },
      className: bem('skip-dialog'),
    });

    if (response) {
      if (onSkip)
        onSkip({
          // just forward initalValues, they won't be used
          ...(initialValues as IRecipientAddressPromptFormValues),
          isDontAskAgain,
          whichButtonIsPressed: 'skip',
        });
    }
  };

  return (
    <>
      <PageSection noPad variant="aqua" gradientDarkening className={bem('header')}>
        <span className={bem('title')}>{t(translationKeys.title)}</span>
        <SvgIcon className={bem('icon')} onClick={handleWhyAddAddress} Icon={InformationCircleIcon} />
        <p className={bem('description')}>{t(translationKeys.description)}</p>
      </PageSection>
      <div className={bem('content')}>
        <RecipientAddressPromptForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          buttonsPortal={buttonsPortal}
          isRecipientFullNameRequired={true}
          onSkip={handleSkip}
          isDontAskAgain={isDontAskAgain}
          setIsDontAskAgain={setIsDontAskAgain}
        />
      </div>
    </>
  );
}

export const recipientAddressPromptModalBlockName = 'recipient-address-prompt-modal';

export function openRecipientAddressPrompt(
  initialValues?: Partial<IRecipientAddressPromptFormValues>
): Promise<IRecipientAddressPromptFormValuesWithSource> {
  const bem = bemBlock(recipientAddressPromptModalBlockName);
  const footerId = 'recipient-prompt-address-form-footer-id';

  return UI.dialog(
    ({ close }) => (
      <RecipientAddressPrompt
        onSuccess={close}
        onSkip={close}
        buttonsPortal={() => document.getElementById(footerId)}
        initialValues={initialValues}
      />
    ),
    {
      title: i18n.t(translationKeys.modalTitle),
      padding: false,
      closable: true,
      className: bem(),
      footer: <ModalButtonFooter id={footerId} />,
    }
  );
}

function getRecipientInfoFromInkindPage(inkindPage: InkindPageAPIModel) {
  return {
    recipientFullName: inkindPage.recipientFullName,
    address1: inkindPage.address,
    address2: inkindPage.address2,
    postalCode: inkindPage.zip,
    city: inkindPage.city,
    state: inkindPage.stateCode,
    country: 'US',
  };
}

type CollectRecipientInfoStore = {
  pageIdsArray: string[];
  setPageIdsArray: (pageIdsArray: string[]) => void;
};

export const collectRecipientStore: StateCreator<
  CollectRecipientInfoStore,
  [],
  [],
  CollectRecipientInfoStore
> = set => ({
  pageIdsArray: undefined,
  setPageIdsArray: pageIdsArray => set(() => ({ pageIdsArray })),
});

const useCollectRecipientInfoStore = create<CollectRecipientInfoStore>()(
  persist(collectRecipientStore, {
    name: bemBlock('user-already-prompted-for-recipient-info-page-ids')(),
    getStorage: () => localStorage,
  })
);

export function useCollectRecipientInfo(): () => Promise<boolean> {
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkindPage, mutate: mutateInkindPage } = useInkind(inkindRouteId);
  const isRecipientInfoComplete = useInkindPageRecipientInfoComplete(inkindPage);
  const { _pageIdsArray, setPageIdsArray } = useCollectRecipientInfoStore(state => ({
    _pageIdsArray: state.pageIdsArray,
    setPageIdsArray: state.setPageIdsArray,
  }));
  const pageIdsArray = React.useMemo(() => _pageIdsArray || [], [_pageIdsArray]);
  const isAlreadyPrompted = React.useMemo(
    () => pageIdsArray.find(_inkindRouteId => _inkindRouteId === inkindRouteId),
    [inkindRouteId, pageIdsArray]
  );

  const dialogs = useDialogStore(state => state.dialogs);

  return async (): Promise<boolean> => {
    if (!inkindRouteId || !inkindPage) return false;
    if (isAlreadyPrompted) return true;

    if (!isRecipientInfoComplete) {
      // stop checking if another modal is already open
      if (dialogs.length) return false;

      Analytics.fireEvent(AnalyticsEvents.RecipientAddAddressPromptOpen, { inkindRouteId });

      const initialValues = getRecipientInfoFromInkindPage(inkindPage);
      const recipientInfo = await openRecipientAddressPrompt(initialValues);

      if (recipientInfo && recipientInfo.whichButtonIsPressed === 'skip' && recipientInfo.isDontAskAgain === true) {
        setPageIdsArray([...pageIdsArray, inkindRouteId]);
        Analytics.fireEvent(AnalyticsEvents.RecipientAddAddressPromptSkipped, { inkindRouteId });
      }

      if (recipientInfo && recipientInfo.whichButtonIsPressed === 'next') {
        const patchInkindPageData: PatchInkindPageRequest = {
          recipient: {
            type: inkindPage.recipientType,
            fullName: recipientInfo.recipientFullName,
            address1: recipientInfo.address1,
            address2: recipientInfo.address2,
            city: recipientInfo.city,
            postalCode: recipientInfo.postalCode,
            state: recipientInfo.state,
          },
        };

        const newInkindPage: InkindPageAPIModel = {
          ...inkindPage,
          recipientFullName: recipientInfo.recipientFullName,
          address: recipientInfo.address1,
          address2: recipientInfo.address2,
          city: recipientInfo.city,
          stateCode: recipientInfo.state,
          zip: recipientInfo.postalCode,
        };

        optimisticUpdate(newInkindPage, null, mutateInkindPage, () => patchInkind(inkindRouteId, patchInkindPageData));
        Analytics.fireEvent(AnalyticsEvents.RecipientAddAddressPromptAdded, { inkindRouteId });
      } else {
        Analytics.fireEvent(AnalyticsEvents.RecipientAddAddressPromptSkipped, { inkindRouteId });
      }
    }
    return true;
  };
}
