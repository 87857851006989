import { auth } from '@gik/auth';
import { SignInFlowCalloutBlock } from '@gik/auth/components/SignInFlow/SignInFlowCallout';
import { SignInFlowContentCopyVariant } from '@gik/auth/components/SignInFlow/SignInFlowStartContent';
import { useProducts } from '@gik/checkout/api';
import { usePerfectGiftCarrierPrices } from '@gik/checkout/utils/usePerfectGiftCarrierPrices';
import { gikClassPrefix } from '@gik/core/constants';
import { CheckoutType } from '@gik/core/models/gik/Product';
import { useUserStore } from '@gik/core/store/UserStore';
import { LottieOptions } from '@gik/ui/Animation';
import { Button } from '@gik/ui/Button';
import { Collapse } from '@gik/ui/Collapse';
import { Decoder } from '@gik/ui/Decoder';
import loadable from '@loadable/component';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';
import orderCardAnimation from '../../animations/orderCard.json';
import orderGiftboxAnimation from '../../animations/orderGiftbox.json';
import type { PaymentConfirmationValues } from '../../types';
import { PerfectgiftCarrierTypes } from '../../types';
import { parsePaymentMethodName } from '../../utils/productUtils';

const Format = loadable.lib(() => import('@gik/core/utils/format'));
const Lottie = dynamic(() => import('react-lottie'));

export interface PaymentConfirmationProps extends React.HTMLAttributes<HTMLDivElement> {
  values: PaymentConfirmationValues;
  productName: string;
  shipping?: boolean;
  header?: React.ReactNode;
  successIcon?: React.ReactNode;
  renderDefaultThankYou?: boolean;
  inkindRouteId?: string;
  renderFulfillmentDescription?: (fulfillmentTimeString: string) => React.ReactNode;
}

const blockName = `${gikClassPrefix}-payment-confirmation`;

export function PaymentConfirmation({
  className,
  values,
  productName,
  shipping,
  header,
  inkindRouteId,
  successIcon,
  renderDefaultThankYou = true,
  renderFulfillmentDescription = fulfillmentTimeString => '',
  ...otherProps
}: PaymentConfirmationProps): React.ReactElement {
  const productIds = values.products ? values.products.map(item => item.productId) : [];
  const { data: products } = useProducts({ productIds });
  const { greetingCardCarrierPrice, trifoldWithEnvelopeCarrierPrice } = usePerfectGiftCarrierPrices();

  const userId = useUserStore(state => state.id);

  const blockClasses = classnames([blockName, className || '']);

  const shippingDetails = values.shipping;
  const paymentMethod = parsePaymentMethodName(values.paymentMethod);

  const orderCardAnimationOptions = LottieOptions.looped(orderCardAnimation);
  const giftboxAnimationOptions = LottieOptions.looped(orderGiftboxAnimation);

  function isDonation() {
    return values.products?.some(product => product.checkoutType === CheckoutType.Donation);
  }

  function isGiftCard() {
    return values.products?.some(
      product =>
        product.checkoutType === CheckoutType.Perfectgift ||
        product.checkoutType === CheckoutType.TangoCard ||
        product.checkoutType === CheckoutType.GiftyaPlatform
    );
  }

  function isPremium() {
    return values.products?.some(product => product.checkoutType === CheckoutType.GikPremium);
  }

  // get the checkoutType from the first product
  const animation = isGiftCard() ? orderCardAnimationOptions : giftboxAnimationOptions;

  const fulfillmentTimeString = isGiftCard() ? '24 hours' : '1-2 business days';

  function onClickSignupForUpdates() {
    auth.signin({
      callout: SignInFlowCalloutBlock.UPDATE_VIEW_ATTEMPT,
      copyVariant: SignInFlowContentCopyVariant.INKIND_PAGE,
      routeId: inkindRouteId,
    });
  }

  function renderRecipientDisplay() {
    if (values.products.every(p => p.productType === 'physical')) {
      return `${shippingDetails.firstName} ${shippingDetails.lastName}`.trim();
    }

    return isGiftCard() ? shippingDetails.email : `${shippingDetails.firstName} ${shippingDetails.lastName}`.trim();
  }

  function renderOrderConfirmationSummary() {
    if (isGiftCard()) {
      const bridge = values.products.length > 1 ? 's are on their ' : ' is on its ';
      const bridge2 = values.products.length > 1 ? 'are' : 'is';

      if (values.products.every(p => p.productType === 'digital' || p.productType === undefined)) {
        return (
          <>
            Your gift{bridge}way to <strong>{shippingDetails.email}</strong> and {bridge2} typically available to the
            recipient within 24hrs.
          </>
        );
      }

      if (values.products.every(p => p.productType === 'physical')) {
        return (
          <>
            Your gift card{bridge}way! The Visa Gift Card{values.products.length > 1 ? 's' : ''} and Greeting Card
            {values.products.length > 1 ? 's' : ''} will be mailed to the recipient’s shipping address, arriving in the
            next 2-7 business days, depending on your selected shipping method. You will also receive an order
            confirmation email shortly.
            {renderFulfillmentDescription(fulfillmentTimeString)}
          </>
        );
      }

      return (
        <>
          Your gift card{bridge}way! Digital Gift Cards will be delivered to <strong>{shippingDetails.email}</strong>{' '}
          and {bridge2} typically fulfilled and sent in {fulfillmentTimeString}. Visa Gift Card
          {values.products.length > 1 ? 's' : ''} and Greeting Card
          {values.products.length > 1 ? 's' : ''} will be mailed to the recipient’s shipping address, arriving in the
          next 2-7 business days, depending on your selected shipping method. You will also receive an order
          confirmation email shortly.
          {renderFulfillmentDescription(fulfillmentTimeString)}
        </>
      );
    }

    return (
      <>
        {!isDonation() ? (
          <>
            Your&nbsp;
            <strong>
              <Decoder text={productName} />
            </strong>
            &nbsp;is on its way to <strong>{renderRecipientDisplay()}</strong>
            {renderFulfillmentDescription(fulfillmentTimeString)}
          </>
        ) : (
          <>We appreciate your support in keeping Give InKind free when it’s needed most.</>
        )}
      </>
    );
  }

  const numberOfGreetingCards = values.products.filter(
    p => p.carrier?.carrierType == PerfectgiftCarrierTypes.GREETING_CARD
  )?.length;

  const numberOfTrifolds = values.products.filter(
    p => p.carrier?.carrierType == PerfectgiftCarrierTypes.TRIFOLD_WITH_ENVELOPE
  )?.length;

  return (
    <Format fallback={'-'}>
      {({ formatCurrency, formatDate }) => {
        return (
          <div className={blockClasses} {...otherProps}>
            <div className={`${blockName}__header`}>{header}</div>
            {renderDefaultThankYou && (
              <div className="tw-text-center">
                {successIcon || (
                  <div className={`${blockName}__icon gik-circle gik-circle--primary gik-circle--2xl`}>
                    <Lottie options={animation} isPaused={false} />
                  </div>
                )}
                <span className={`${blockName}__section-title`}>Thanks for your order!</span>
                <p className={`${blockName}__section-text-body`}>{renderOrderConfirmationSummary()}</p>

                {!userId && inkindRouteId && (
                  <>
                    <p className={`${blockName}__section-text-body`}>
                      Stay in the loop. Get updates from this page so you know how best to support{' '}
                      <strong>{`${shippingDetails.firstName} ${shippingDetails.lastName}`.trim()}</strong> in the
                      future.
                    </p>

                    <Button className={`${blockName}__section-text-body`} onClick={onClickSignupForUpdates}>
                      Sign up for updates
                    </Button>
                  </>
                )}
              </div>
            )}

            <ul className="gik-tablelist">
              <li>
                <label>Order number:</label>
                <strong>{values.orderId}</strong>
              </li>
              <li>
                <label>Date:</label>
                <strong>{formatDate(values.orderDate)}</strong>
              </li>
              <li>
                <label>Email:</label>
                <strong>{values.senderEmail}</strong>
              </li>
              <li>
                <label>Total:</label>
                <strong>{formatCurrency(values.total)}</strong>
              </li>
              <li>
                <label>Payment method:</label>
                <strong>{paymentMethod}</strong>
              </li>
            </ul>

            <br />

            <Collapse className={`${blockName}__collapse`} title="Show Order Details" visibleTitle="Hide Order Details">
              <ul className="gik-datalist">
                {products &&
                  values.products &&
                  values.products.map((item, index) => {
                    // find product based on the order's productId
                    const product = products.find(product => product.id === item.productId);
                    if (!product) {
                      return <li key={`notfound-${index}`}>Product not found...</li>;
                    }
                    return (
                      <li key={item.productId}>
                        <label>
                          <strong>
                            <Decoder text={product.name} /> <strong>x{item.quantity || 1}</strong>
                          </strong>
                        </label>
                        <span>
                          <strong>{formatCurrency(item.price)}</strong>
                        </span>
                      </li>
                    );
                  })}
                <li className="gik-datalist__item--highlight">
                  <label>
                    <strong>Subtotal:</strong>
                  </label>
                  <span>{formatCurrency(values.subtotal)}</span>
                </li>
                {values.pgCarrier === PerfectgiftCarrierTypes.GREETING_CARD && (
                  <li className="gik-datalist__item--highlight">
                    <label>
                      <strong>Greeting card:</strong>
                    </label>

                    <span className={'tw-text-right'}>
                      <span>{formatCurrency(greetingCardCarrierPrice * numberOfGreetingCards)}</span>
                    </span>
                  </li>
                )}
                {values.pgCarrier === PerfectgiftCarrierTypes.TRIFOLD_WITH_ENVELOPE && (
                  <li className="gik-datalist__item--highlight">
                    <label>
                      <strong>Trifold:</strong>
                    </label>

                    <span className={'tw-text-right'}>
                      {numberOfTrifolds > 1 && (
                        <span>
                          {numberOfTrifolds || 1} x {formatCurrency(trifoldWithEnvelopeCarrierPrice)}
                          {' = '}
                        </span>
                      )}
                      <span>{formatCurrency(trifoldWithEnvelopeCarrierPrice * numberOfTrifolds)}</span>
                    </span>
                  </li>
                )}

                {/* https://www.notion.so/giveinkind/GIK-5897-remove-the-tax-line-from-the-order-summary-in-checkout-flow-98defd852929452c8c8d37a9335217d7  */}
                {/* {values.taxCost !== undefined && (
                  <li className="gik-datalist__item--highlight">
                    <label>
                      <strong>Taxes:</strong>
                    </label>
                    <span>{formatCurrency(values.taxCost)}</span>
                  </li>
                )} */}
                {!isDonation() && !isPremium() && values.shippingCost !== undefined && values.shippingCost > 0 && (
                  <li className="gik-datalist__item--highlight">
                    <label>
                      <strong>Shipping:</strong>
                    </label>
                    <span>{formatCurrency(values.shippingCost)}</span>
                  </li>
                )}
                {!isDonation() && values.tip !== undefined && (
                  <li className="gik-datalist__item--highlight">
                    <label>
                      <strong>Give InKind Tip:</strong>
                    </label>
                    <span>{formatCurrency(values.tip)}</span>
                  </li>
                )}
                {values.shippingTax !== undefined && (
                  <li className="gik-datalist__item--highlight">
                    <label>
                      <strong>Shipping Tax:</strong>
                    </label>
                    <span>{formatCurrency(values.shippingTax)}</span>
                  </li>
                )}
                <li className="gik-datalist__item--highlight">
                  <label>
                    <strong>Payment method:</strong>
                  </label>
                  <span>{paymentMethod}</span>
                </li>
                <li className="gik-datalist__item--highlight">
                  <label>
                    <strong>Total:</strong>
                  </label>
                  <span>{formatCurrency(values.total)}</span>
                </li>
              </ul>
            </Collapse>

            {shipping && shippingDetails && (
              <section>
                <span className="gik-payment-confirmation__shipping-title">Shipping to:</span>
                <address>
                  <strong>
                    {shippingDetails.firstName} {shippingDetails.lastName}
                  </strong>
                  <br />
                  {shippingDetails.address1} <br />
                  {shippingDetails.address2 && (
                    <>
                      {shippingDetails.address2} <br />
                    </>
                  )}
                  {shippingDetails.city}, {shippingDetails.state} {shippingDetails.postalCode} <br />
                </address>
              </section>
            )}
          </div>
        );
      }}
    </Format>
  );
}
