import type { FormSchemaEntry } from '@gik/ui/Form';

export type FirstNameFieldProps = {
  readOnly?: boolean;
  required?: boolean;
  labelPrefix?: string;
};

export const FirstNameField = (props?: FirstNameFieldProps): FormSchemaEntry => ({
  label: (props?.labelPrefix ?? '') + 'First name',
  name: 'firstName',
  type: props?.readOnly ? 'plaintext' : 'text',
  required: props?.required !== undefined ? props?.required : true,
  minLength: 2,
  maxLength: 255,
  props: { autoComplete: 'given-name', centerVertical: props?.readOnly },
});
