import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';

export interface ISearchStore {
  readonly isOpen: boolean;
  readonly navigationLoading: boolean;
  readonly query: string;
  setQuery: (query: string) => void;
  open(): void;
  close(): void;
  navigationStart(): void;
}

export const searchStore: StateCreator<ISearchStore, [], [], ISearchStore> = set => ({
  isOpen: false,
  navigationLoading: false,
  query: '',
  setQuery: query => set({ query }),
  navigationStart: () => set({ navigationLoading: true }),
  open: () =>
    set({
      isOpen: true,
      query: '',
    }),
  close: () =>
    set({
      isOpen: false,
      navigationLoading: false,
      query: '',
    }),
});

export const useSearchStore = create<ISearchStore>()(searchStore);

export function openSearch(
  location: 'NavBar' | 'HomePageFindAPage' | 'InkindPageNotFound' | 'FeaturedInkindsSection' | 'MenuBar'
) {
  useSearchStore.getState().open();
  Analytics.fireEvent(
    AnalyticsEvents.SearchButtonClicked,
    {
      location,
    },
    () => ['location']
  );
}
