// TODO: rename this file to DialogStore.ts
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import type { DialogResponseType, IDialog } from './DialogTypes';

export interface IDialogStore {
  readonly dialogs?: IDialog[];
  readonly nextDialogId?: number;
  readonly counter?: number;
  readonly sheet?: React.ReactNode | (() => React.ReactNode);
  readonly sheetIsOpen?: boolean;
  readonly modalSheetHasChanges?: boolean;
  add(item: IDialog): string;
  open(id: string): void;
  close(id?: string, response?: DialogResponseType): void;
  closeByKey(key?: string, response?: DialogResponseType): void;
  closeAll(response: DialogResponseType): void;
  remove(id: string): void;
  removeAll(): void;
  addModalSheet(item?: React.ReactNode | (() => React.ReactNode)): void;
  closeModalSheet(): void;
  removeModalSheet(): void;
  setModalSheetHasChanges(modalSheetHasChanges: boolean): void;
}

export const dialogStore: StateCreator<IDialogStore, [], [], IDialogStore> = set => ({
  dialogs: [],
  sheet: null,
  sheetIsOpen: false,
  nextDialogId: 0,
  modalSheetHasChanges: false,
  counter: 0,
  add: (item: IDialog) => {
    let itemId: string;
    set(state => {
      itemId = state.nextDialogId.toString();
      item.id = itemId;
      item.open = false;
      if (!item.okButtonProps) item.okButtonProps = {};
      if (!item.cancelButtonProps) item.cancelButtonProps = {};
      if (!item.type) item.type = 'dialog';
      return {
        dialogs: state.dialogs.concat([item]),
        counter: state.counter + 1,
        nextDialogId: state.nextDialogId + 1,
      };
    });
    return itemId;
  },
  open: (id: string) =>
    set(state => {
      return {
        dialogs: state.dialogs.map(entry => {
          if (entry.id === id) {
            entry.open = true;
          }
          return entry;
        }),
      };
    }),
  setModalSheetHasChanges: (modalSheetHasChanges: boolean) =>
    set(() => {
      return {
        modalSheetHasChanges,
      };
    }),
  close: (id?: string, response?: DialogResponseType) =>
    set(state => {
      return {
        dialogs: state.dialogs.map(entry => {
          // close the last dialog that was opened
          if (!id) id = state.dialogs[state.dialogs.length - 1].id;

          if (entry.open && entry.id === id) {
            // only set the response on the item if one was provided
            // this is to make sure we don't overwrite the response which might have been assigned elsewhere
            if (response !== undefined) entry.response = response;
            entry.open = false;
          }
          return entry;
        }),
      };
    }),
  closeByKey: (key?: string, response?: DialogResponseType) =>
    set(state => {
      return {
        dialogs: state.dialogs.map(entry => {
          if (entry.open && entry.key === key) {
            // only set the response on the item if one was provided
            // this is to make sure we don't overwrite the response which might have been assigned elsewhere
            if (response !== undefined) entry.response = response;
            entry.open = false;
          }
          return entry;
        }),
      };
    }),
  closeAllAboveFirst: () =>
    set(state => {
      return {
        dialogs: state.dialogs.map(entry => {
          entry.open = false;
          return entry;
        }),
      };
    }),
  closeAll: () =>
    set(state => {
      return {
        dialogs: state.dialogs.map(entry => {
          entry.open = false;
          return entry;
        }),
      };
    }),
  remove: (id: string) =>
    set(state => {
      return {
        dialogs: state.dialogs.filter(entry => entry.id !== id),
      };
    }),
  removeAll: () =>
    set(() => {
      return {
        dialogs: [],
      };
    }),
  addModalSheet: (item: React.ReactNode | (() => React.ReactNode)) =>
    set(() => {
      return {
        sheet: item,
        sheetIsOpen: true,
      };
    }),
  closeModalSheet: () =>
    set(() => {
      return {
        sheetIsOpen: false,
      };
    }),
  removeModalSheet: () =>
    set(() => {
      return {
        sheet: null,
        sheetIsOpen: false,
      };
    }),
});

export const useDialogStore = create<IDialogStore>()(dialogStore);
