import { AxiosError } from 'axios';
import type { AxiosResponseWithError, StrapiErrorResponse } from '../types/Response';

/**
 * Intercept thrown errors and return them as a response property instead
 */
export async function errorInterceptor(error: unknown) {
  if (error instanceof AxiosError) {
    const serverError: StrapiErrorResponse = error.response?.data;
    return { error: serverError, data: null } as AxiosResponseWithError<unknown>;
  }

  return { error, data: null };
}

export async function errorInterceptorBase(error: unknown) {
  if (error instanceof AxiosError) {
    const serverError: AxiosResponseWithError<StrapiErrorResponse> = error.response?.data;

    return { ...serverError, status: error.response?.status, statusText: error.response?.statusText };
  }

  return { error, data: null };
}

export async function dotnetErrorInterceptorBase(error: unknown) {
  if (error instanceof AxiosError) {
    const serverError: AxiosResponseWithError<StrapiErrorResponse> = error.response?.data;

    const errorData = {
      cause: error.cause,
      status: error.response?.status,
      statusText: error.response?.statusText,
    };

    if (typeof serverError === 'string') {
      return {
        message: serverError,
        ...errorData,
      };
    }

    return {
      ...serverError,
      ...errorData,
    };
  }

  return { error, data: null };
}
