import type { FormRef, FormSchemaEntry } from '@gik/ui/Form';
import type { StepsRefProps } from '@gik/ui/Steps';
import React from 'react';
import { create } from 'zustand';
import type { StateCreator } from 'zustand';

interface CheckoutFormModalStore {
  stepsRef: React.MutableRefObject<StepsRefProps<string>>;
  orderSummaryFormRef: React.MutableRefObject<FormRef>;
  cardCarrierFormRef: React.MutableRefObject<FormRef>;
  billingFormRef: React.MutableRefObject<FormRef>;
  billingFormSchema: React.MutableRefObject<FormSchemaEntry[]>;
  isCardFieldComplete: React.MutableRefObject<boolean>;
}

export const checkoutFormModalStore: StateCreator<CheckoutFormModalStore, [], [], CheckoutFormModalStore> = set => ({
  stepsRef: null,
  orderSummaryFormRef: React.createRef<FormRef>() as React.MutableRefObject<FormRef>,
  cardCarrierFormRef: React.createRef<FormRef>() as React.MutableRefObject<FormRef>,
  billingFormRef: React.createRef<FormRef>() as React.MutableRefObject<FormRef>,
  billingFormSchema: React.createRef<FormSchemaEntry[]>() as React.MutableRefObject<FormSchemaEntry[]>,
  isCardFieldComplete: React.createRef() as React.MutableRefObject<boolean>,
});

export const useCheckoutFormModalStore = create<CheckoutFormModalStore>()(checkoutFormModalStore);
