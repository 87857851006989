import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import cn from 'classnames';
import type { Breakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import { gikClassPrefix } from '@gik/core/constants';

export const Layout = withComponentErrorBoundary(LayoutComp);

type DirectionType = 'row' | 'column' | 'row-reverse' | 'column-reverse';
type AlignType = 'start' | 'center' | 'end' | 'space-around' | 'space-between' | 'space-evenly';
type PerpendicularType = 'start' | 'center' | 'end' | 'stretch';

type WithBreakpoint<T extends string> = `${Breakpoint}:${T}`;
type Token<T extends string> = WithBreakpoint<T> | T;

type TokenWithBreakpoint<T extends string> =
  | Token<T>
  | `${Token<T>} ${Token<T>}`
  | `${Token<T>} ${Token<T>} ${Token<T>}`
  | `${Token<T>} ${Token<T>} ${Token<T>} ${Token<T>}`;

export type ILayoutProps = UIComponent & {
  direction?: TokenWithBreakpoint<DirectionType>;
  align?: TokenWithBreakpoint<AlignType>;
  perpendicular?: TokenWithBreakpoint<PerpendicularType>;
  gap?: number;
  flex?: number | string;
  inline?: boolean;
  element?: string;
};

function LayoutComp({
  direction = 'row',
  align = 'start',
  perpendicular = 'stretch',
  gap,
  flex,
  inline = false,
  className,
  children,
  element = 'section',
  ...otherProps
}: ILayoutProps) {
  const bem = useBemCN('layout');

  const directionClass = useLayoutDirectionClassNames(direction);
  const alignClass = useLayoutAlignClassNames(align);
  const perpendicularClass = useLayoutPerpendicularClassNames(perpendicular);

  const finalClassName = cn(directionClass, alignClass, perpendicularClass, flex === 1 ? 'tw-flex-1' : null, className);

  return React.createElement(
    element,
    {
      ...otherProps,
      ...bem(null, [{ inline }], finalClassName),
      style: {
        ...(otherProps.style ?? {}),
        flex: flex === 1 ? undefined : flex,
        gap: otherProps.style?.gap ?? (gap ? `${gap / 4}rem` : undefined),
      },
    },
    children
  );
}

function useLayoutDirectionClassNames(direction?: TokenWithBreakpoint<DirectionType>): string {
  if (!direction) {
    return '';
  }

  return direction
    .split(' ')
    .map(value => {
      if (value.includes(':')) {
        const [breakpoint, direction] = value.split(':') as [Breakpoint, DirectionType];
        return `${breakpoint}-direction-${direction}`;
      } else {
        return `direction-${direction}`;
      }
    })
    .map(v => `${gikClassPrefix}-layout--${v}`)
    .join(' ');
}

function useLayoutAlignClassNames(align?: TokenWithBreakpoint<AlignType>): string {
  if (!align) {
    return '';
  }

  return align
    .split(' ')
    .map(value => {
      if (value.includes(':')) {
        const [breakpoint, align] = value.split(':') as [Breakpoint, AlignType];
        return `${breakpoint}-align-${align}`;
      } else {
        return `align-${align}`;
      }
    })
    .map(v => `${gikClassPrefix}-layout--${v}`)
    .join(' ');
}

function useLayoutPerpendicularClassNames(perpendicular?: TokenWithBreakpoint<PerpendicularType>): string {
  if (!perpendicular) {
    return '';
  }

  return perpendicular
    .split(' ')
    .map(value => {
      if (value.includes(':')) {
        const [breakpoint, perpendicular] = value.split(':') as [Breakpoint, PerpendicularType];
        return `${breakpoint}-perpendicular-${perpendicular}`;
      } else {
        return `perpendicular-${perpendicular}`;
      }
    })
    .map(v => `${gikClassPrefix}-layout--${v}`)
    .join(' ');
}
