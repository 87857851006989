const prefix = 'gift-wallet/admin/';

const orderLog = {
  list: () => `${prefix}order-logs`,
  get: (id: string) => `${prefix}order-logs/${id}`,
  getByOrderId: (id: string) => `${prefix}order-logs/order/${id}`,
  create: () => `${prefix}order-logs`,
  update: (id: string) => `${prefix}order-logs/${id}`,
  delete: (id: string) => `${prefix}order-logs/${id}`,
};

const giftNote = {
  list: () => `${prefix}gift-notes`,
  get: (id: string) => `${prefix}gift-notes/${id}`,
  create: () => `${prefix}gift-notes`,
  update: (id: string) => `${prefix}gift-notes/${id}`,
  delete: (id: string) => `${prefix}gift-notes/${id}`,
};

const gift = {
  list: () => `${prefix}gifts`,
  get: (id: string) => `${prefix}gifts/${id}`,
  create: () => `${prefix}gifts`,
  update: (id: string) => `${prefix}gifts/${id}`,
  delete: (id: string) => `${prefix}gifts/${id}`,
  registerMissingCredits: (id: string) => `${prefix}gifts/${id}/register-missing-credits`,
  getCardTransactionHistoryByGyGiftid: (gyGiftId: string) => `${prefix}card-transaction-history/${gyGiftId}`,
  getDetailed: (id: string) => `${prefix}gifts/${id}/detailed`,
  getRemoteGift: (id: string) => `${prefix}gifts/${id}/remote`,
  getRemoteEGift: (id: string) => `${prefix}gifts/${id}/egift`,
  getRemoteMerchant: (id: string) => `${prefix}gifts/${id}/merchant`,
  createEGift: (uuid: string) => `${prefix}gifts/${uuid}/create-egift`,
  createPGGift: (uuid: string) => `${prefix}gifts/${uuid}/create-perfectgift`,
  cancelGiftyaGift: (uuid: string) => `${prefix}gifts/${uuid}/cancel-giftya-gift`,
  markAsRedeemed: (uuid: string) => `${prefix}gifts/${uuid}/mark-redeemed`,
  markAsReady: (uuid: string) => `${prefix}gifts/${uuid}/mark-ready`,
};

const wallet = {
  list: () => `${prefix}wallets`,
  get: (id: string) => `${prefix}wallets/${id}`,
  create: () => `${prefix}wallets`,
  update: (id: string) => `${prefix}wallets/${id}`,
  delete: (id: string) => `${prefix}wallets/${id}`,
  getByCustomerId: (id: string) => `${prefix}wallets/customer/${id}`,
  updateByCustomerId: (id: string) => `${prefix}wallets/customer/${id}`,
  activateByCustomerId: (id: string) => `${prefix}wallets/customer/${id}/unarchive`,
  updateName: (id: string) => `${prefix}update-wallet-name/${id}`,
  updateCounters: (id: string) => `${prefix}wallets/customer/${id}/update-gift-count`,
  cancelMergedGiftForCardLinking: (id: string, giftUuid: string) =>
    `${prefix}wallets/customer/${id}/cancel-merged-gift-for-card-linking/${giftUuid}`,
  deactivateByCustomerId: (id: string, markAsUsed: boolean) =>
    `${prefix}wallets/customer/${id}/archive?markAsUsed=${markAsUsed}`,
  securityLock: (id: string) => `${prefix}wallets/customer/${id}/security-lock`,
  securityUnlock: (id: string) => `${prefix}wallets/customer/${id}/security-unlock`,
};

export const giftWalletAdminEndPoints = {
  gift,
  wallet,
  orderLog,
  giftNote,
};
