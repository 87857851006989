import { DevTools } from '@gik/core/components/DevTools/DevTools';
import React from 'react';
import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';

export function withDevTools(Component: React.ReactElement) {
  return <DevToolsClientOnlyWrapper>{Component}</DevToolsClientOnlyWrapper>;
}

function DevToolsClientOnlyWrapper({ children }: React.PropsWithChildren) {
  const mounted = useIsMounted();

  return (
    <>
      {children}
      {mounted() && <DevTools />}
    </>
  );
}
