import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type WalletSessionStore = {
  accessToken?: string;
  setAccessToken: (refreshToken: string) => void;
};

export const walletSessionStore: StateCreator<WalletSessionStore, [], [], WalletSessionStore> = set => ({
  setAccessToken: (accessToken?: string) => set(() => ({ accessToken })),
});

export const useWalletSessionStore = create<WalletSessionStore>()(
  persist(walletSessionStore, {
    name: 'wallet-session-store',
    getStorage: () => sessionStorage,
  })
);
