import { type ProductVariation, type Product } from '@gik/models/gik/Product';
import type { GiftRedeemAs } from '@gik/platform-management-api-js';
import { type GiftEntity } from '@gik/platform-management-api-js';
import { RedeemedGiftShippingMethod, type RedemptionType } from '@gik/user-profile/components/RedemptionModal/types';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { BrandCategory } from '@gik/calendar/models/BrandCategory';
import { ShippingMethod } from '@gik/checkout/components/useShippingMethods';

export type GiftValueValidationError = {
  name: string;
  params: Record<string, unknown>;
};

export type WalletRedemptionKeyStore = {
  redeemAsProduct?: Product;
  setRedeemAsProduct: (redeemAsProduct: Product) => void;
  redeemAsVariation?: ProductVariation;
  setRedeemAsVariation: (redeemAsVariation: ProductVariation) => void;

  redeemSubmitting?: boolean;
  setRedeemSubmitting: (redeemSubmitting: boolean) => void;

  redemptionType?: RedemptionType;
  setRedemptionType: (redeemSubmitting: string) => void;

  mergedGift?: GiftEntity;
  setMergedGift: (mergedGift: GiftEntity) => void;

  isMergingGifts?: boolean;
  setIsMergingGifts: (isMergingGifts: boolean) => void;

  selectedCards?: GiftEntity[];
  setSelectedCards: (selectedCards: GiftEntity[]) => void;

  closable?: boolean;
  setClosable: (closable: boolean) => void;

  customerId?: string;
  setCustomerId: (customerId: string) => void;

  fixedDenominations?: number[];
  setFixedDenominations: (fixedDenominations: number[]) => void;

  rangeDenomination?: number[];
  setRangeDenomination: (rangeDenomination: number[]) => void;

  giftValueError?: GiftValueValidationError;
  setGiftValueError: (giftValueError: GiftValueValidationError) => void;

  consolidatedCardAmount?: number;
  setConsolidatedCardAmount: (consolidatedCardAmount: number) => void;

  merchantToSwap?: GiftRedeemAs;
  setMerchantToSwap: (merchantToSwap: GiftRedeemAs) => void;

  selectedSwapBrandCategory?: BrandCategory;
  setSelectedSwapBrandCategory: (brandCategory: BrandCategory) => void;

  selectedSwapBrandSubcategories?: BrandCategory[];
  toggleSelectedSwapBrandSubcategories: (brandSubcategory: BrandCategory) => void;
  clearSelectedSwapBrandSubcategories: () => void;

  swapQuery?: string;
  setSwapQuery: (swapQuery: string) => void;

  dontShowMarkAsUsedConfirmationAgain?: boolean;
  setDontShowMarkAsUsedConfirmationAgain: (dontShowMarkAsUsedConfirmationAgain: boolean) => void;
  shippingMethod?: RedeemedGiftShippingMethod;
  setShippingMethod: (shippingMethod: RedeemedGiftShippingMethod) => void;

  selectedShippingMethod?: ShippingMethod;
  setSelectedShippingMethod: (selectedShippingMethod: ShippingMethod) => void;
};

export const walletStore: StateCreator<WalletRedemptionKeyStore, [], [], WalletRedemptionKeyStore> = (set, get) => ({
  closable: true,
  redeemSubmitting: false,
  selectedCards: [],
  setRedeemAsProduct: (redeemAsProduct?: Product) => set(() => ({ redeemAsProduct })),
  setRedeemAsVariation: (redeemAsVariation?: ProductVariation) => set(() => ({ redeemAsVariation })),
  setRedeemSubmitting: (redeemSubmitting: boolean) => set(() => ({ redeemSubmitting })),
  setRedemptionType: (redemptionType: RedemptionType) => set(() => ({ redemptionType })),
  setClosable: (closable: boolean) => set(() => ({ closable })),
  setSelectedCards: (selectedCards: GiftEntity[]) => set(() => ({ selectedCards })),
  setCustomerId: (customerId: string) => set(() => ({ customerId })),
  setMergedGift: (mergedGift: GiftEntity) => set(() => ({ mergedGift })),
  setIsMergingGifts: (isMergingGifts: boolean) => set(() => ({ isMergingGifts })),

  setFixedDenominations: (fixedDenominations: number[]) => set(() => ({ fixedDenominations })),
  setRangeDenomination: (rangeDenomination: number[]) => set(() => ({ rangeDenomination })),
  setGiftValueError: (giftValueError: GiftValueValidationError) => set(() => ({ giftValueError })),
  setConsolidatedCardAmount: (consolidatedCardAmount: number) => set(() => ({ consolidatedCardAmount })),
  setMerchantToSwap: (merchantToSwap: GiftRedeemAs) => set(() => ({ merchantToSwap })),

  setSelectedSwapBrandCategory: (selectedSwapBrandCategory?: BrandCategory) =>
    set(() => ({ selectedSwapBrandCategory, swapQuery: undefined, selectedSwapBrandSubcategories: undefined })),
  toggleSelectedSwapBrandSubcategories: (toggleSwapBrandCategory?: BrandCategory) => {
    const { selectedSwapBrandSubcategories } = get();

    if (selectedSwapBrandSubcategories?.includes(toggleSwapBrandCategory))
      return set(() => ({
        selectedSwapBrandSubcategories: (selectedSwapBrandSubcategories ?? []).filter(
          _subcategory => toggleSwapBrandCategory.id !== _subcategory.id
        ),
        swapQuery: undefined,
      }));

    return set(() => ({
      selectedSwapBrandSubcategories: [...(selectedSwapBrandSubcategories ?? []), toggleSwapBrandCategory],
      swapQuery: undefined,
    }));
  },

  clearSelectedSwapBrandSubcategories: () => set(() => ({ selectedSwapBrandSubcategories: undefined })),

  setSwapQuery: (swapQuery: string) => set(() => ({ swapQuery })),

  setDontShowMarkAsUsedConfirmationAgain: (dontShowMarkAsUsedConfirmationAgain: boolean) =>
    set(() => ({ dontShowMarkAsUsedConfirmationAgain })),
  setShippingMethod: (shippingMethod: RedeemedGiftShippingMethod) => set(() => ({ shippingMethod })),
  setSelectedShippingMethod: (selectedShippingMethod: ShippingMethod) => set(() => ({ selectedShippingMethod })),
});

export const useWalletRedemptionStore = create<WalletRedemptionKeyStore>()(
  persist(walletStore, {
    name: 'wallet-redemption-store',
    getStorage: () => localStorage,
  })
);
