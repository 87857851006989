import type { AxiosRequestConfig } from 'axios';
import type { PlatformManagementApiResponse, StrapiPagedDataSource, StrapiSingleDataSource } from '../types/Response';
import BaseResource from './base';
import type {
  GiftRedeemPayload,
  WalletEntity,
  WalletMarkGiftsAsBeingActivatedPayload,
  WalletMergeGiftsForCardLinkingPayload,
} from '../types/Wallet';
import { giftWalletEndPoints } from './endPoints';
import { type GiftEntity } from '../types/Gift';

const wallet = giftWalletEndPoints.wallet;

type Entity = WalletEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<SingleDataType>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;

export class WalletResource extends BaseResource {
  activateByCustomerId(id: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      wallet.activateByCustomerId(id),
      undefined,
      options
    );
  }

  deactivateByCustomerId(id: string, markAsUsed: boolean = false, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      wallet.deactivateByCustomerId(id, markAsUsed),
      undefined,
      options
    );
  }

  redeem(customerId: string, payload: GiftRedeemPayload, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<GiftEntity>, PlatformManagementApiResponse<GiftEntity>>(
      wallet.redeem(customerId),
      payload,
      options
    );
  }

  updateByCustomerId(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
    return this.client.put<SingleDataType, SingleResponseType>(wallet.updateByCustomerId(id), payload, options);
  }

  async getByUuid(id: string, options?: AxiosRequestConfig) {
    return await this.client.get<SingleDataType, PaginatedResponseType>(wallet.getBy(), {
      ...options,
      ...{
        params: {
          filters: {
            uuid: {
              $eq: id,
            },
          },
          ...options?.params,
        },
      },
    });
  }

  async getByCustomerId(id: string, options?: AxiosRequestConfig) {
    return await this.client.get<SingleDataType, PlatformManagementApiResponse<WalletEntity>>(
      wallet.getByCustomerId(id),
      options
    );
  }

  async getSupporters(customerId: string, giftTypeId?: string, options?: AxiosRequestConfig) {
    return await this.client.get<SingleDataType, PlatformManagementApiResponse<WalletEntity>>(
      wallet.getSupporters(customerId, giftTypeId),
      options
    );
  }

  getRemainingRedemptionBalance(customerId: string, options?: AxiosRequestConfig) {
    return this.client.get<number, PlatformManagementApiResponse<number>>(
      wallet.getRemainingRedemptionBalance(customerId),
      options
    );
  }

  createOrderGifts(orderId: string, options?: AxiosRequestConfig) {
    return this.client.post<boolean, PlatformManagementApiResponse<boolean>>(
      wallet.createOrderGifts(orderId),
      undefined,
      options
    );
  }

  mergeGiftsForCardLinking(
    customerId: string,
    payload: WalletMergeGiftsForCardLinkingPayload,
    options?: AxiosRequestConfig
  ) {
    return this.client.post<StrapiSingleDataSource<GiftEntity>, PlatformManagementApiResponse<GiftEntity>>(
      wallet.mergeGiftsForCardLinking(customerId),
      payload,
      options
    );
  }

  markGiftsAsBeingActivated(
    customerId: string,
    payload: WalletMarkGiftsAsBeingActivatedPayload,
    options?: AxiosRequestConfig
  ) {
    return this.client.post<StrapiSingleDataSource<GiftEntity>, PlatformManagementApiResponse<GiftEntity>>(
      wallet.markGiftsAsBeingActivated(customerId),
      payload,
      options
    );
  }

  startCardActivateAttempt(customerId: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<GiftEntity>, PlatformManagementApiResponse<GiftEntity>>(
      wallet.startCardActivateAttempt(customerId),
      undefined,
      options
    );
  }

  getPendingCardActivationStatus(customerId: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<GiftEntity>, PlatformManagementApiResponse<GiftEntity>>(
      wallet.getPendingCardActivationStatus(customerId),
      undefined,
      options
    );
  }

  placeOrder(customerId: string, payload: unknown, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<unknown>, PlatformManagementApiResponse<unknown>>(
      wallet.placeOrder(),
      payload,
      options
    );
  }

  // getByCustomerIdRedeemed(id: string, options?: AxiosRequestConfig) {
  //   return this.client.get<PaginatedDataType, PaginatedResponseType>(wallet.list(), options);
  // }
}
