import type { AxiosRequestConfig } from 'axios';
import type {
  PlatformManagementApiResponse,
  StrapiPagedDataSource,
  StrapiSingleDataSource,
} from '../../types/Response';
import BaseResource from '../base';
import type { WalletEntity } from '../../types/Wallet';
import { giftWalletAdminEndPoints } from './endPoints';
const wallet = giftWalletAdminEndPoints.wallet;

export class AdminWalletResource extends BaseResource {
  list(options?: AxiosRequestConfig) {
    return this.client.get<
      StrapiPagedDataSource<WalletEntity>,
      PlatformManagementApiResponse<StrapiPagedDataSource<WalletEntity>>
    >(wallet.list(), options);
  }

  get(id: string, options?: AxiosRequestConfig) {
    return this.client.get<
      StrapiSingleDataSource<WalletEntity>,
      PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>
    >(wallet.get(id), options);
  }

  create(payload: WalletEntity, options?: AxiosRequestConfig) {
    return this.client.post<
      StrapiSingleDataSource<WalletEntity>,
      PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>
    >(wallet.create(), payload, options);
  }

  update(id: string, payload: Partial<WalletEntity>, options?: AxiosRequestConfig) {
    return this.client.put<
      StrapiSingleDataSource<WalletEntity>,
      PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>
    >(wallet.update(id), payload, options);
  }

  delete(id: string, options?: AxiosRequestConfig) {
    return this.client.delete<StrapiSingleDataSource<WalletEntity>, boolean>(wallet.delete(id), options);
  }

  activateByCustomerId(id: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<WalletEntity>, boolean>(wallet.activateByCustomerId(id), options);
  }

  deactivateByCustomerId(id: string, markAsUsed: boolean, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<WalletEntity>, boolean>(
      wallet.deactivateByCustomerId(id, markAsUsed),
      options
    );
  }

  updateName(id: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<WalletEntity>, boolean>(wallet.updateName(id), options);
  }

  updateCounters(id: string, options?: AxiosRequestConfig) {
    return this.client.post<StrapiSingleDataSource<WalletEntity>, boolean>(wallet.updateCounters(id), options);
  }

  updateByCustomerId(id: string, payload: Partial<WalletEntity>, options?: AxiosRequestConfig) {
    return this.client.put<
      StrapiSingleDataSource<WalletEntity>,
      PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>
    >(wallet.update(id), payload, options);
  }

  /**
   * Strapi get by uuid
   */
  async getByUuid(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<
      StrapiSingleDataSource<WalletEntity>,
      PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>
    >(wallet.list(), {
      ...options,
      ...{
        params: {
          filters: {
            uuid: {
              $eq: id,
            },
          },
          ...options?.params,
        },
      },
    });
    // @ts-ignore
    return response?.data?.data?.[0];
  }

  getByCustomerId(id: string, options?: AxiosRequestConfig) {
    return this.client.get<
      StrapiPagedDataSource<WalletEntity>,
      PlatformManagementApiResponse<StrapiPagedDataSource<WalletEntity>>
    >(wallet.getByCustomerId(id), options);
  }

  getByCustomerIdRedeemed(id: string, options?: AxiosRequestConfig) {
    return this.client.get<
      StrapiPagedDataSource<WalletEntity>,
      PlatformManagementApiResponse<StrapiPagedDataSource<WalletEntity>>
    >(wallet.list(), options);
  }

  cancelMergedGiftForCardLinking(customerId: string, giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<null, boolean>(wallet.cancelMergedGiftForCardLinking(customerId, giftUuid), options);
  }

  securityLock(customerId: string, options?: AxiosRequestConfig) {
    return this.client.post<null, boolean>(wallet.securityLock(customerId), options);
  }

  securityUnlock(customerId: string, options?: AxiosRequestConfig) {
    return this.client.post<null, boolean>(wallet.securityUnlock(customerId), options);
  }
}
