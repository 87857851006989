import { stripeTokenLocalStorage } from '@/constants';
import { navigateTo } from '@/utils/navigateTo';
import { Analytics } from '@gik/analytics';
import type { IAnalyticsProps } from '@gik/analytics/utils/Analytics';
import { fireEvent } from '@gik/analytics/utils/Analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import type { ICalendarEventClaimCreatePayload } from '@gik/api/calendar/calendarClaims';
import { useInkind } from '@gik/api/inkinds/inkind';
import { addBillingAddress, updateBillingAddress } from '@gik/api/users/billingAddresses';
import { useUser } from '@gik/api/users/user';
import GiftCardServiceForm from '@gik/calendar/components/ClaimEvent/services/gift-card/GiftCardServiceForm';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { createClaimRequestFromClaimEvent } from '@gik/calendar/utils/CalendarClaimUtils';
import {
  calendarEventClaimFormContentId,
  checkoutFormContentId,
  scrollToPayment,
} from '@gik/calendar/utils/CalendarModals';
import type { ClaimConflictErrorDetails, ClaimConflictResolve, OrderStatus } from '@gik/checkout/api';
import { cancelPaymentIntent } from '@gik/checkout/api';
import { useProducts } from '@gik/checkout/api';
import { AddFromWishtlistSection } from '@gik/checkout/components/AddFromWishlist/AddFromWishlistSection';
import type { CardCarrierFormValues } from '@gik/checkout/components/CardCarrier/CardCarrierForm';
import { useCheckoutFormModalStore } from '@gik/checkout/store/CheckoutFormModalStore';
import { useCheckoutStore } from '@gik/checkout/store/CheckoutStore';
import type { BillingDetails, CheckoutFormInitiatedOnType, PaymentConfirmationValues } from '@gik/checkout/types';
import type { PaymentMethod } from '@gik/checkout/utils/productUtils';
import GreetinCardIcon from '@gik/core/assets/img/icons/greeting-card.svg';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { BillingAddress } from '@gik/core/models/gik/BillingAddress';
import type { CartItem, ShippingDetails } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import { useIdempotencyKeysStore } from '@gik/core/store/IdempotencyKeysStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { sanitizeCreditCardDisplayString } from '@gik/core/utils/Sanitize';
import sleep from '@gik/core/utils/sleep';
import { storage } from '@gik/core/utils/Storage';
import { timers } from '@gik/core/utils/TimerUtil';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { fireCompletedPurchaseLocalStorageKey } from '@gik/inkind-page/components/InkindPageViewRecorder/useCompletedPurchaseForPageAnalytics';
import WishlistIcon from '@gik/shop/assets/wishlist.svg';
import { Button } from '@gik/ui/Button';
import type { IButtonProps } from '@gik/ui/Button/ButtonProps';
import { LoadingPopup } from '@gik/ui/gik/LoadingPopup/LoadingPopup';
import { DeterminationType } from '@gik/ui/LoadingLinear';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { StepItem, StepsRefProps } from '@gik/ui/Steps';
import { Steps } from '@gik/ui/Steps';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import CalendarIcon from '@heroicons/react/outline/CalendarIcon';
import CheckCircleIcon from '@heroicons/react/outline/CheckCircleIcon';
import ClipboardCheckIcon from '@heroicons/react/outline/ClipboardCheckIcon';
import CreditCardIcon from '@heroicons/react/outline/CreditCardIcon';
import InformationCircleIcon from '@heroicons/react/outline/InformationCircleIcon';
import { ArrowDownIcon } from '@heroicons/react/solid';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import PencilAltIcon from '@heroicons/react/solid/PencilAltIcon';
import { useStripe } from '@stripe/react-stripe-js';
import type { Source, StripeCardElement } from '@stripe/stripe-js';
import type { FormApi } from 'final-form';
import React, { useState } from 'react';
import { scroller } from 'react-scroll';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useLatest from 'react-use/lib/useLatest';
import { v4 as uuidv4 } from 'uuid';
import { AddFromCalendarSection } from '../AddFromCalendar/AddFromCalendarSection';
import type { BillingFormValues } from '../BillingForm/BillingForm';
import { BillingForm, createStripeSource } from '../BillingForm/BillingForm';
import type { GreetingCardCarrierEditorRefProps } from '../CardCarrier/CardCarrierEditor';
import { CardCarrierEditor } from '../CardCarrier/CardCarrierEditor';
import { CheckoutPromoCode } from '../CheckoutPromoCode/CheckoutPromoCode';
import { ClaimConflictsResolution } from '../ClaimConflictsResolution/ClaimConflictsResolution';
import { DigitalShippingForm } from '../DigitalShippingForm/DigitalShippingForm';
import type { GiftCardEditorFormValues } from '../GiftCard/GiftCardEditor';
import { GiftCardEditor } from '../GiftCard/GiftCardEditor';
import { OrderForm } from '../OrderForm/OrderForm';
import type { OrderSummaryValues } from '../OrderSummary/OrderSummary';
import { OrderSummary } from '../OrderSummary/OrderSummary';
import { ShippingForm } from '../ShippingForm/ShippingForm';
import type { ICheckoutFormModalContext } from './CheckoutFormModalContext';
import { CheckoutFormModalContext } from './CheckoutFormModalContext';
import { Separator } from '@gik/ui/Separator';
import { RecipientInfoIcon } from '@gik/ui/SvgIcon/GikIcons/RecipientInfo';
import { isEmpty } from '@gik/core/utils/validator';

export const calendarFormId = 'calendarForm';
export const claimFormId = 'claimForm';
export const wishlistFormId = 'wishlistForm';
export const summaryFormId = 'summaryForm';
export const cardCarrierFormId = 'cardCarrierForm';
export const giftCardEditorFormId = 'giftcardEditorForm';
export const billingFormFormId = 'billingForm';
export const shippingFormId = 'shippingForm';
export const orderFormId = 'orderForm';

type CheckoutFormIds =
  | typeof orderFormId
  | 'billingForm'
  | 'shippingForm'
  | 'claimForm'
  | typeof claimFormId
  | typeof wishlistFormId
  | typeof calendarFormId
  | typeof summaryFormId
  | typeof cardCarrierFormId
  | typeof giftCardEditorFormId;

export type CheckoutFormStepId =
  | 'products'
  | 'billing'
  | 'payment'
  | 'shipping'
  | 'greetingcard'
  | 'giftcard'
  | 'wishlist'
  | 'claim'
  | 'calendar';

export type CheckoutFormProps = {
  /**
   * Render Shipping information in order summary
   */
  shipping?: boolean;

  /**
   * Allow user to enter shipping details (disabled for purchasing for inkind pages)
   */
  shippingEditable?: boolean;

  /**
   * Allow a shipping option to be selected (standard, fast delivery)
   */
  hasShipping?: boolean;

  hasBilling?: boolean;

  /**
   * Allow PG shipping option to be selected
   */
  hasPGShipping?: boolean;

  /**
   * Skip the products step when first opened (product/wishlist/giftbox page)
   */
  skipProducts?: boolean;

  /**
   * Hide the product step
   */
  hideProductsStep?: boolean;
  paymentConfirmationValues?: PaymentConfirmationValues;
  navigateToInkindPageAfterPurchase?: boolean;

  buttonsPortal?: HTMLElement;
  stepsPortal?: HTMLElement;
  stepsNavPortal?: HTMLElement;

  initialData?: CheckoutFormValues;

  inkindRouteId?: string;
  initiatedOn: CheckoutFormInitiatedOnType;

  errorPage?: React.ReactElement;

  /**
   * An error message to be displayed below the form.
   * Normally this would be used to display the error response from the backend.
   */
  errorMessage?: string;

  showPromoInput?: boolean;
  selectFromWishlist?: boolean;
  selectFromCalendar?: boolean;

  renderProductListOnAllSteps?: boolean;

  id?: string;
  currentCart?: CartItem[];

  recipientName?: string;
  customMessage?: string;
  expiryMonth?: string;
  cardImage?: string;
  faceplate?: PerfectGiftFaceplate;
  anonymousOverride?: boolean;
  privateClaim?: boolean;

  hasCardCarrier?: boolean;
  hasPhysicalCard?: boolean;
  editMode?: boolean;
  initialCreateClaimRequest?: ICalendarEventClaimCreatePayload;
  initialClaimEntry?: ICalendarEntry;
  addToOrder?: boolean;
  buyForSomeoneElse?: boolean;
  mainClaimEvent?: ICalendarEvent;
  claimErrors?: ClaimConflictErrorDetails[];

  onAddtoOrder?(data: CheckoutFormValues, entry?: ICalendarEntry, event?: ICalendarEvent): void;
  onSuccess?(): void;
  onSuccessfulPurchase?(promoCodeId?: string): void;

  onSubmit?(
    values: CheckoutFormPayload,
    updateCheckoutStatusText: (orderStatus: OrderStatus) => void
  ): boolean | void | Promise<boolean> | Promise<void> | Promise<Error>;

  onChange?(values: CheckoutFormValues): void;

  onGotoStep?(stepId: string): void;
  onOpenTermsOfService?(): void;
  onOpenPrivacyPolicy?(): void;

  onBillingInfoChanged?(): void;
  scrollToTop?(): void;
  setDialogFooterClass?(className: string): void;
  setOnDialogBack?(fn: () => void): void;
  setClosable?(closable: boolean): void;
  onStepChange?: (step: CheckoutFormStepId) => void;
  onStepProgression?: (step: CheckoutFormStepId, index: number) => void;
  onUpdateClaimConflicts?: (suggestions: ClaimConflictResolve[]) => void;
  onSuggestedResolvesChange?: (suggestions: ClaimConflictResolve[]) => void;
};

type CheckoutPayloadCarrierValues = Omit<CardCarrierFormValues, 'selectedCarrierDesign'> & {
  selectedCarrierDesign?: string;
};

export interface CheckoutFormValues {
  cart?: CartItem[];
  carrier?: CardCarrierFormValues;
  billing?: BillingFormValues;
  shipping?: ShippingDetails;
  summary?: OrderSummaryValues;
}

export interface CheckoutFormPayload {
  stripeToken: string;
  timezoneOffset: number;
  inkindRouteId?: string;
  messageToRecipient?: string;
  buyForSomeoneElse?: boolean;
  tip?: number;
  total?: number; // used for verification only
  products?: CartItem[];
  billing?: BillingDetails;
  shipping?: ShippingDetails;
  carrier?: CheckoutPayloadCarrierValues;
  summary?: OrderSummaryValues;
  // fields for events (not necessarily claiming if someone already claimed that day)
  eventType?: string;
  eventDateTime?: string;
  // fields for claiming as part of the order
  createClaimRequest?: ICalendarEventClaimCreatePayload;
  subscribeToNewsletter?: boolean;
  // frontend-generated transaction ID passed to backend to prevent double purchases
  frontendTransactionId: string;
  recipientName?: string;
  customMessage?: string;
  deliveryMethod?: string;
  anonymous?: boolean;
  privateClaim?: boolean;
  ignoreClaimConflict?: boolean;
  paymentMethod?: PaymentMethod;
}

export interface CheckoutFormRefProps {
  gotoStep: (step: CheckoutFormStepId) => void;
}

export interface CheckoutFormRefProps {
  gotoStep: (step: CheckoutFormStepId) => void;
}

export async function tryToNavigateToPaymentStep(currentStep: React.MutableRefObject<CheckoutFormStepId>) {
  const maxAttempts = 5;
  let currentAttempt = 0;
  let stepsRef: StepsRefProps<string>;

  while (!stepsRef && currentAttempt < maxAttempts) {
    stepsRef = useCheckoutFormModalStore.getState().stepsRef?.current;
    currentAttempt++;
    // go to the payment step if needed
    if (currentStep.current !== 'payment') {
      stepsRef?.gotoStep(1);
      await sleep(1000);
    }
  }

  return stepsRef !== null;
}

function CheckoutFormComp({
  initialData,
  hideProductsStep: hideProducts,
  shipping,
  shippingEditable,
  hasShipping,
  hasPGShipping,
  buttonsPortal,
  stepsPortal,
  stepsNavPortal,
  errorMessage,
  initiatedOn,
  customMessage,
  expiryMonth,
  cardImage,
  faceplate,
  inkindRouteId,
  errorPage,
  showPromoInput,
  paymentConfirmationValues,
  navigateToInkindPageAfterPurchase,
  hasCardCarrier,
  hasBilling = true,
  hasPhysicalCard,
  selectFromWishlist,
  selectFromCalendar,
  editMode,
  addToOrder,
  currentCart,
  buyForSomeoneElse,
  id = 'checkoutform',
  anonymousOverride,
  initialCreateClaimRequest,
  initialClaimEntry,
  mainClaimEvent,
  // claimErrors,
  privateClaim,
  scrollToTop,
  setDialogFooterClass,
  onAddtoOrder,
  onSuccessfulPurchase,
  onSuccess,
  onSubmit,
  onChange,
  onGotoStep,
  onOpenTermsOfService,
  onOpenPrivacyPolicy,
  onBillingInfoChanged,
  setClosable,
  setOnDialogBack,
  onStepChange,
  onStepProgression,
  onUpdateClaimConflicts,
  onSuggestedResolvesChange,
  claimErrors: initialClaimErrors,
}: CheckoutFormProps): React.ReactElement {
  // GIK-6438 express checkout deprecates billing step
  hasBilling = false;

  const [source, setSource] = React.useState(undefined);
  const [stripeError, setStripeError] = React.useState(null);
  const [cardElement, setCardElement] = React.useState<StripeCardElement>();
  const [selectedProductId, setSelectedProductId] = React.useState<number>();
  const [_hasCardCarrier, _setHasCardCarrier] = React.useState<boolean>(hasCardCarrier);
  const [createClaimRequest, setCreateClaimRequest] =
    React.useState<ICalendarEventClaimCreatePayload>(initialCreateClaimRequest);
  const [claimEntry, setClaimEntry] = React.useState<ICalendarEntry>(initialClaimEntry);
  const [formError, setFormError] = React.useState<string>();

  const [selectedGiftCard, setSelectedGiftCard] = React.useState<Product>();
  const [selectedId, setSelectedId] = React.useState<number>();
  const selectedIdLatest = useLatest(selectedId);
  const selectedGiftCardLatest = useLatest(selectedGiftCard);
  const createClaimRequestLatest = useLatest(createClaimRequest);
  const claimEntryLatest = useLatest(claimEntry);
  const [suggestedResolves, setSuggestedResolves] = React.useState<ClaimConflictResolve[]>([]);
  const [orderFormSubmitted, setOrderFormSubmitted] = React.useState<boolean>(false);

  const _privateClaim = useCalendarStore(state => state.privateClaim);

  const { mainCart, setMainCart, anonymous, setAnonymous, setPrivateClaim } =
    React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext);

  const setClaimConflicts = useCalendarStore(state => state.setClaimConflicts);
  const setGoneConflicts = useCalendarStore(state => state.setGoneConflicts);
  const setResolveGiftCardClaimConflictValues = useCalendarStore(state => state.setResolveGiftCardClaimConflictValues);

  useEffectOnce(() => {
    if (
      initiatedOn !== 'calendar' &&
      initiatedOn !== 'wishlist' &&
      initiatedOn !== 'add-from-calendar' &&
      initiatedOn !== 'add-from-wishlist' &&
      initialData?.cart?.length > 0
    ) {
      setAnonymous(initialData?.cart.some(product => product.anonymous));
    }

    setResolveGiftCardClaimConflictValues(null);
    setClaimConflicts(null);
    setGoneConflicts(null);
  });

  const setCartStore = useCheckoutStore(state => state.setCart);
  const setOrderTotal = useCheckoutStore(state => state.setOrderTotal);
  const setOrderTip = useCheckoutStore(state => state.setOrderTip);

  const formId = id;

  React.useEffect(() => {
    if (errorMessage || stripeError?.message) {
      fireEvent(AnalyticsEvents.CheckoutError, {
        errorMessage: errorMessage || stripeError?.message,
        initiatedOn,
        inkindRouteId,
      });
    }
  }, [errorMessage, initiatedOn, inkindRouteId, stripeError]);

  React.useEffect(() => {
    if (errorMessage || stripeError?.message) {
      setCompletePurchaseButtonDisabled(true);
    }
  }, [errorMessage, initiatedOn, inkindRouteId, stripeError]);

  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const handleScrollToPayment = React.useCallback(() => {
    scrollToPayment();
  }, []);

  const handleScrollToBillingForm = React.useCallback(() => {
    const target = 'billing-form-group';
    scroller.scrollTo(target, {
      duration: 300,
      offset: -16,
      smooth: true,
      container:
        document.getElementById(checkoutFormContentId) ?? document.getElementById(calendarEventClaimFormContentId),
    });
  }, []);

  const handleScrollToExpressCheckout = React.useCallback(() => {
    const target = 'express-checkout-form-group';
    scroller.scrollTo(target, {
      duration: 300,
      offset: -16,
      smooth: true,
      container:
        document.getElementById(checkoutFormContentId) ?? document.getElementById(calendarEventClaimFormContentId),
    });
  }, []);

  const billingAddress = user?.billingAddresses?.[0];
  const billingAddressId = billingAddress?.id;

  const stripe = useStripe();

  const cardCarrierEditorRef = React.useRef<GreetingCardCarrierEditorRefProps>();

  const stepsRef = React.useRef<StepsRefProps<StepItem<CheckoutFormStepId>>>();

  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const stepIndexLatest = useLatest(stepIndex);

  // frontend-generated transaction ID passed to backend to prevent double purchases
  const [productType, setProductType] = useState<string>(hasPhysicalCard ? 'physical' : 'digital');
  const { frontendTransactionId, updateFrontendTransactionId } = useIdempotencyKeysStore(state => ({
    frontendTransactionId: state.frontendTransactionId,
    updateFrontendTransactionId: state.updateFrontendTransactionId,
  }));
  const [data, setData] = React.useState<CheckoutFormValues>();
  const latestData = useLatest(data);
  const [claimErrors, setClaimErrors] = React.useState<ClaimConflictErrorDetails[]>(initialClaimErrors);

  const { setIsSubmitted, isSubmitted, setIsSubmitting, isSubmitting, orderStatus, setOrderStatus } =
    React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext);

  const [isOrderFormLoading, setIsOrderFormLoading] = React.useState(true);
  const [isOrderSummaryFormLoading, setIsOrderSummaryFormLoading] = React.useState(true);

  const { data: inkindPage, error: inkindPageError } = useInkind(inkindRouteId);

  const productIds = React.useMemo(() => {
    return selectedProductId
      ? [selectedProductId]
      : (mainCart?.length ? mainCart : initialData.cart)?.map(order => order.productId);
  }, [initialData, mainCart, selectedProductId]);

  const { data: products, error: productError } = useProducts({ productIds });

  const product = products?.[0];
  const cartItem = mainCart?.[0];

  React.useEffect(() => {
    if (mainCart || source) {
      setCompletePurchaseButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCart, source]);

  const handleStripeFieldChange = React.useCallback(() => {
    if (mainCart) {
      updateFrontendTransactionId();
      if (data) {
        data.billing.stripeToken = null;
        setStripeToken(null);
      }
    }
  }, [data, mainCart, updateFrontendTransactionId]);

  React.useEffect(() => {
    if (mainCart) {
      updateFrontendTransactionId();
      if (data) {
        data.billing.stripeToken = null;
        setStripeToken(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCart]);

  React.useEffect(() => {
    useCheckoutFormModalStore.getState().stepsRef = stepsRef;
  }, [stepsRef]);

  React.useEffect(() => {
    setClaimErrors(initialClaimErrors);
  }, [initialClaimErrors]);

  const unsupportedPageNoWallet = React.useMemo(
    () =>
      inkindPage &&
      products &&
      !inkindPage.isWalletFeatureActive &&
      products.some(product => product.checkoutType === CheckoutType.GiftyaPlatform),
    [inkindPage, products]
  );

  const steps: StepItem<CheckoutFormStepId>[] = React.useMemo(() => {
    const steps = [];

    if (selectFromWishlist) {
      steps.push({
        id: 'wishlist',
        name: 'WISHLIST',
        prepend: <WishlistIcon />,
        tooltip: 'WISHLIST',
        disabled: editMode,
      });
    }

    if (selectFromCalendar) {
      steps.push({
        id: 'calendar',
        name: 'CALENDAR',
        prepend: <SvgIcon Icon={CalendarIcon} />,
        tooltip: 'CALENDAR',
        disabled: editMode,
      });
      steps.push({
        id: 'claim',
        name: 'CLAIM',
        prepend: <SvgIcon Icon={CheckCircleIcon} />,
        tooltip: 'CLAIM',
      });
    }

    if (!hideProducts || unsupportedPageNoWallet) {
      steps.push({
        id: 'products',
        name: 'INFO',
        prepend: <SvgIcon Icon={InformationCircleIcon} />,
        tooltip: 'ORDER INFO',
        disabled: claimErrors || (!addToOrder && !cartItem),
      });
    }

    if (productType === 'physical') {
      steps.push({
        id: 'greetingcard',
        name: 'GREETING',
        prepend: <GreetinCardIcon />,
        tooltip: 'CUSTOMIZE GREETING',
        disabled: !cartItem,
      });

      steps.push({
        id: 'giftcard',
        name: 'GIFT CARD',
        prepend: <PencilAltIcon />,
        tooltip: 'CUSTOMIZE GIFT CARD',
        disabled: !cartItem,
      });
    }

    if (shippingEditable || !inkindRouteId) {
      steps.push({
        id: 'shipping',
        name: 'RECIPIENT',
        prepend: <SvgIcon Icon={RecipientInfoIcon} />,
        tooltip: 'RECIPIENT INFO',
      });
    }

    // if (hasCardCarrier || cartItem?.productType === 'physical') {
    //   steps.push({
    //     id: 'card-carrier',
    //     name: 'CARRIER',
    //     prepend: <SvgIcon Icon={DocumentTextIcon} />,
    //     tooltip: 'CARRIER INFO',
    //   });
    // }

    if (hasBilling) {
      steps.push({
        id: 'billing',
        name: 'BILLING',
        prepend: <SvgIcon Icon={CreditCardIcon} />,
        tooltip: 'BILLING INFO',
        disabled: !!claimErrors,
      });
    }

    // only add the payment step if this is a normal checkout not meant to add products to another order
    if (!addToOrder) {
      steps.push({
        id: 'payment',
        name: 'CONFIRM & PAY',
        prepend: <SvgIcon Icon={ClipboardCheckIcon} />,
        tooltip: 'COMPLETE PURCHASE',
      });
    }

    return steps;
  }, [
    selectFromWishlist,
    selectFromCalendar,
    hideProducts,
    unsupportedPageNoWallet,
    productType,
    shippingEditable,
    inkindRouteId,
    hasBilling,
    addToOrder,
    editMode,
    claimErrors,
    cartItem,
  ]);

  const stepsLatest = useLatest(steps);

  const stepItem = React.useMemo(() => steps[stepIndex], [stepIndex, steps]);
  const currentStepId = React.useMemo<CheckoutFormStepId>(() => stepItem?.id, [stepItem]);

  const setStep = React.useCallback(
    (stepId: CheckoutFormStepId) => {
      onStepChange?.(stepId);
      const newStepIndex = steps.findIndex(step => step.id === stepId);
      setStepIndex(newStepIndex);
    },
    [onStepChange, steps]
  );

  const fireStepAnalytics = React.useCallback(
    (stepId: CheckoutFormStepId) => {
      const startTime = new Date();

      const commonAnalyticProps: IAnalyticsProps = {
        productNames: mainCart?.map(item => item.name).join(','),
        productIds: mainCart?.map(item => item.productId).join(','),
        productCheckoutTypes: mainCart?.map(item => item.checkoutType).join(','),
        userId: userId,
        initiatedOn,
        inkindRouteId,
      };

      let eventName:
        | AnalyticsEvents.LoadCardCarrierCheckout
        | AnalyticsEvents.LoadGiftCardCheckout
        | AnalyticsEvents.LoadShippingCheckout
        | AnalyticsEvents.AddFromWishlistStep
        | AnalyticsEvents.AddFromCalendarStep
        | AnalyticsEvents.AddFromCalendarGiftCard
        | AnalyticsEvents.AddFromCalendarCardCarrier
        | AnalyticsEvents.AddFromCalendarClaimStep
        | AnalyticsEvents.LoadFinishCheckout;
      switch (stepId) {
        case 'wishlist':
          eventName = AnalyticsEvents.AddFromWishlistStep;
          break;
        case 'calendar':
          eventName = AnalyticsEvents.AddFromCalendarStep;
          break;
        case 'claim':
          eventName = AnalyticsEvents.AddFromCalendarClaimStep;
          break;
        case 'greetingcard':
          eventName = addToOrder ? AnalyticsEvents.AddFromCalendarCardCarrier : AnalyticsEvents.LoadCardCarrierCheckout;
          break;
        case 'giftcard':
          eventName = addToOrder ? AnalyticsEvents.AddFromCalendarGiftCard : AnalyticsEvents.LoadGiftCardCheckout;
          break;
        case 'shipping':
          eventName = AnalyticsEvents.LoadShippingCheckout;
          break;
        case 'payment':
          eventName = AnalyticsEvents.LoadFinishCheckout;

          logger.time(timers.billingFormStripeToken, 'stripe token to LoadFinishCheckout took');

          break;

        case 'products':
        case 'billing':
          break;

        default:
          logger.error('Checkout Unrecognized step:', { stepId });
      }

      Analytics.fireEvent(eventName, {
        ...commonAnalyticProps,
      });
    },
    [mainCart, userId, initiatedOn, inkindRouteId, addToOrder]
  );

  const gotoStep = React.useCallback(
    (stepId: CheckoutFormStepId) => {
      fireStepAnalytics(stepId);
      setStep(stepId);
      onGotoStep?.(stepId);
      scrollToTop?.();
    },
    [fireStepAnalytics, setStep, onGotoStep, scrollToTop]
  );

  const handleClickSteps = React.useCallback(
    (stepItem: StepItem<CheckoutFormStepId>) => {
      gotoStep(stepItem?.id);
    },
    [gotoStep]
  );

  const gotoNextStep = React.useCallback(() => {
    const stepIndex = stepIndexLatest.current;
    if (stepIndex < steps.length) {
      gotoStep(stepsLatest.current[stepIndex + 1].id);
    }
  }, [gotoStep, stepIndexLatest, steps.length, stepsLatest]);

  const gotoPrevStep = React.useCallback(() => {
    const stepIndex = stepIndexLatest.current;
    if (stepIndex > 0) {
      gotoStep(stepsLatest.current[stepIndex - 1].id);
    }
  }, [gotoStep, stepIndexLatest, stepsLatest]);

  const gotoStepIndex = React.useCallback(
    (index: number) => {
      gotoStep(stepsLatest.current[index].id);
    },
    [gotoStep, stepsLatest]
  );

  const handleNext = React.useCallback(
    (id: CheckoutFormIds) => {
      if (isSubmitting) return;

      setIsSubmitting(true);

      if (id === wishlistFormId || id === calendarFormId || id === claimFormId) {
        const prodId = selectedGiftCardLatest.current ? selectedGiftCardLatest.current?.id : selectedIdLatest.current;

        if (!prodId) {
          setFormError('Please select a giftcard');
          UI.notifyError('Please select a giftcard');
          setIsSubmitting(false);

          return;
        }

        setSelectedProductId(prodId);

        setTimeout(() => {
          if (id === claimFormId) {
            Analytics.fireEvent(AnalyticsEvents.AddFromCalendarBuyClick, {
              inkindRouteId,
            });
          }

          if (addToOrder) {
            setIsOrderFormLoading(false);
          }

          gotoNextStep();
          setIsSubmitting(false);
        }, timeoutDefaultValue);
      } else {
        let formEl = document.getElementById(formId + '_' + id);
        if (!formEl) formEl = document.getElementById(id);
        if (formEl) formEl.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    },
    [
      addToOrder,
      formId,
      gotoNextStep,
      inkindRouteId,
      isSubmitting,
      selectedGiftCardLatest,
      selectedIdLatest,
      setIsSubmitting,
    ]
  );

  const handleStepProgressionFromComponent = React.useCallback(
    (step: StepItem<CheckoutFormStepId>, index: number) => {
      if (step) onStepProgression?.(step.id, index);
    },
    [onStepProgression]
  );

  const handleStepProgression = React.useCallback(
    (newData: CheckoutFormValues, nextStep: boolean = true) => {
      try {
        setData(newData);
        onChange?.(newData);
        if (nextStep) {
          gotoNextStep();
          setIsSubmitting(false);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [gotoNextStep, onChange, setIsSubmitting]
  );

  const handleSubmitOrder = React.useCallback(
    (values: CartItem[]) => {
      const data = latestData.current;

      const newData = {
        ...data,
        customMessage: values[0]?.customMessage,
      } as CheckoutFormValues;
      let newCart = values;

      if (mainCart?.length) {
        newCart = mainCart.concat([]);
        newCart[0] = values[0];
      }

      newData.cart = newCart;

      // mark cartItems with the claim date
      const cartItem = newData.cart[0];
      cartItem.quantity = 1;

      if (cartItem.createClaimRequest !== null) {
        if (addToOrder) {
          cartItem.createClaimRequest = createClaimRequestLatest.current;
        } else {
          // link the cartItem to the mainClaimEvent passed by CalendarClaimCheckoutForm
          // only do this the first time the order form is submitted
          if (!orderFormSubmitted) {
            cartItem.createClaimRequest = createClaimRequestFromClaimEvent(mainClaimEvent);
            setOrderFormSubmitted(true);
          }
        }
      }

      // handle adding non physical products to the current order
      if (addToOrder && ((products?.[0].isToggleable && productType === 'digital') || !products?.[0].isToggleable)) {
        return onAddtoOrder(newData);
      }

      setMainCart(newData.cart);
      if (!addToOrder) setCartStore(newData.cart);
      handleStepProgression(newData);
    },
    [
      latestData,
      mainCart,
      addToOrder,
      products,
      productType,
      setMainCart,
      setCartStore,
      handleStepProgression,
      createClaimRequestLatest,
      orderFormSubmitted,
      mainClaimEvent,
      onAddtoOrder,
    ]
  );

  const handleOrderOnChange = React.useCallback(
    values => {
      if (currentStepId === 'products') {
        const productType = values['productType-0'];
        setProductType(productType);

        const anon = values['anonymous-0'];

        // const newCart = mainCart.concat([]);
        // newCart[0].anonymous = anon;
        // setMainCart(newCart);
        setAnonymous(anon);
      }
    },
    [currentStepId, setAnonymous]
  );

  const handleSubmitCardCarrier = React.useCallback(
    (values: CardCarrierFormValues) => {
      const newData = {
        ...data,
        carrier: values,
      } as CheckoutFormValues;

      const newCart = mainCart.concat([]);

      // also update the first cart item's carrier data if it is a physical card
      if (!addToOrder && newCart[0]?.productType === 'physical') {
        newCart[0].carrier = values;
      }

      setMainCart(newCart);
      handleStepProgression(newData);
    },
    [addToOrder, data, handleStepProgression, mainCart, setMainCart]
  );

  const handleSubmitGiftCard = React.useCallback(
    (values: GiftCardEditorFormValues) => {
      const newData = {
        ...data,
      } as CheckoutFormValues;

      const newCart = mainCart.concat([]);

      const cartItem = newCart[0];

      if (cartItem) {
        cartItem.nameOnCard = values.nameOnCard;
        cartItem.customMessage = values.customMessage;
        // cartItem.carrier = data.carrier;
      }

      // new cart items should be assigned a new unique id
      if (!cartItem.id) {
        cartItem.id = uuidv4();
      }

      newData.cart = newCart;

      // handle adding physical products to the current order
      if (addToOrder && products?.[0].isToggleable && productType === 'physical') {
        return onAddtoOrder(newData);
      }

      setMainCart(mainCart);
      handleStepProgression(newData);
    },
    [addToOrder, data, handleStepProgression, mainCart, onAddtoOrder, productType, products, setMainCart]
  );

  const handleBeforeSubmitBilling = React.useCallback(() => {
    setClosable?.(false);
  }, [setClosable]);

  const updateBillingAddressRef = React.useRef<() => Promise<void>>(null);
  const handleSubmitBilling = React.useCallback(
    async (values: BillingFormValues) => {
      const changed = JSON.stringify(values) !== JSON.stringify(data.billing);

      const newData = { ...data } as CheckoutFormValues;
      newData.billing = values;

      setClosable?.(false);

      if (values.saveAddress) {
        (() => {
          const billingDetailsFromForm = JSON.parse(JSON.stringify(newData.billing)) as BillingFormValues;
          updateBillingAddressRef.current = async () => {
            logger.info('saving billing address');
            delete billingDetailsFromForm.stripeToken;
            delete billingDetailsFromForm.saveAddress;

            const billingDetails: BillingAddress = billingDetailsFromForm as BillingAddress;

            if (billingAddressId) {
              await updateBillingAddress(userId, billingAddressId, billingDetails);
            } else {
              billingDetails.primary = true;
              await addBillingAddress(userId, billingDetails);
            }

            onBillingInfoChanged?.();
            logger.info('saving billing address saved');
          };
        })();
      } else {
        updateBillingAddressRef.current = null;
      }

      // also store the stripe token in local storage for 15 minutes (but clear it after successful transaction)
      if (stripeTokenLocalStorage) {
        storage.setWithExpiry('stripe-token', values.stripeToken, 15 * 60);
        logger.info('saving stripe token in localstorage', { stripeToken: values.stripeToken });
      }

      setStripeToken(values.stripeToken);

      setOnDialogBack(undefined);
      if (changed) updateFrontendTransactionId();
      setClosable?.(true);
      handleStepProgression(newData, false);
    },
    [
      billingAddressId,
      data,
      handleStepProgression,
      onBillingInfoChanged,
      setClosable,
      setOnDialogBack,
      updateFrontendTransactionId,
      userId,
    ]
  );

  const handleAfterSubmitBilling = React.useCallback((_values: BillingFormValues, _form: FormApi, source: Source) => {
    if (source) {
      if (stripeTokenLocalStorage) storage.setWithExpiry('stripe-token-last-4-digits', source?.card.last4, 60 * 15);

      setSource(source);
    }
  }, []);

  const handleSubmitShipping = React.useCallback(
    (values: ShippingDetails) => {
      const newData = { ...data } as CheckoutFormValues;
      newData.shipping = values;
      handleStepProgression(newData);
    },
    [data, handleStepProgression]
  );

  const updateSummaryData = React.useCallback(
    (values: OrderSummaryValues): CheckoutFormValues => {
      const newData = { ...data } as CheckoutFormValues;
      newData.summary = values;

      return newData;
    },
    [data]
  );

  const [_stripeToken, setStripeToken] = React.useState(null);

  const handleComplete = React.useCallback(
    async (data: CheckoutFormValues, cart?: CartItem[]) => {
      const thisCart = cart ?? mainCart;

      if (thisCart?.length === 0) {
        UI.notifyError('Please add some products to your cart');
        setIsSubmitting(false);
        setIsSubmitted(false);
        return;
      }

      setClosable?.(false);
      setOnDialogBack?.(undefined);

      setIsSubmitted(false);
      setIsSubmitting(true);
      setStripeError(null);

      // clone cartItems and update the price
      const newOrders = thisCart.concat([]).map(item => {
        // remove carrier from line item
        if (item.productType !== 'physical') delete item.carrier;
        return item;
      });

      let stripeToken =
        _stripeToken ??
        data.billing.stripeToken ??
        storage.getWithExpiry('stripe-token') ??
        storage.getWithExpiry('applePayPaymentIntent'); // workaround for: https://wolfellc.atlassian.net/browse/GIK-8894

      // set new stripe token on billing values if we don't have one yet
      if (!stripeToken) {
        const { error, source } = await createStripeSource(data.billing, stripe, cardElement);

        if (error) {
          setStripeError(error);
          setIsSubmitting(false);
          setIsSubmitted(false);
          return;
        }

        // set new stripe token on billing values
        stripeToken = source.id;
      }

      setStripeToken(stripeToken);

      // workaround for: https://wolfellc.atlassian.net/browse/GIK-8759
      const billing: BillingFormValues =
        isEmpty(data.billing.firstName) && isEmpty(data.billing.lastName)
          ? (JSON.parse(storage.getWithExpiry('billingForm')) as BillingFormValues)
          : data.billing;

      // create payload expected by the backend
      const payload: CheckoutFormPayload = {
        stripeToken,
        timezoneOffset: new Date().getTimezoneOffset(),
        messageToRecipient: data.summary.messageToRecipient,
        products: newOrders,
        billing,
        inkindRouteId,
        subscribeToNewsletter: data.summary.subscribeToNewsletter,
        frontendTransactionId,
        anonymous,
        privateClaim: privateClaim || anonymous || newOrders.some(p => p.anonymous),
        paymentMethod: data.billing.paymentMethod,
        tip: data.summary.tipAmount,
        total: data.summary.total,
      };

      if (!payload.total) {
        payload.total = useCheckoutStore.getState().orderTotal;
        payload.tip = useCheckoutStore.getState().orderTip;

        logger.warn('order total workaround for frontend (0) used.', { data, payload });
      } else {
        // FIXME: workaround for bug where order total or tip may be lost after conflict resolution
        setOrderTotal(payload.total);
        setOrderTip(payload.tip);
      }

      // always add shipping data if it's available
      if (data.shipping) {
        payload.shipping = data.shipping;
        if (data.summary.shippingValue) payload.shipping.shippingValue = data.summary.shippingValue.toString(10);
      }

      if (payload.products[0].productType !== 'physical') {
        payload.products[0].customMessage = undefined;
      }

      // if donating, recipient is always GIK
      if (thisCart?.some(cartItem => cartItem.checkoutType === 'donation')) {
        payload.shipping = {
          ...(payload.shipping ?? {}),
          firstName: 'Give InKind',
          email: 'orders@giveinkind.com',
        };
      }

      if (onSubmit) {
        setOrderStatus(null);

        const submitSuccess = await onSubmit(payload, orderStatus => {
          setOrderStatus(orderStatus);
        });

        setClosable(true);

        if (submitSuccess && (submitSuccess as unknown as Error).message !== 'conflicts') {
          // remove the stripe token after submitting the form (to reset the form entirely)
          delete data.billing.stripeToken;
          setStripeToken(null);

          // remove stored message after successful purchase
          logger.info(`Removing stored message to recipient from local storage`);
          storage.remove(`message-to-recipient-${inkindRouteId}`);

          // remove stored stripe token
          if (stripeTokenLocalStorage) {
            logger.info(`Removing stripe token from local storage`);
            storage.remove('stripe-token');
            storage.remove('stripe-token-last-4-digits');
          }

          // For each transaction being done, we should persist the Tip Percentage for 2 hours with a combination of Inkind + Product ID.
          // https://www.notion.so/giveinkind/Improvement-Save-Billing-details-in-checkout-flow-misc-9882cc0b0dcf4136ac7d16c1aa3b5764
          logger.info(`Refreshing tip percentage from local storage for another 2h`);
          storage.setWithExpiry(
            `tip-percentage-${inkindRouteId}-${newOrders[0].productId}`,
            data.summary.tipPercentage.toString(),
            3600 * 2 // expire in 2 hours
          );

          onSuccess?.();
        } else {
          setIsSubmitting(false);
          setIsSubmitted(false);
          setOrderStatus(null);

          return submitSuccess as unknown as Error;
        }
      }

      setIsSubmitting(false);
      setIsSubmitted(true);

      if (updateBillingAddressRef.current) await updateBillingAddressRef.current();
    },
    [
      mainCart,
      setClosable,
      setOnDialogBack,
      setIsSubmitted,
      setIsSubmitting,
      _stripeToken,
      inkindRouteId,
      frontendTransactionId,
      anonymous,
      privateClaim,
      onSubmit,
      stripe,
      cardElement,
      setOrderTotal,
      setOrderTip,
      setOrderStatus,
      onSuccess,
    ]
  );

  const handleSuggestedResolvesChange = React.useCallback((suggestions: ClaimConflictResolve[]) => {
    setSuggestedResolves(suggestions);
    // const newData = { ...data } as CheckoutFormValues;
    // setData(newData);
  }, []);

  const handleGreetingNext = React.useCallback(() => {
    if (isSubmitting) return;

    // scroll to top
    cardCarrierEditorRef?.current?.scrollToTop();

    if (!cardCarrierEditorRef?.current?.isOpen()) {
      cardCarrierEditorRef?.current?.open();
      setTimeout(() => {
        handleNext(cardCarrierFormId);
      }, 1000);
    } else {
      setTimeout(() => {
        handleNext(cardCarrierFormId);
      }, 300);
    }
  }, [handleNext, isSubmitting]);

  const handleDone = React.useCallback(() => {
    if (inkindRouteId && navigateToInkindPageAfterPurchase) {
      // TODO: after SPA migration remove the below line + useCompletedPurchaseForPageAnalytics as SPA redirection will not interrupt events
      localStorage.setItem(fireCompletedPurchaseLocalStorageKey, 'true');
      navigateTo(`/inkinds/${inkindRouteId}`);
    }
    // must close all dialogs in order to close both the purchase confirmation modal and the choose a giftbox modal
    UI.closeAllDialogs();

    if (paymentConfirmationValues) {
      Analytics.fireEvent(AnalyticsEvents.CompletedPurchaseCloseModal, {});
    }
  }, [inkindRouteId, navigateToInkindPageAfterPurchase, paymentConfirmationValues]);

  // this handles "back to checkout" and "update & place order" buttons
  const handleUpdateClaimConflicts = React.useCallback(
    async (suggestions: ClaimConflictResolve[], placeOrder?: boolean) => {
      const newCart = mainCart.concat([]);

      newCart.forEach((cartItem, index) => {
        const suggestion = suggestions.find(item => item.id === cartItem.id);
        if (!cartItem.createClaimRequest) return;

        if (suggestion) {
          if (!cartItem.createClaimRequest.originalClaimDate) {
            cartItem.createClaimRequest.originalClaimDate = cartItem.createClaimRequest.claimDate;
          }
          cartItem.createClaimRequest.claimDate = suggestion.date.instanceDate;
          cartItem.createClaimRequest.calendarEntryId = suggestion.date.entryId;
        } else {
          if (
            claimErrors?.find(
              error =>
                error.id === cartItem.id &&
                (error.status?.toLowerCase() === 'conflict' || error.status?.toLowerCase() === 'gone')
            )
          ) {
            // there is no suggestion for this conflict, just turn it into a wishlist purchase
            cartItem.createClaimRequest = null;
            cartItem.isWishlist = false;
          }
        }
      });

      setMainCart(newCart);
      setSuggestedResolves(suggestions);
      onUpdateClaimConflicts(suggestions);

      if (placeOrder) {
        setTimeout(async () => {
          //
          const result = await handleComplete(data, newCart);

          if (result?.message === 'conflicts') {
            suggestions.forEach(suggestion => {
              // @ts-ignore
              const newValue = (result.data as ClaimConflictErrorDetails[]).find(r => r.id === suggestion.id);
              if (newValue) suggestion.date = newValue.availableDates[0];
            });

            handleUpdateClaimConflicts(suggestions, true);
          }
        }, timeoutDefaultValue);
      } else {
        setClaimErrors(null);
        setClosable(true);

        if (_stripeToken.startsWith('pi_')) {
          // this is an Apple Pay (or rather strapi Express Checkout) transaction that was confirmed but not yet captured
          // we need to release the money on the card by cancelling the payment intent
          // if this fails for any reason, the backend also has a timer already setup at this point to do that in 2h

          try {
            await cancelPaymentIntent(_stripeToken, useIdempotencyKeysStore.getState().expressCheckoutUniqueKey);
          } catch (error) {
            logger.error('Failed to cancel payment intent', { error });
          }
        }
        setStripeToken(null);
      }
    },
    [_stripeToken, claimErrors, data, handleComplete, mainCart, onUpdateClaimConflicts, setClosable, setMainCart]
  );

  const [completePurchaseButtonDisabled, setCompletePurchaseButtonDisabled] = React.useState<boolean>(false);

  const buttons = React.useCallback(
    (isFormValid: boolean, cartHasError?: boolean) => {
      const isHandleCompletePurchaseDisabled =
        isOrderSummaryFormLoading || !cartItem || cartHasError || !isFormValid || completePurchaseButtonDisabled;

      const buttons = [];

      if (paymentConfirmationValues) {
        buttons.push(
          <Button key="btn-confirmation-done" variant="primary" onClick={handleDone}>
            Done
          </Button>
        );
        return buttons;
      }

      if (!stepItem) {
        return null;
      }

      const productsStepButtonText = initiatedOn === 'wishlist' ? 'BUY' : 'CONTINUE';

      const commonButtonProps: Partial<IButtonProps> = {
        variant: 'primary',
        loading: isSubmitting,
        type: 'submit',
      };

      setDialogFooterClass?.(undefined);

      switch (currentStepId) {
        case 'wishlist':
          // no buttons here

          break;
        case 'calendar':
          // no buttons here
          setDialogFooterClass?.('add-from-calendar');
          break;
        case 'claim':
          if (stepIndex > 0) {
            buttons.push(
              <Button
                loading={isSubmitting}
                disabled={isSubmitting}
                variant="default"
                key="btn-greetingcard-back"
                onClick={gotoPrevStep}
                tabIndex={-1}
                hideLabelOnMobile
                prepend={<ChevronLeftIcon />}
              >
                BACK
              </Button>
            );
          } else {
            buttons.push(<div></div>);
          }

          buttons.push(
            <Button
              {...commonButtonProps}
              key="btn-claim-next"
              disabled={!isFormValid}
              onClick={() => handleNext(claimFormId)}
              wide={false}
            >
              CONTINUE <ChevronRightIcon />
            </Button>
          );
          break;
        case 'products':
          if (stepIndex > 0) {
            buttons.push(
              <Button
                loading={isSubmitting}
                disabled={isSubmitting}
                variant="default"
                key="btn-greetingcard-back"
                onClick={gotoPrevStep}
                tabIndex={-1}
                hideLabelOnMobile
                prepend={<ChevronLeftIcon />}
              >
                BACK
              </Button>
            );
          } else {
            buttons.push(<div></div>);
          }

          if (
            addToOrder &&
            ((products?.[0].isToggleable && productType !== 'physical') || !products?.[0].isToggleable)
          ) {
            buttons.push(
              <Button
                {...commonButtonProps}
                wide={false}
                id={cardCarrierFormId + '-btn'}
                loading={isOrderFormLoading}
                disabled={isOrderFormLoading || !isFormValid}
                onClick={() => handleNext(orderFormId)}
              >
                {editMode ? 'Save & Checkout' : 'Add & Checkout'}
                <ChevronRightIcon />
              </Button>
            );
          } else {
            buttons.push(
              <Button
                {...commonButtonProps}
                key="btn-order-next"
                loading={isOrderFormLoading}
                disabled={isOrderFormLoading || !isFormValid}
                onClick={() => handleNext(orderFormId)}
                wide={false}
                append={<ChevronRightIcon />}
              >
                {productType === 'physical' ? 'Customize & Buy' : productsStepButtonText}
              </Button>
            );
          }
          break;
        case 'greetingcard':
          if (stepIndex > 0) {
            buttons.push(
              <Button
                loading={isSubmitting}
                disabled={isSubmitting}
                variant="default"
                key="btn-greetingcard-back"
                onClick={gotoPrevStep}
                tabIndex={-1}
                hideLabelOnMobile
                prepend={<ChevronLeftIcon />}
              >
                BACK
              </Button>
            );
          } else {
            buttons.push(<div></div>);
          }
          buttons.push(
            <Button
              {...commonButtonProps}
              wide={false}
              key="btn-card-carrier-next"
              id={cardCarrierFormId + '-btn'}
              onClick={handleGreetingNext}
              debounce={250}
            >
              CONTINUE <ChevronRightIcon />
            </Button>
          );
          break;
        case 'giftcard':
          if (stepIndex > 0) {
            buttons.push(
              <Button
                loading={isSubmitting}
                disabled={isSubmitting}
                variant="default"
                key="btn-giftcard-back"
                onClick={gotoPrevStep}
                tabIndex={-1}
                hideLabelOnMobile
                prepend={<ChevronLeftIcon />}
              >
                BACK
              </Button>
            );
          } else {
            buttons.push(<div></div>);
          }

          if (addToOrder && productType === 'physical') {
            buttons.push(
              <Button
                {...commonButtonProps}
                wide={false}
                id={cardCarrierFormId + '-btn'}
                onClick={() => handleNext(giftCardEditorFormId)}
              >
                {editMode ? 'Save & Checkout' : 'Add & Checkout'}
                <ChevronRightIcon />
              </Button>
            );
          } else {
            buttons.push(
              <Button
                {...commonButtonProps}
                wide={false}
                id={cardCarrierFormId + '-btn'}
                onClick={() => handleNext(giftCardEditorFormId)}
              >
                {shippingEditable || !inkindRouteId ? 'Recipient Info' : 'Checkout'}
                <ChevronRightIcon />
              </Button>
            );
          }
          break;
        // case 'card-carrier':
        //   buttons.push(
        //     <Button {...commonButtonProps} key="btn-card-carrier-next" onClick={() => handleNext(cardCarrierFormId)}>
        //       CONTINUE
        //     </Button>
        //   );
        //   break;
        case 'billing':
          if (stepIndex > 0) {
            buttons.push(
              <Button
                loading={isSubmitting}
                disabled={isSubmitting}
                variant="default"
                key="btn-billing-back"
                onClick={gotoPrevStep}
                tabIndex={-1}
                hideLabelOnMobile
                prepend={<ChevronLeftIcon />}
              >
                BACK
              </Button>
            );
          } else {
            buttons.push(<div></div>);
          }

          buttons.push(
            <Button
              {...commonButtonProps}
              wide={false}
              id={billingFormFormId + '-btn'}
              onClick={() => handleNext('billingForm')}
            >
              CONTINUE <ChevronRightIcon />
            </Button>
          );
          break;
        case 'shipping':
          if (stepIndex > 0) {
            buttons.push(
              <Button
                loading={isSubmitting}
                disabled={isSubmitting}
                variant="default"
                key="btn-shipping-back"
                onClick={gotoPrevStep}
                tabIndex={-1}
                hideLabelOnMobile
                prepend={<ChevronLeftIcon />}
              >
                BACK
              </Button>
            );
          } else {
            buttons.push(<div></div>);
          }

          buttons.push(
            <Button
              {...commonButtonProps}
              wide={false}
              id={shippingFormId + '-btn'}
              onClick={() => handleNext('shippingForm')}
            >
              Checkout <ChevronRightIcon />
            </Button>
          );
          break;
        case 'payment':
          if (claimErrors) {
            buttons.push(
              <Button
                variant={'default'}
                onClick={() => handleUpdateClaimConflicts(suggestedResolves)}
                preventClickWhenDisabled
              >
                Return to checkout
              </Button>
            );
            buttons.push(<div />);
            buttons.push(
              <Button
                {...commonButtonProps}
                id={summaryFormId + '-btn'}
                onClick={() => handleUpdateClaimConflicts(suggestedResolves, true)}
                preventClickWhenDisabled
                append={<ChevronRightIcon />}
              >
                UPDATE & PAY
              </Button>
            );
            break;
          }

          if (stepIndex > 0) {
            buttons.push(
              <Button
                loading={isSubmitting}
                disabled={isSubmitting || !cartItem}
                variant="default"
                key="btn-payment-back"
                preventClickWhenDisabled
                onClick={gotoPrevStep}
                tabIndex={-1}
                hideLabelOnMobile
                prepend={<ChevronLeftIcon />}
              >
                BACK
              </Button>
            );
          } else {
            buttons.push(<div></div>);
          }

          if (!errorPage) {
            buttons.push(
              <Button
                {...commonButtonProps}
                id={summaryFormId + '-btn'}
                onClick={handleScrollToPayment}
                preventClickWhenDisabled
                disabled={isOrderSummaryFormLoading || cartHasError || !isFormValid || isSubmitting}
                prepend={<SvgIcon Icon={ArrowDownIcon} />}
              >
                SCROLL TO PAYMENT
              </Button>
            );
          }
          break;
      }

      return buttons;
    },
    [
      isOrderSummaryFormLoading,
      cartItem,
      completePurchaseButtonDisabled,
      paymentConfirmationValues,
      stepItem,
      initiatedOn,
      isSubmitting,
      setDialogFooterClass,
      currentStepId,
      handleDone,
      stepIndex,
      addToOrder,
      products,
      productType,
      handleGreetingNext,
      claimErrors,
      errorPage,
      gotoPrevStep,
      handleNext,
      isOrderFormLoading,
      editMode,
      shippingEditable,
      inkindRouteId,
      handleUpdateClaimConflicts,
      suggestedResolves,
      handleScrollToPayment,
    ]
  );

  const handleSubmitSummary = React.useCallback(
    (values: OrderSummaryValues) => {
      const newData = updateSummaryData(values);

      setTimeout(() => {
        //
        handleComplete(newData);
      }, timeoutDefaultValue);
    },
    [handleComplete, updateSummaryData]
  );

  const handleChangeSummary = React.useCallback(
    (values: OrderSummaryValues) => {
      const newData = updateSummaryData(values);
      newData.shipping.shippingOptionName = values.shippingOptionName;
      newData.shipping.deliveryMethod = values.deliveryMethod;
      setData(newData);
      if (onChange) onChange(newData);
    },
    [onChange, updateSummaryData]
  );

  const handleChangeTip = React.useCallback(
    (tipPercentage: number, tipAmount: number) => {
      if (completePurchaseButtonDisabled) {
        // set a new unique transaction ID each time order value changes after an error occurred
        setCompletePurchaseButtonDisabled(false);
        updateFrontendTransactionId();
        data.billing.stripeToken = null;
      }

      const newData = { ...data } as CheckoutFormValues;
      if (!newData.summary) newData.summary = {};
      newData.summary.tipAmount = tipAmount;
      newData.summary.tipPercentage = tipPercentage;
      setData(newData);
      if (onChange) {
        onChange(newData);
      }
    },
    [completePurchaseButtonDisabled, data, onChange, updateFrontendTransactionId]
  );

  const handleCarrierChanged = React.useCallback(
    (cartItem: CartItem) => {
      const newData = {
        ...data,
      } as CheckoutFormValues;

      if (productType === 'physical') {
        newData.carrier = cartItem.carrier;
      } else {
        delete newData.carrier;
      }

      const newCart = newData.cart.concat([]);

      newCart[0] = cartItem;

      newData.cart = newCart;

      setData(newData);
    },
    [data, productType]
  );

  const handleProductTypeChanged = React.useCallback(
    (cartItem: CartItem) => {
      if (stepsLatest.current[stepIndexLatest.current].id !== 'payment') return;

      setProductType(cartItem.productType);

      _setHasCardCarrier(cartItem.productType === 'physical');

      // setTimeout(() => {

      //   // switched from physical to digital
      //   gotoStepIndex(paymentStepIndex);
      // } else {
      // switched from digital to physical
      if (stepsRef.current) {
        setTimeout(() => {
          const steps = stepsLatest.current;

          const paymentStepIndex = steps.findIndex(step => step.id === 'payment');
          // if (cartItem.productType !== 'physical') {
          stepsRef.current?.setCompletedIndex(paymentStepIndex);
          setTimeout(() => {
            const paymentStepIndex = steps.findIndex(step => step.id === 'payment');

            stepsRef.current?.gotoStep(paymentStepIndex);
          }, 100);
        }, 500);
      }
      // setTimeout(() => {
      //   gotoStepIndex(paymentStepIndex);
      // }, 1);
      // }ks
      // }, 1);
    },
    [stepIndexLatest, stepsLatest]
  );

  const handleClearSuggestions = React.useCallback(() => {
    setSuggestedResolves(undefined);

    const newCart = mainCart.concat([]);

    mainCart.forEach(cartItem => {
      if (cartItem.createClaimRequest.originalClaimDate) {
        cartItem.createClaimRequest.claimDate = cartItem.createClaimRequest.originalClaimDate;
      }
    });

    setMainCart(newCart);
  }, [mainCart, setMainCart]);

  const handleClaimEvent = React.useCallback(
    (event: ICalendarEvent, entry: ICalendarEntry, routeId: string, serviceSlug: string) => {
      Analytics.fireEvent(AnalyticsEvents.AddFromCalendarClaimDate, {
        inkindRouteId: routeId,
        claimDate: event.startsAt,
        serviceSlug,
      });

      setCreateClaimRequest(createClaimRequestFromClaimEvent(event, serviceSlug));
      setClaimEntry(entry);
      gotoNextStep();
    },
    [gotoNextStep]
  );

  const handleSelectFromWishlist = React.useCallback(
    (id: number) => {
      setSelectedId(id);
      setTimeout(() => {
        Analytics.fireEvent(AnalyticsEvents.AddFromWishlistBuyClick, {
          inkindRouteId,
          wishlistProductId: id.toString(),
        });

        handleNext(wishlistFormId);
      }, timeoutDefaultValue);
    },
    [handleNext, inkindRouteId]
  );

  const isShippingStep = currentStepId === 'shipping';

  const recipientNameDisplay = (data?.shipping?.firstName + ' ' + (data?.shipping?.lastName || '')).trim();
  const { setMessageToRecipient } = React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext);

  /**
   * Populate message to recipient and tip percentage from localstorage
   */
  useEffectOnce(() => {
    const newData: CheckoutFormValues = data ? data : initialData ? { ...initialData } : {};
    if (mainCart) newData.cart = mainCart;

    const storedMessage = storage.getWithExpiry(`message-to-recipient-${inkindRouteId}`);

    if (storedMessage && storedMessage !== 'undefined' && !newData.summary?.messageToRecipient) {
      newData.summary.messageToRecipient = storedMessage;
      setMessageToRecipient(storedMessage);
    }

    const product = newData.cart?.[0];
    const storedTipPercentage = product
      ? storage.getWithExpiry(`tip-percentage-${inkindRouteId}-${product.productId}`)
      : undefined;

    if (storedTipPercentage) newData.summary.tipPercentage = parseInt(storedTipPercentage, 10);

    if (!newData) return;

    setPrivateClaim(privateClaim);

    setData(newData);
    setMainCart(newData.cart);
    setTimeout(() => {
      // figure out initial step to land on when editing a cart item
      if (editMode && selectFromWishlist) {
        const cartItem = initialData.cart?.[0];

        setSelectedProductId(cartItem?.productId);

        setTimeout(() => {
          gotoStepIndex(1);
          setIsSubmitting(false);
        }, 100);
        return;
      }

      if (editMode && selectFromCalendar) {
        const cartItem = initialData.cart?.[0];

        setSelectedProductId(cartItem?.productId);

        setTimeout(() => {
          gotoStepIndex(1);
          setIsSubmitting(false);
        }, 100);
        return;
      }

      gotoStepIndex(0);
    });
  });

  if ((!data && !products) || productError || (!product && !addToOrder)) return <LoadingSpinner center />;

  return (
    <>
      {orderStatus && (
        <div className={'gik-order-status-popup-wrapper'}>
          <LoadingPopup
            type={DeterminationType.Determinate}
            progress={(orderStatus.progress / orderStatus.totalProgress) * 100}
            text={orderStatus.text.toLowerCase()}
          />
        </div>
      )}
      {errorPage}
      {/* <CodeBlock value={mainCart} /> */}
      <Steps
        ref={stepsRef}
        variant="primary"
        allowBackwardNavigation
        allowForwardNavigation={false}
        steps={steps}
        index={stepIndex}
        onClick={handleClickSteps}
        navPortal={stepsNavPortal}
        portal={stepsPortal}
        disabled={isSubmitting || isSubmitted}
        renderHiddenTabs
        hidden={!!errorPage}
        onStepProgression={handleStepProgressionFromComponent}
      >
        {selectFromWishlist && (
          <div>
            <AddFromWishtlistSection
              inkindRouteId={inkindRouteId}
              pageName={inkindPage.title}
              selectedId={selectedId}
              onSelect={handleSelectFromWishlist}
              buttons={currentStepId === 'wishlist' && buttons}
              buttonsPortal={currentStepId === 'wishlist' && buttonsPortal}
            />
          </div>
        )}

        {selectFromCalendar && (
          <div>
            <AddFromCalendarSection
              inkindRouteId={inkindRouteId}
              pageName={inkindPage.title}
              buttons={currentStepId === 'calendar' && buttons}
              buttonsPortal={currentStepId === 'calendar' && buttonsPortal}
              onClaimEvent={handleClaimEvent}
              currentCart={currentCart}
              privateClaim={privateClaim || anonymous}
            />
          </div>
        )}

        {selectFromCalendar && (
          <div>
            {claimEntry && (
              <GiftCardServiceForm
                entry={claimEntry}
                privateClaimOverride={privateClaim || anonymousOverride || anonymous}
                submitButton={false}
                onSelect={product => setSelectedGiftCard(product)}
                errorMessage={formError}
              />
            )}
            {currentStepId === 'claim' && renderPortal?.(buttons && buttons?.(!!selectedGiftCard), () => buttonsPortal)}
          </div>
        )}

        {((!hideProducts && productIds) || unsupportedPageNoWallet) && (
          <div>
            {initiatedOn === 'productPage' && (products?.[0].isToggleable || unsupportedPageNoWallet) && (
              <span className="gik-form-header">Verify info</span>
            )}

            <OrderForm
              id={id + '_' + orderFormId}
              onLoadComplete={() => setIsOrderFormLoading(false)}
              productIds={[productIds?.[0]]}
              initiatedOn={initiatedOn}
              initialOrders={mainCart}
              onSubmit={handleSubmitOrder}
              onChange={handleOrderOnChange}
              onValidationFail={() => setIsSubmitting(false)}
              key={currentStepId}
              buttons={currentStepId === 'products' && buttons}
              buttonsPortal={currentStepId === 'products' && buttonsPortal}
              inkindRouteId={inkindRouteId}
              anonymous={anonymous}
              anonymousOverride={anonymousOverride}
            />
          </div>
        )}

        {productType === 'physical' && (
          <div>
            <CardCarrierEditor
              id={id + '_' + cardCarrierFormId}
              key={cartItem?.id}
              ref={cardCarrierEditorRef}
              inkindRouteId={inkindRouteId}
              shippingDetails={data?.shipping}
              anonymous={anonymous}
              anonymousOverride={anonymousOverride}
              nameOnCard={cartItem?.nameOnCard}
              greetingCardUrl={cartItem?.carrier?.selectedCarrierDesign.url}
              toName={cartItem?.carrier?.toName}
              fromName={cartItem?.carrier?.fromName}
              customMessage={customMessage ?? cartItem?.customMessage}
              expiryMonth={expiryMonth ?? cartItem?.expiryMonth}
              cardImage={cardImage}
              faceplate={faceplate ?? cartItem?.faceplate}
              price={mainCart?.[0]?.price}
              onSubmit={handleSubmitCardCarrier}
              onValidationFail={() => setIsSubmitting(false)}
              buttons={currentStepId === 'greetingcard' && buttons}
              buttonsPortal={currentStepId === 'greetingcard' && buttonsPortal}
            />
          </div>
        )}

        {productType === 'physical' && (
          <div>
            <GiftCardEditor
              id={id + '_' + giftCardEditorFormId}
              key={cartItem?.id}
              anonymous={anonymous}
              expiryMonth={expiryMonth ?? cartItem?.expiryMonth}
              cardImage={cardImage}
              faceplate={faceplate ?? cartItem?.faceplate}
              product={product}
              active={currentStepId === 'giftcard'}
              buttons={currentStepId === 'giftcard' && buttons}
              buttonsPortal={currentStepId === 'giftcard' && buttonsPortal}
              nameOnCard={cartItem?.nameOnCard || sanitizeCreditCardDisplayString(inkindPage?.recipientFullName)}
              customMessage={cartItem?.customMessage}
              carrier={data?.carrier}
              onSubmit={handleSubmitGiftCard}
              onValidationFail={() => setIsSubmitting(false)}
              price={mainCart?.[0]?.price}
            />
          </div>
        )}
        {(shippingEditable || !inkindRouteId) && productType === 'physical' && (
          <div>
            <span className="gik-form-header">Recipient&apos;s Details</span>
            <p className="gik-form-subheader">To whom do you want to send the greeting card with gift card?</p>
            <Separator />
            <ShippingForm
              id="shippingForm"
              initialValues={data?.shipping}
              onSubmit={handleSubmitShipping}
              onValidationFail={() => setIsSubmitting(false)}
              inkindRouteId={inkindRouteId}
              buttons={currentStepId === 'shipping' && buttons}
              buttonsPortal={currentStepId === 'shipping' && buttonsPortal}
              recipient
            />
          </div>
        )}
        {(shippingEditable || !inkindRouteId) && productType === 'digital' && (
          <div>
            <span className="gik-form-header">Recipient&apos;s Details</span>
            <p className="gik-form-subheader">To whom do you want to send the gift card?</p>
            <Separator />
            <DigitalShippingForm
              id={shippingFormId}
              initialValues={data?.shipping}
              onSubmit={handleSubmitShipping}
              onValidationFail={() => setIsSubmitting(false)}
              inkindRouteId={inkindRouteId}
              buttons={currentStepId === 'shipping' && buttons}
              buttonsPortal={currentStepId === 'shipping' && buttonsPortal}
              recipient
            />
          </div>
        )}
        {hasBilling && data?.billing && (
          <div>
            <span className="gik-form-header">Billing Info</span>
            {showPromoInput && <CheckoutPromoCode onSuccessfulPurchase={onSuccessfulPurchase} />}
            <BillingForm
              id={billingFormFormId}
              initialValues={data?.billing}
              initialOrders={mainCart}
              shippingDetails={data?.shipping}
              productIds={productIds}
              onSubmit={handleSubmitBilling}
              onBillingSubmitFail={() => setIsSubmitting(false)}
              onValidationFail={() => setIsSubmitting(false)}
              onAfterSubmit={handleAfterSubmitBilling}
              setCardElement={setCardElement}
              creditCardLast4={
                (source && source.card.last4) || stripeTokenLocalStorage
                  ? storage.getWithExpiry('stripe-token-last-4-digits') || 'xxxx'
                  : undefined
              }
              buttons={currentStepId === 'billing' && buttons}
              buttonsPortal={currentStepId === 'billing' && buttonsPortal}
            />
          </div>
        )}
        <div>
          {claimErrors ? (
            <ClaimConflictsResolution
              claimErrors={claimErrors}
              addToOrder={addToOrder}
              inkindRouteId={inkindRouteId}
              buttons={currentStepId === 'payment' && buttons}
              buttonsPortal={currentStepId === 'payment' && buttonsPortal}
              onSuggestedResolvesChange={handleSuggestedResolvesChange}
              setClosable={setClosable}
            />
          ) : (
            <OrderSummary
              claimErrors={claimErrors}
              addFromCalendar={!buyForSomeoneElse && cartItem?.checkoutType !== 'gik-premium'}
              addFromWishlist={!buyForSomeoneElse && cartItem?.checkoutType !== 'gik-premium'}
              id={summaryFormId}
              inkindRouteId={inkindRouteId}
              initialValues={data?.summary}
              billingInitialValues={data?.billing}
              cart={mainCart}
              carrier={data?.carrier}
              shipping={shipping}
              hasShipping={hasShipping}
              hasPGShipping={hasPGShipping}
              shippingDetails={data?.shipping}
              billing={data?.billing}
              setIsOrderSummaryFormLoading={setIsOrderSummaryFormLoading}
              onSubmit={handleSubmitSummary}
              onValidationFail={() => setIsSubmitting(false)}
              onChange={handleChangeSummary}
              onTipChange={handleChangeTip}
              onOpenTermsOfService={onOpenTermsOfService}
              onOpenPrivacyPolicy={onOpenPrivacyPolicy}
              onCarrierChanged={handleCarrierChanged}
              onProductTypeChanged={handleProductTypeChanged}
              onClearSuggestions={handleClearSuggestions}
              stripeErrorMessage={stripeError?.message}
              errorMessage={errorMessage}
              buyForSomeoneElse={buyForSomeoneElse}
              buttons={currentStepId === 'payment' && buttons}
              buttonsPortal={currentStepId === 'payment' && buttonsPortal}
              privateClaim={_privateClaim}
              productIds={productIds}
              creditCardLast4={
                (source && source.card.last4) || stripeTokenLocalStorage
                  ? storage.getWithExpiry('stripe-token-last-4-digits') || 'xxxx'
                  : undefined
              }
              setCardElement={setCardElement}
              onSubmitBilling={handleSubmitBilling}
              onBeforeSubmitBilling={handleBeforeSubmitBilling}
              onBillingSubmitFail={() => setIsSubmitting(false)}
              onBillingValidationFail={() => setIsSubmitting(false)}
              onBillingAfterSubmit={handleAfterSubmitBilling}
              onClickFirstNameField={handleScrollToBillingForm}
              onExpandBillingForm={handleScrollToExpressCheckout}
              showPromoInput={showPromoInput}
              onSuccessfulPromo={onSuccessfulPurchase}
              onStripeFieldChange={handleStripeFieldChange}
            />
          )}
        </div>
      </Steps>
    </>
  );
}

export const CheckoutForm = withComponentErrorBoundary(CheckoutFormComp);
