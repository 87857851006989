import type { UIComponent } from '@gik/core/types/UI';
import type { LinkProps } from 'next/link';
import NextLink from 'next/link';
import React from 'react';

const Link = (props: React.PropsWithChildren<UIComponent & LinkProps>) => {
  return (
    <NextLink {...props} href={props.href || ''}>
      {props.children}
    </NextLink>
  );
};

export default Link;
