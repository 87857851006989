// @ts-ignore
import { isBrowser } from '@/utils/environment';
import { logger } from '@gik/analytics/utils/logger';
import { useIntercomStore } from '@gik/ui/gik/IntercomLazyProvider/IntercomStore';
import React from 'react';

export function openIntercomWindow() {
  if (isBrowser()) {
    try {
      Intercom('show');
    } catch (e) {
      alert('Open Intercom');
    }
  }
}

export function showIntercomDefaultLauncher() {
  if (isBrowser()) {
    try {
      Intercom('update', {
        hide_default_launcher: false,
      });
    } catch (e) {
      logger.info('Show Intercom default launcher');
    }
  }
}

export function hideIntercomDefaultLauncher() {
  if (isBrowser()) {
    try {
      Intercom('update', {
        hide_default_launcher: true,
      });
    } catch (e) {
      logger.info('Hide Intercom default launcher');
    }
  }
}

export function useHideIntercomDefaultLauncher() {
  const isIntercomBooted = useIntercomStore(state => state.isBooted);
  React.useEffect(() => {
    if (isIntercomBooted) {
      hideIntercomDefaultLauncher();
    }
    return () => {
      if (isIntercomBooted) {
        showIntercomDefaultLauncher();
      }
    };
  }, [isIntercomBooted]);
}
