import type { ClaimStatus, PaymentConfirmationValues } from '@gik/checkout/types';
import type { Product } from '@gik/core/models/gik/Product';
import type { IWordpressCalendarEventType } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import type IWordpressServiceCategory from '@gik/core/models/wordpress/WordpressServiceCategory';
import DateTimeUtils from '@gik/core/utils/DateTimeUtils';
import moment from 'moment';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';
import type { ICalendarEvent, ResolveGiftCardClaimConflictValues } from '../models/Calendar';

export const calendarDefaultMonth = moment().format(DateTimeUtils.MonthFormat); // default to the current month

interface ICalendarStoreProperties {
  readonly selectedMonth: string;
  readonly calendarEventTypes?: IWordpressCalendarEventType[];
  readonly reloadFunction?: Function;
  readonly setDialogTitle?: Function;
  readonly calendarStore?: Function;

  readonly claimConflicts: ClaimStatus[];
  readonly goneConflicts: ClaimStatus[];
  readonly selectedService: IWordpressService;
  readonly selectedServiceCategory: IWordpressServiceCategory;
  readonly dialogIsClosed: boolean;
  readonly ignoreClaimConflict: boolean;
  readonly selectedGiftcard: Product;
  readonly privateClaim: boolean;
  readonly paymentConfirmationValues: PaymentConfirmationValues;
  readonly claimFailErrorCode: number;
  readonly calendarEvents: ICalendarEvent[];
  readonly fatalError: string;
  readonly scrollToEntryById: Function;
  readonly hasChangedGiftCards: boolean;
  readonly formSaving: boolean;
  readonly resolveGiftCardClaimConflictValues: ResolveGiftCardClaimConflictValues;
  readonly onClaimClose: () => void;
  readonly onClaimSuccess: () => void;
  readonly onClaimEditSuccess: () => void;
  readonly onUnclaimSuccess: () => void;
  readonly buttonsPortal: () => HTMLElement;
  readonly stepsNavPortal: () => HTMLElement;
  readonly calendarRefresh: () => void;
}

export interface ICalendarStore extends ICalendarStoreProperties {
  setFatalError: (fatalError: string) => void;

  // getRecipientContactInformation(): void;

  resetStore(): void;
  setSelectedMonth(month: string): void;
  setCalendarEventTypes(calendarEventTypes: IWordpressCalendarEventType[]): void;
  setReloadFunction(reload: Function): void;

  setButtonsPortal(buttonsPortal: () => HTMLElement): void;
  setStepsNavPortal(stepsNavPortal: () => HTMLElement): void;

  setOnUnclaimSuccess(unclaimSuccess: () => void): void;

  setDialogIsClosed(dialogIsClosed: boolean): void;
  setSelectedGiftcard(selectedGiftcard: Product): void;
  setPrivateClaim(privateClaim: boolean): void;
  setClaimConflicts(claimConflicts: ClaimStatus[]): void;
  setGoneConflicts(goneConflicts: ClaimStatus[]): void;
  setPaymentConfirmationValues(paymentConfirmationValues: PaymentConfirmationValues): void;
  setClaimFailErrorCode(claimFailErrorCode: number): void;
  setResolveGiftCardClaimConflictValues(resolveGiftCardClaimConflictValues: ResolveGiftCardClaimConflictValues): void;
  setIgnoreClaimConflict(ignoreClaimConflict: boolean): void;
  setCalendarEvents(events: ICalendarEvent[]): void;
  setCalendarRefresh(calendarRefresh: () => void): void;
  setScrollToEntryById(scrollToEntryById: Function): void;
  setHasChangedGiftCards(hasChangedGiftCards: boolean): void;
  setFormSaving(formSaving: boolean): void;
}

const initialState: ICalendarStoreProperties = {
  selectedMonth: calendarDefaultMonth,
  calendarEventTypes: undefined,
  buttonsPortal: undefined,
  stepsNavPortal: undefined,
  selectedService: undefined,
  selectedServiceCategory: undefined,
  onClaimClose: undefined,
  onClaimSuccess: undefined,
  onClaimEditSuccess: undefined,
  onUnclaimSuccess: undefined,
  dialogIsClosed: undefined,
  selectedGiftcard: undefined,
  privateClaim: false,
  paymentConfirmationValues: undefined,
  claimFailErrorCode: undefined,
  calendarEvents: undefined,
  calendarRefresh: undefined,
  fatalError: undefined,
  setDialogTitle: undefined,
  scrollToEntryById: undefined,
  hasChangedGiftCards: false,
  formSaving: false,
  resolveGiftCardClaimConflictValues: null,
  ignoreClaimConflict: false,
  claimConflicts: null,
  goneConflicts: null,
};

export const calendarStore: StateCreator<ICalendarStore, [], [], ICalendarStore> = set => ({
  ...initialState,

  resetStore: () =>
    // @ts-ignore
    set(() => {
      return {
        claimSuccess: false,
        selectedService: undefined,
        selectedServiceCategory: undefined,
        claim: undefined,
        selectedGiftcard: undefined,
        resolveGiftCardClaimConflictValues: undefined,
        ignoreClaimConflict: false,
      };
    }),
  setSelectedMonth: (month: string) =>
    set(() => {
      if (!month) {
        throw new Error('Month is undefined');
      }
      return {
        selectedMonth: month,
      };
    }),

  setCalendarEventTypes: (calendarEventTypes: IWordpressCalendarEventType[]) =>
    set(() => {
      return {
        calendarEventTypes,
      };
    }),
  setReloadFunction: (reload: Function) =>
    set(() => {
      return {
        reloadFunction: reload,
      };
    }),
  setButtonsPortal: (buttonsPortal: () => HTMLElement) =>
    set(() => {
      return {
        buttonsPortal,
      };
    }),
  setStepsNavPortal: (stepsNavPortal: () => HTMLElement) =>
    set(() => {
      return {
        stepsNavPortal,
      };
    }),

  setSelectedService: (selectedService: IWordpressService) =>
    set(() => {
      return {
        selectedService,
      };
    }),
  setSelectedServiceCategory: (selectedServiceCategory: IWordpressServiceCategory) =>
    set(() => {
      return {
        selectedServiceCategory,
      };
    }),

  setOnClaimClose: (fn: () => void) =>
    set(() => {
      return {
        onClaimClose: fn,
      };
    }),
  setOnClaimSuccess: (fn: () => void) =>
    set(() => {
      return {
        onClaimSuccess: fn,
      };
    }),
  setOnClaimEditSuccess: (fn: () => void) =>
    set(() => {
      return {
        onClaimEditSuccess: fn,
      };
    }),
  setOnUnclaimSuccess: (fn: () => void) =>
    set(() => {
      return {
        onUnclaimSuccess: fn,
      };
    }),

  setDialogIsClosed: (dialogIsClosed: boolean) =>
    set(() => {
      return {
        dialogIsClosed,
      };
    }),
  setSelectedGiftcard: (selectedGiftcard: Product) =>
    set(() => {
      return {
        selectedGiftcard,
      };
    }),
  setPrivateClaim: (privateClaim: boolean) =>
    set(() => {
      return {
        privateClaim,
      };
    }),
  setPaymentConfirmationValues: (paymentConfirmationValues: PaymentConfirmationValues) =>
    set(() => {
      return {
        paymentConfirmationValues,
      };
    }),
  setResolveGiftCardClaimConflictValues: (resolveGiftCardClaimConflictValues: ResolveGiftCardClaimConflictValues) =>
    set(() => {
      return {
        resolveGiftCardClaimConflictValues,
      };
    }),
  setIgnoreClaimConflict: (ignoreClaimConflict: boolean) =>
    set(() => {
      return {
        ignoreClaimConflict,
      };
    }),
  setClaimFailErrorCode: (claimFailErrorCode: number) =>
    set(() => {
      return {
        claimFailErrorCode,
      };
    }),
  setCalendarEvents: (calendarEvents: ICalendarEvent[]) =>
    set(() => {
      return {
        calendarEvents,
      };
    }),
  setCalendarRefresh: (calendarRefresh: () => void) =>
    set(() => {
      return {
        calendarRefresh,
      };
    }),
  setFatalError: (fatalError: string) =>
    set(() => {
      return {
        fatalError,
      };
    }),
  setScrollToEntryById: (scrollToEntryById: Function) =>
    set(() => {
      return {
        scrollToEntryById,
      };
    }),
  setHasChangedGiftCards: (hasChangedGiftCards: boolean) =>
    set(() => {
      return {
        hasChangedGiftCards,
      };
    }),
  setFormSaving: (formSaving: boolean) =>
    set(() => {
      return {
        formSaving,
      };
    }),
  setClaimConflicts: (claimConflicts: ClaimStatus[]) =>
    set(() => {
      return {
        claimConflicts,
      };
    }),
  setGoneConflicts: (goneConflicts: ClaimStatus[]) =>
    set(() => {
      return {
        goneConflicts,
      };
    }),
});

export const useCalendarStore = create<ICalendarStore>()(calendarStore);
