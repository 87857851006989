import { SignInFlowStartContentFormInputNames } from '@gik/auth/enums/SignInFlowStartContentFormInputNames';
import type { FormSchemaEntry } from '@gik/ui/Form';

export const signInFlowStartContentFormSchema: FormSchemaEntry[] = [
  {
    placeholder: 'Email address',
    name: SignInFlowStartContentFormInputNames.EmailAddress,
    type: 'email',
    required: true,
    props: {
      variant: 'default-solid',
      autoFocus: true,
    },
  },
];

export const walletSignInFlow2faFormSchema: FormSchemaEntry[] = [
  {
    placeholder: 'Verification code',
    name: 'code',
    type: 'text',
    required: true,
    props: {
      variant: 'default-solid',
      minLength: 6,
      maxLength: 6,
      autoFocus: true,
    },
  },
  {
    name: 'remember',
    type: 'checkbox',
    required: false,
    props: {
      label: 'Remember this device for 24hrs',
    },
  },
];

export const walletSignInFlowWalletSessionFormSchema: FormSchemaEntry[] = [
  {
    placeholder: 'Password',
    name: 'password',
    type: 'password',
    required: true,
    props: {
      variant: 'default-solid',
      minLength: 8,
      maxLength: 255,
      autoFocus: true,
    },
  },
  // {
  //   name: 'remember',
  //   type: 'checkbox',
  //   required: false,
  //   props: {
  //     label: 'Remember this device for 24hrs',
  //   },
  // },
];
