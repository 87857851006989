import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';

const blockName = `${gikClassPrefix}-div-bone`;

export interface DivBoneProps extends React.PropsWithChildren<BaseBoneProps> {}

export function DivBone({ className, children, ...otherProps }: DivBoneProps) {
  const blockClasses = classnames([blockName, className || '']);

  return (
    <div className={blockClasses} {...otherProps}>
      {children}
    </div>
  );
}
