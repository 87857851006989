import type { FormError } from '@gik/ui/Form/Form';
import type { IMultiSelectProps } from '@gik/ui/Select';
import React from 'react';
import type { AnyType } from '../Table';
import type { FormVariant } from '../typesValues';
import type { FormContextProps } from './FormContext';
import { FormContext } from './FormContext';
import { generateField } from './FormFieldGenerator';
import type { FormGroupProps } from './FormGroup';
import { FormGroup } from './FormGroup';
import type { FieldInputProps, FormFieldExtraProps, FormSchemaEntry } from './types';

interface FormFieldProps extends FormGroupProps {
  name: string;
  labelClassName?: string;
  enterKeyHint?: string;
  vertical?: boolean;
  variant?: FormVariant;
  inline?: boolean;
  center?: boolean;
  isSaving?: boolean;
  hideLabel?: boolean;
  disabled?: boolean; // force this field to be disabled
  extraProps?: FormFieldExtraProps;
  //eslint-disable-next-line
  format?(value: any, name: string): any;
  //eslint-disable-next-line
  parse?(value: any, name: string): any;
  onChange?(value: AnyType): void;
  onKeyPress?(event: AnyType): void;
  onSubmit?: () => Promise<void | Response>;
  onSubmitFail?: (err: Response) => void | Promise<void>;
  context?: FormContextProps;
}

/**
 * GIK FormField
 *
 * This component makes it easier to work with react-final-form and a form schema.
 * it only needs the name of the form entry that should be rendered, details on how to render
 * the form field is extracted from the schema.
 */
function FormFieldComp({
  name,
  vertical,
  disabled,
  variant,
  labelClassName,
  inline,
  center,
  extraProps,
  hideLabel,
  isSaving,
  format,
  parse,
  onChange,
  onSubmit,
  onSubmitFail,
  onBlur,
  onFocus,
  context,
  ...otherProps
}: FormFieldProps): React.ReactElement {
  const schemaEntry: FormSchemaEntry = context.schema ? context.schema.find(entry => entry.name === name) : null;

  const isVertical = vertical || context.vertical;
  const isDisabled = disabled || context.disabled;
  const fieldVariant = variant || context.variant;

  const { form } = context;

  const error: FormError = form?.errors?.[name];
  const touched: boolean = !!form?.touched?.[name];
  const isRequired = schemaEntry?.required && error?.type === 'required';
  const value = form?.values?.[name];

  const [isFieldDirty, setFieldDirty] = React.useState<boolean>(false);
  const [isFieldModified, setFieldModified] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const fieldInitialValue = React.useMemo(() => form?.initialValues?.[name], [form, name]);
  const [autofocusBlurred, setAutofocusBlurred] = React.useState<number>(0);
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);

  const shownErrors = React.useRef<{ [key: string]: boolean }>({});

  // @ts-ignore
  const hasAutofocus = schemaEntry?.props?.autoFocus === true;
  const showSavableButtons = React.useMemo(() => isFieldDirty && isFieldModified, [isFieldDirty, isFieldModified]);

  const valueLength = Array.isArray(value) ? value?.length : value?.toString?.().length;

  const hasError =
    // field has no autofocus OR -- it has autofocus AND it has been blurred more than once OR form has been submitted
    (!hasAutofocus || (hasAutofocus && (autofocusBlurred > 1 || form.submitCount > 0))) &&
    !!error &&
    touched &&
    // has value OR -- has no value AND form has been submitted
    ((valueLength > 0 &&
      ((!hasFocus && error.message?.toLowerCase().indexOf('required') < 0 && schemaEntry.type != 'creatable-select') ||
        (hasFocus && shownErrors.current[error.message]))) ||
      ((value === undefined || value?.toString?.().length == 0) && form.submitCount > 0 && isRequired));

  if (hasError) {
    shownErrors.current[error.message] = true;
  }

  // TODO: also need to detect if the error that was previously shown was fixed

  const input: FieldInputProps<unknown, HTMLElement> = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (hasAutofocus) setAutofocusBlurred(autofocusBlurred => autofocusBlurred + 1);

      const value = event.target.value;

      form.setFieldValue(name, value, true);
      setFieldModified(fieldInitialValue !== value);
      setFieldDirty(fieldInitialValue !== value);
    },
    type: schemaEntry?.type,
    name: schemaEntry?.name,
    value: form?.values?.[name],
    checked: schemaEntry?.type === 'checkbox' ? form.values?.[name] : null,
    multiple: schemaEntry?.type === 'select' ? (schemaEntry?.props as IMultiSelectProps).multi : null,
    onBlur: form?.handleBlur,
  };

  const handleSubmit = React.useCallback(async () => {
    setSaving(true);

    const response = await onSubmit?.();
    if (hasAutofocus) setAutofocusBlurred(1);

    if (response && !response.ok) {
      onSubmitFail?.(response);
      setSaving(false);
      return response;
    }

    setSaving(false);
    return undefined;
  }, [hasAutofocus, onSubmit, onSubmitFail]);

  const resetField = React.useCallback(
    function resetField() {
      form.setFieldValue(name, fieldInitialValue);
      setFieldModified(false);
      setFieldDirty(false);
    },
    [form, name, fieldInitialValue]
  );

  const handleSave = React.useCallback(() => {
    handleSubmit?.();

    setFieldModified(false);
    setFieldDirty(false);
  }, [handleSubmit]);

  const handleFocus = React.useCallback(
    function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
      if (schemaEntry?.type === 'checkbox') return;

      setHasFocus(true);
      onFocus?.(event);
    },
    [onFocus, schemaEntry]
  );

  const handleBlur = React.useCallback(
    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
      if (schemaEntry?.type === 'checkbox') return;

      if (hasAutofocus) setAutofocusBlurred(autofocusBlurred => autofocusBlurred + 1);
      form?.setFieldTouched(name, true, true);
      setHasFocus(false);
      onBlur?.(event);
    },
    [error, form, hasAutofocus, name, onBlur, schemaEntry]
  );

  if (!schemaEntry) return <div>{name} not found</div>;

  return (
    <FormGroup
      label={schemaEntry.label}
      hideLabel={hideLabel}
      center={center}
      labelClassName={labelClassName || schemaEntry.labelClassName}
      required={schemaEntry.requiredIndicator}
      requiredIndicator={schemaEntry.requiredIndicator}
      optional={schemaEntry.optional}
      vertical={isVertical}
      disabled={isDisabled}
      variant={fieldVariant}
      fieldWidth={schemaEntry.width}
      fieldType={schemaEntry.type}
      fieldName={schemaEntry.name}
      fieldExtra={schemaEntry.fieldHelp}
      savable={schemaEntry.savable}
      help={schemaEntry.help}
      inline={inline}
      hasError={hasError}
      saving={saving || isSaving}
      showSavableButtons={showSavableButtons}
      handleSave={handleSave}
      handleCancel={resetField}
      error={error}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...otherProps}
    >
      {generateField({
        entry: schemaEntry,
        formFieldProps: input,
        extraProps: { ...extraProps, variant: fieldVariant, disabled: isDisabled, vertical: isVertical },
        disabled,
        hasError,
        onChange,
      })}
    </FormGroup>
  );
}

export const FormField = (props: FormFieldProps) => {
  return (
    <FormContext.Consumer>
      {context => {
        return <FormFieldComp {...props} context={context} />;
      }}
    </FormContext.Consumer>
  );
};
