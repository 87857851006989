import type { FormSchemaEntry } from '@gik/ui/Form';

export type ZipCodeFieldProps = {
  required?: boolean;
};

export const ZipCodeField = (props?: ZipCodeFieldProps): FormSchemaEntry => ({
  label: 'Zip',
  name: 'postalCode',
  type: 'number',
  required: props?.required !== undefined ? props?.required : true,
  minLength: 5,
  // this max length is for validation
  maxLength: 5,
  // the max length in the props is to prevent more than 5 charaters to be used on the input
  props: { autoComplete: 'postal-code', maxLength: 5, maxLengthDisplay: false },
});
