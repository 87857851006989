import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useUser } from '@gik/api/users/user';
import { createSourceFromGooglePay } from '@gik/checkout/api';
import { ApplePayButton } from '@gik/checkout/components/ApplePayButton/ApplePayButton';
import type { BillingFormValues } from '@gik/checkout/components/BillingForm/BillingForm';
import { BillingForm } from '@gik/checkout/components/BillingForm/BillingForm';
import { billingFormFormId } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import type { ICheckoutFormModalContext } from '@gik/checkout/components/CheckoutForm/CheckoutFormModalContext';
import { CheckoutFormModalContext } from '@gik/checkout/components/CheckoutForm/CheckoutFormModalContext';
import {
  GooglePayButton,
  type ReadyToPayChangeResponse,
} from '@gik/checkout/components/GooglePayButton/GooglePayButton';
import { translationKeys } from '@gik/checkout/i18n/en';
import { useCheckoutFormModalStore } from '@gik/checkout/store/CheckoutFormModalStore';
import { useApplePay } from '@gik/checkout/utils/useApplePay';
import { useGooglePay } from '@gik/checkout/utils/useGooglePay';
import { validateSendToSelf } from '@gik/checkout/utils/validateSendToSelf';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { CartItem, ShippingDetails } from '@gik/core/models/gik/Order';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { validator } from '@gik/core/utils/validator';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import { FormGroup } from '@gik/ui/Form';
import type { PaymentIntent, Source, StripeCardElement, Order } from '@stripe/stripe-js';
import type { FormApi, SubmissionErrors } from 'final-form';
import React from 'react';
import { ExpandableButton } from '@gik/ui/ExpandableButton/ExpandableButton';
import CreditCardIcon from '@heroicons/react/solid/CreditCardIcon';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { Debug } from '@gik/core/utils/asPlaceholderReactNode';
import { storage } from '@gik/core/utils/Storage';

export const ExpressCheckout = withComponentErrorBoundary(ExpressCheckoutComp);

export type IExpressCheckoutProps = UIComponent & {
  cart?: CartItem[];
  tip?: number;
  shipping?: number;
  total: number;
  billingInitialValues?: Partial<BillingFormValues>;
  shippingDetails?: ShippingDetails;
  productIds?: number[];
  creditCardLast4?: string;
  setCardElement?(cardElement: StripeCardElement): void;
  /** Called when form validation succeeds but either stripe token generation or address validation fails */
  // TODO: fix typing
  onSubmit?: (values: BillingFormValues) => void | Promise<void>;
  onBeforeSubmitPurchaseCard?: () => void;
  onBillingSubmitFail?();
  onBillingValidationFail?();

  onBillingAfterSubmit?: (
    values: object,
    form: FormApi<object, object>,
    source: Source
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;
  onClickFirstNameField?(): void;
  onExpandBillingForm?(): void;
  disabled?: boolean;
  onStripeFieldChange?(): void;
};

function ExpressCheckoutComp({
  cart,
  tip,
  shipping,
  total,
  billingInitialValues: initialValues,
  shippingDetails,
  creditCardLast4,
  setCardElement,
  onSubmit,
  onBeforeSubmitPurchaseCard,
  onBillingSubmitFail,
  onBillingValidationFail,
  onBillingAfterSubmit,
  onClickFirstNameField,
  onExpandBillingForm,
  productIds,
  className,
  disabled,
  onStripeFieldChange,
  ...otherProps
}: IExpressCheckoutProps) {
  const bem = useBemCN('express-checkout');
  const { isEnabled: isGooglePayEnabled } = useGooglePay();
  const { isEnabled: isApplePayEnabled } = useApplePay();

  const [hideExpressCheckoutForm, setHideExpressCheckoutForm] = React.useState(true);

  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const recalculateHeightFnRef = React.useRef<(timeout?: number) => void>(null);

  const { isSubmitting, setIsSubmitting } = React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext);

  // TODO: this logic needs to change
  const handleOnGooglePayReadyToPay = React.useCallback(
    (result: ReadyToPayChangeResponse) => {
      setHideExpressCheckoutForm(
        isGooglePayEnabled && (!result.isButtonVisible || !result.isReadyToPay) && !isApplePayEnabled
      );
    },
    [isApplePayEnabled, isGooglePayEnabled]
  );

  const onPreDebounce = React.useCallback(() => {
    setIsSubmitting(true);
  }, [setIsSubmitting]);

  const handleBeforeSubmitPurchaseCard = React.useCallback(() => {
    onBeforeSubmitPurchaseCard?.();
  }, [onBeforeSubmitPurchaseCard]);

  const handleCompletePurchaseCard = React.useCallback(
    values => {
      onSubmit?.(values);
      setTimeout(() => {
        useCheckoutFormModalStore.getState().orderSummaryFormRef.current?.submitForm();
        Analytics.fireEvent(AnalyticsEvents.CheckoutPlaceOrder, {
          paymentMethod: 'credit-card',
        });
      }, timeoutDefaultValue);
    },
    [onSubmit]
  );

  // TODO: validate for yourself purchases
  const handleCompletePurchaseGooglePay = React.useCallback(
    async function (paymentData: google.payments.api.PaymentData): Promise<void> {
      if (validateSendToSelf(cart, paymentData.email, shippingDetails)) {
        throw {
          message: i18n.t(translationKeys.tangoCardCannotPurchaseForSelf),
          reason: 'PAYMENT_DATA_INVALID',
        };
      }

      setIsSubmitting(true);

      const token = JSON.parse(paymentData.paymentMethodData.tokenizationData.token);
      const response = await createSourceFromGooglePay(token.id);

      const state =
        user?.billingAddresses[0]?.state ?? paymentData.paymentMethodData.info.billingAddress?.administrativeArea;

      if (response?.id) {
        onSubmit?.({
          firstName:
            user?.firstName?.length > 0
              ? user?.firstName
              : paymentData.paymentMethodData.info.billingAddress?.name?.split(' ')[0]?.length > 0
                ? paymentData.paymentMethodData.info.billingAddress?.name?.split(' ')[0]
                : '-',
          lastName:
            user?.lastName?.length > 0
              ? user?.lastName
              : paymentData.paymentMethodData.info.billingAddress?.name?.split(' ')[1]?.length > 0
                ? paymentData.paymentMethodData.info.billingAddress?.name?.split(' ')[1]
                : '-',
          address1:
            user?.billingAddresses[0]?.address1?.length > 0
              ? user?.billingAddresses[0]?.address1
              : paymentData.paymentMethodData.info.billingAddress?.address1,
          address2:
            user?.billingAddresses[0]?.address2?.length > 0
              ? user?.billingAddresses[0]?.address2
              : paymentData.paymentMethodData.info.billingAddress?.address2,
          city:
            user?.billingAddresses[0]?.city?.length > 0
              ? user?.billingAddresses[0]?.city
              : paymentData.paymentMethodData.info.billingAddress?.locality,
          state: !validator.isEmpty(state) ? state : 'NA',
          postalCode:
            user?.billingAddresses[0]?.postalCode ?? paymentData.paymentMethodData.info.billingAddress?.postalCode,
          country: ' US', // user?.firstName ?? paymentData.paymentMethodData.info.billingAddress?.countryCode,
          email: paymentData.email,
          phone: paymentData.paymentMethodData.info.billingAddress?.phoneNumber,
          saveAddress: false,
          stripeToken: response.id,
          paymentMethod: 'google-pay',
        });

        setTimeout(() => {
          useCheckoutFormModalStore.getState().orderSummaryFormRef.current?.submitForm();
          Analytics.fireEvent(AnalyticsEvents.CheckoutPlaceOrder, {
            paymentMethod: 'google-pay',
          });
        }, timeoutDefaultValue);
      } else {
        throw {
          message: 'Invalid token',
          reason: 'PAYMENT_DATA_INVALID',
        };
      }

      setTimeout(() => {
        setIsSubmitting(false);
      }, timeoutDefaultValue * 2);
    },
    [cart, onSubmit, setIsSubmitting, shippingDetails, user]
  );

  const handleCompleteApplePayPurchase = React.useCallback(
    async function (paymentIntent: PaymentIntent, billingDetails: Order.Billing): Promise<void> {
      if (validateSendToSelf(cart, billingDetails.email, shippingDetails)) {
        throw {
          message: i18n.t(translationKeys.tangoCardCannotPurchaseForSelf),
          reason: 'PAYMENT_DATA_INVALID',
        };
      }

      setIsSubmitting(true);

      const state = user?.billingAddresses[0]?.state ?? billingDetails.address.state;

      if (paymentIntent.client_secret) {
        onSubmit?.({
          firstName:
            user?.firstName?.length > 0
              ? user?.firstName
              : billingDetails.name.split(' ')[0]?.length > 0
                ? billingDetails.name?.split(' ')[0]
                : '-',
          lastName:
            user?.lastName?.length > 0
              ? user?.lastName
              : billingDetails.name.split(' ')[1]?.length > 0
                ? billingDetails.name?.split(' ')[1]
                : '-',
          address1:
            user?.billingAddresses[0]?.address1?.length > 0
              ? user?.billingAddresses[0]?.address1
              : billingDetails.address.line1,
          address2:
            user?.billingAddresses[0]?.address2?.length > 0
              ? user?.billingAddresses[0]?.address2
              : billingDetails.address.line2,
          city:
            user?.billingAddresses[0]?.city?.length > 0 ? user?.billingAddresses[0]?.city : billingDetails.address.city,
          state: !validator.isEmpty(state) ? state : 'NA',
          postalCode: user?.billingAddresses[0]?.postalCode ?? billingDetails.address.postal_code,
          country: ' US',
          email: billingDetails.email,
          phone: billingDetails.phone,
          saveAddress: false,
          stripeToken: paymentIntent.id,
          paymentMethod: 'apple-pay',
        });

        // workaround for: https://wolfellc.atlassian.net/browse/GIK-8894
        storage.setWithExpiry('applePayPaymentIntent', paymentIntent.id, 15 * 60);

        setTimeout(() => {
          useCheckoutFormModalStore.getState().orderSummaryFormRef.current?.submitForm();
          Analytics.fireEvent(AnalyticsEvents.CheckoutPlaceOrder, {
            paymentMethod: 'apple-pay',
          });
        }, timeoutDefaultValue);
      } else {
        throw {
          message: 'Invalid token',
          reason: 'PAYMENT_DATA_INVALID',
        };
      }

      setTimeout(() => {
        setIsSubmitting(false);
      }, timeoutDefaultValue * 2);
    },
    [cart, onSubmit, setIsSubmitting, shippingDetails, user]
  );

  const handleBillingValidationFail = React.useCallback(() => {
    recalculateHeightFnRef.current?.(1);
    onBillingValidationFail?.();
  }, [onBillingValidationFail]);

  const billingForm = React.useMemo(
    () => (
      <BillingForm
        id={billingFormFormId}
        initialValues={initialValues}
        initialOrders={cart}
        shippingDetails={shippingDetails}
        productIds={productIds}
        onSubmit={handleCompletePurchaseCard}
        onBeforeSubmit={handleBeforeSubmitPurchaseCard}
        preDebounce={onPreDebounce}
        onBillingSubmitFail={onBillingSubmitFail}
        onValidationFail={handleBillingValidationFail}
        onAfterSubmit={onBillingAfterSubmit}
        setCardElement={setCardElement}
        creditCardLast4={creditCardLast4}
        onShowAddress2Field={() => recalculateHeightFnRef.current?.(1)}
        onFormBlur={() => recalculateHeightFnRef.current?.(1)}
        onClickFirstNameField={onClickFirstNameField}
        buttons={isFormValid => (
          <Button type={'submit'} disabled={!isFormValid || disabled || isSubmitting} fullWidth loading={isSubmitting}>
            Complete purchase
          </Button>
        )}
        disabled={disabled}
        onStripeFieldChange={onStripeFieldChange}
      />
    ),
    [
      cart,
      creditCardLast4,
      disabled,
      handleBeforeSubmitPurchaseCard,
      handleBillingValidationFail,
      handleCompletePurchaseCard,
      initialValues,
      isSubmitting,
      onBillingAfterSubmit,
      onBillingSubmitFail,
      onClickFirstNameField,
      onPreDebounce,
      onStripeFieldChange,
      productIds,
      setCardElement,
      shippingDetails,
    ]
  );

  return (
    <div
      {...otherProps}
      {...bem(
        null,
        [
          {
            'no-express-checkout': hideExpressCheckoutForm,
          },
        ],
        className
      )}
    >
      <Debug
        data={{
          isApplePayEnabled,
          isGooglePayEnabled,
          hideExpressCheckoutForm,
        }}
      />
      {(isGooglePayEnabled || isApplePayEnabled) && (
        <>
          <FormGroup id="express-checkout-form-group">
            <Layout
              direction={'column'}
              align={'space-between'}
              perpendicular={'stretch'}
              gap={4}
              className={'tw-mb-6'}
            >
              {isApplePayEnabled && (
                <ApplePayButton
                  shipping={shipping}
                  tip={tip}
                  cart={cart}
                  total={total}
                  onAuthorized={handleCompleteApplePayPurchase}
                  disabled={disabled}
                  onReadyToPayChange={handleOnGooglePayReadyToPay}
                />
              )}
              {isGooglePayEnabled && (
                <GooglePayButton
                  shipping={shipping}
                  tip={tip}
                  cart={cart}
                  total={total}
                  onAuthorized={handleCompletePurchaseGooglePay}
                  disabled={disabled}
                  onReadyToPayChange={handleOnGooglePayReadyToPay}
                />
              )}
            </Layout>
          </FormGroup>
        </>
      )}

      <div className={'express-checkout-form-group'}>
        {(!isGooglePayEnabled && !isApplePayEnabled) || hideExpressCheckoutForm ? (
          <FormGroup highlight>{billingForm}</FormGroup>
        ) : (
          <ExpandableButton title="Credit card" Icon={CreditCardIcon} onExpand={() => onExpandBillingForm?.()}>
            {billingForm}
          </ExpandableButton>
        )}
      </div>
    </div>
  );
}
